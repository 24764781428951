import React, {useEffect, useState} from 'react';
import {setSalesforceIntegrationIsActive, setupZoom, trackEvent} from '../utils/genericUtils'
import {useUserData} from './hooks/useUserData';
import {GoogleOAuthProvider} from '@react-oauth/google';
import CheckMark from '../images/checkmark.svg'
import {SALESFORCE_CONNECT_LINK} from "../config/config";


function SalesForceOnboardingStep1({closePopup, goToNextStep}) {
    const [userData] = useUserData()
    const {
        subscription_is_active,
    } = userData

    return <div>
        <h1 style={{
            marginBottom: 20,
            "color": "#FFF",
            "fontFeatureSettings": "'clig' off, 'liga' off",
            "fontFamily": "\"Source Sans Pro\"",
            "fontSize": "24px",
            "fontStyle": "normal",
            "fontWeight": "900",
            "lineHeight": "normal"
        }}>
            Set up your Briefly integration for Salesforce </h1>
        <div style={{display: 'flex'}}>
            <div style={{paddingLeft: 25}}>
                <h6 style={{
                    margin: 0,
                    "color": "var(--text-text-primary, #FFF)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "16px",
                    "fontStyle": "normal",
                    "fontWeight": "500",
                    "lineHeight": "22px",
                    "letterSpacing": "0.15px"
                }}>
                    How does it work?
                </h6>
                <h6 style={{
                    margin: 0,
                    marginTop: 8,
                    "color": "var(--text-text-primary, #FFF)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "700",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    🔗 Connect to Salesforce </h6>
                <p style={{
                    margin: 0,
                    marginTop: 4,
                    "color": "var(--text-text-secondary, #C6C6C6)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    You must have access to all contacts and accounts in Salesforce. </p>
                <h6 style={{
                    margin: 0,
                    marginTop: 8,
                    "color": "var(--text-text-primary, #FFF)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "700",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    🛡️ Individual connection </h6>
                <p style={{
                    margin: 0,
                    marginTop: 4,
                    "color": "var(--text-text-secondary, #C6C6C6)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    Each team member will need to connect to Salesforce individually.
                </p>
                <h6 style={{
                    margin: 0,
                    marginTop: 8,
                    "color": "var(--text-text-primary, #FFF)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "700",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    ✍️ Configure transcript uploads </h6>
                <p style={{
                    margin: 0,
                    marginTop: 4,
                    "color": "var(--text-text-secondary, #C6C6C6)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    Adjust your Salesforce settings to automatically upload transcripts after each call. </p>
                <h6 style={{
                    margin: 0,
                    marginTop: 8,
                    "color": "var(--text-text-primary, #FFF)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "700",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    🔓️ Call-Level Override </h6>
                <p style={{
                    margin: 0,
                    marginTop: 4,
                    "color": "var(--text-text-secondary, #C6C6C6)",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "20px",
                    "letterSpacing": "0.15px"
                }}>
                    Override the default setting during a call for full control on a call-by-call basis.</p>
            </div>
        </div>

        {!subscription_is_active ? <div
            style={{
                background: '#434343',
                border: '1px solid #6D6D6D',
                display: "flex",
                flexDirection: 'column',
                textAlign: 'center',
                padding: 16,
                marginTop: 32
            }}
        >
            <p
                style={
                    {
                        margin: 0,
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'Source Sans Pro',
                        fontWeight: '900',
                        wordWrap: 'break-word'
                    }
                }>SalesForce integration is a PRO feature only</p>

            <p style={{
                color: '#C6C6C6',
                fontSize: 12,

                fontFamily: 'Roboto',
                fontWeight: '400',
                wordWrap: 'break-word',
                marginTop: 16,
                marginBlock: 8
            }}>Upgrade to a PRO account to use this feature
            </p>
            <button>
                Upgrade to PRO now
            </button>
        </div>
            :
        <div style={{textAlign: 'center', marginTop: 20}}>
            <button onClick={goToNextStep} style={{
                marginRight: 0,
                "display": "inline-flex",
                "padding": "8px 32px",
                "justifyContent": "center",
                "alignItems": "center",
                "gap": "8px",
                "borderRadius": "4px",
                "background": "var(--surface-surface-brand, #E44867)"
            }}>
                Start SalesForce setup
            </button>
            <div style={{}}/>
            <button onClick={closePopup} style={{
                marginRight: 0,
                background: 'transparent',
                "color": "var(--color-brand-briefly, #E44867)",
                "fontFeatureSettings": "'clig' off, 'liga' off",
                "fontFamily": "\"Source Sans Pro\"",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "600",
                "lineHeight": "16px",
                "textDecorationLine": "underline"
            }}>
                Quit Setup
            </button>
        </div>
        }
    </div>
}

function SalesForceOnboardingStep2({closePopup, goToNextStep}) {

    const handleRedirect = () => {
        trackEvent('salesforce-connect-clicked', {})
        setTimeout(() => {
            window.location = SALESFORCE_CONNECT_LINK
        }, 500)
    }

    return <div>
        <h1 style={{
            "color": "#FFF",
            "textAlign": "left",
            "fontFeatureSettings": "'clig' off, 'liga' off",
            "fontFamily": "\"Source Sans Pro\"",
            "fontSize": "24px",
            "fontStyle": "normal",
            "fontWeight": "900",
            "lineHeight": "normal"
        }}>
            Set up your Briefly integration for Salesforce </h1>
        <div style={{display: "flex", flexDirection: 'row', gap: 16}}>
            <div>
                <p style={{
                    color: "var(--text-text-primary, #FFF)",
                    fontFamily: 'Roboto',
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '22px',
                    letterSpacing: '0.15px',
                    textAlign: 'left'
                }}>
                    Connect Briefly to your Salesforce workspace </p>
                <p style={{
                    "color": "#C6C6C6",
                    "textAlign": "left",
                    "fontFeatureSettings": "'clig' off, 'liga' off",
                    "fontFamily": "Roboto",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "22px",
                    "letterSpacing": "0.15px"
                }}>
                    You must have access to all contacts and accounts in Salesforce.
                </p>
            </div>
            <button
                onClick={handleRedirect}
                style={{
                width: '100px',
                textWrap: 'nowrap',
                padding: '8px 32px'
            }}>
                Connect
            </button>
        </div>

        <div style={{textAlign: 'center', marginTop: 120}}>
            <button onClick={goToNextStep} style={{
                marginRight: 0,
                "display": "inline-flex",
                "padding": "8px 32px",
                "justifyContent": "center",
                "alignItems": "center",
                "gap": "8px",
                "borderRadius": "4px",
                "background": "var(--surface-surface-brand, #E44867)"
            }}>
                Confirm and complete setup
            </button>
            <div style={{}}/>
            <button onClick={closePopup} style={{
                marginRight: 0,
                background: 'transparent',
                "color": "var(--color-brand-briefly, #E44867)",
                "fontFeatureSettings": "'clig' off, 'liga' off",
                "fontFamily": "\"Source Sans Pro\"",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "600",
                "lineHeight": "16px",
                "textDecorationLine": "underline"
            }}>
                Quit Salesforce setup
            </button>
        </div>
    </div>
}

function SalesForceOnboardingStep3({
                                       closePopup,
                                   }) {

    const [userData] = useUserData()
    const {
        is_integration_active_salesforce,
        is_integration_active_google_calendar,
        salesforce_redshift_db_name

    } = userData
    const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : ''

    const [setShowHubspotCalendarRequiredPopup] = useState()
    return <div>
        <>
            <h1 style={{
                "color": "#FFF",
                "textAlign": "left",
                "fontFeatureSettings": "'clig' off, 'liga' off",
                "fontFamily": "\"Source Sans Pro\"",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "900",
                "lineHeight": "normal"
            }}>
                Set up your Briefly integration for Salesforce </h1>
            <div style={{display: "flex", flexDirection: 'column', width: '100%', gap: 24}}>
                <div style={{display: "flex", flexDirection: 'row', gap: 24, alignItems: 'center'}}>
                    <p style={{
                        color: 'white',
                        flex: 1,
                        fontSize: 16,
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        letterSpacing: 0.15,
                        wordWrap: 'break-word'
                    }}>Briefly is connected to Salesforce
                    </p>
                    {salesforceDomain && <div style={
                        {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 6,
                            fontSize: 12,
                            fontFamily: 'Roboto', fontWeight: 700,
                            padding: '2px 6px',
                            borderRadius: 2,
                            margin: 0,
                            height: 'fit-content',
                            backgroundColor: 'var(--surface-surface-secondary, #E44867)',
                        }
                    }>
                        <img src={CheckMark} alt=""/>
                        {salesforceDomain}
                    </div>}
                </div>
                <hr style={{borderTop: '1px solid #6D6D6D', width: '100%'}}/>
                <div>
                    <div style={{display: 'flex'}}>
                        <p style={
                            {
                                color: 'white',
                                margin: 0,
                                flex: 1,
                                fontSize: 16,
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                letterSpacing: 0.15,
                                wordWrap: 'break-word'
                            }
                        }>
                            Add AI Summaries and Transcripts to Salesforce </p>
                        <label className="switch">
                            <input type="checkbox"
                                   disabled={!is_integration_active_google_calendar}
                                   checked={is_integration_active_salesforce}
                                   onClick={e => {
                                const checked = e.target.checked
                                       if (checked && !is_integration_active_google_calendar) {
                                           setShowHubspotCalendarRequiredPopup(true)
                                           return
                                       }
                                       setSalesforceIntegrationIsActive(checked)

                            }}/>
                            <span className="slider round"></span>

                        </label>
                    </div>
                    <p style={{
                        width: '100%',
                        color: '#C6C6C6',
                        fontSize: 12,
                        margin: 0,
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                        letterSpacing: 0.30,
                        wordWrap: 'break-word'
                    }}>Automatically add your call transcripts and AI summaries to Salesforce contacts
                    </p>
                </div>
            </div>
        </>
        <div style={{textAlign: 'center', marginTop: 20}}>
            {<button onClick={closePopup} style={{
                marginRight: 0,
                "display": "inline-flex",
                "padding": "8px 32px",
                "justifyContent": "center",
                "alignItems": "center",
                "gap": "8px",
                "borderRadius": "4px",
                "background": "var(--surface-surface-brand, #E44867)"
            }}>
                Confirm and complete setup
            </button>}
            <div style={{}}/>
            <button onClick={closePopup} style={{
                marginRight: 0,
                background: 'transparent',
                "color": "var(--color-brand-briefly, #E44867)",
                "fontFeatureSettings": "'clig' off, 'liga' off",
                "fontFamily": "\"Source Sans Pro\"",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "600",
                "lineHeight": "16px",
                "textDecorationLine": "underline"
            }}>
                Quit Salesforce setup
            </button>
        </div>
    </div>
}

const STEPS_CONFIG = [
    {
        component: SalesForceOnboardingStep1,
        label: 'Preview'
    },
    {
        component: SalesForceOnboardingStep2,
        label: 'Add to SalesForce'
    },
    {
        component: SalesForceOnboardingStep3,
        label: 'Confirm and complete setup'
    },
]

function SalesForceOnboardingPopup({onClose}) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    const [, forceRenewUserData] = useUserData()

    const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false)

    const currentStep = STEPS_CONFIG[currentStepIndex]
    const CurrentStepComponent = currentStep.component

    useEffect(() => {
        if (currentStepIndex === 4) {
            setupZoom(botShouldJoinAllCalls, () => {
                forceRenewUserData()
            })
        }
    }, [currentStepIndex])

    return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
        <div className="transcript-info-popup-container">
            <div className="popup-background" onClick={() => onClose()}></div>
            <div className="popup-content" style={{padding: 20}}>
                <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls}
                                      setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose}
                                      goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)}
                                      goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)}/>
            </div>
        </div>
    </GoogleOAuthProvider>
};

export default SalesForceOnboardingPopup;

// ZoomOAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendZoomCodeToBackend, getUserAccessToken } from '../utils/genericUtils'

const ZoomOAuth = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    localStorage.setItem('targetTab', 'integrations');
    
    if (code) {
      getUserAccessToken(({access}) => {
        sendZoomCodeToBackend(code, access).then(response => {
          if(response.error) {
            localStorage.setItem('zoomOAuthMessage', 'Failed to process the code');
          } else {
            localStorage.setItem('zoomOAuthMessage', 'OAuth successful!');
          }
          navigate('/settings');
        });
      });
    } else {
      localStorage.setItem('zoomOAuthMessage', 'No authorization code found');
      navigate('/settings');
    }
  }, [navigate]);

  return null; // Return null since we're navigating away immediately
};

export default ZoomOAuth;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import userEventTracking from "./userEventTracking";

export default function useUserEventTracking({ id }) {
  const location = useLocation();

  useEffect(() => {
    if (userEventTracking.isEnabled) {
      const track = (e) => userEventTracking.logClick(e, { id });
      document.addEventListener("click", track);
      return () => document.removeEventListener("click", track);
    }
  }, []);

  useEffect(() => {
    if (userEventTracking.isEnabled) {
      userEventTracking.logPage(location, { id });
    }
  }, [location]);
}

import React, { FormEvent } from 'react';
import { AddIcon, AlertIcon, CheckmarkCircleIcon, ExternalLinkIcon, MinusIcon, } from "../../Icons";

import style from "./style";
import EditableContentTextarea from '../../forms/EditableContentTextarea';
import styled from 'styled-components';
import { Button, colors } from '../../../theme';
import { useTranslation } from 'react-i18next';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Grid = styled.div`
  flex-grow: 1;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 0fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;

  & > *:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }
  & > *:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
  & > *:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Actions = styled.div`
  display: flex;
  justifyContent: space-between;
  gap: 12px;
`;

interface UiFieldMappingItem {
  id: number;
  label: string;
  status: string;
  destinationUrl: string;
  previousValue: string;
  suggestedValue: string
}

interface FieldMappingContentProps {
  fieldMapping: Partial<UiFieldMappingItem> & { id: UiFieldMappingItem['id']};
  onToggle: (number) => void;
  isOpen: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>, id: number) => void
}

function FieldMappingContent({ fieldMapping, onToggle, onSubmit, isOpen }: FieldMappingContentProps) {
  const { t } = useTranslation();
  
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    onSubmit(e, fieldMapping.id);
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    onToggle(isOpen ? null : fieldMapping.id);
  };

  return (
    <div style={style.item} key={fieldMapping.id}>
      <div onClick={handleClick} style={style.itemSummary}>
        <div style={style.itemHeading}>
          {isOpen ? (
            <MinusIcon size={28} />
          ) : (
            <AddIcon size={28} />
          )}
          <span>{fieldMapping.label}</span>
        </div>
        <div style={style.itemStatus}>
          {fieldMapping.status === "needs_review" && (
            <>
              <AlertIcon size={12} color="FF3A3A" />
              <span>{t('meeting.salesforce.field-mapping-item.review', 'Needs review')}</span>
            </>
          )}
          {fieldMapping.status === "ignored" && (
            <>
              <span style={style.disabledText}>
                {t('meeting.salesforce.field-mapping-item.not-updated', 'Not updated')}
                {fieldMapping.destinationUrl && (
                  <a
                    href={fieldMapping.destinationUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('meeting.salesforce.field-mapping-item.go', 'Go')}
                  </a>
                )}
              </span>
            </>
          )}
          {fieldMapping.status === "completed" && (
            <FlexCenter>
              <CheckmarkCircleIcon size={12}
                style={{ ...style.checkMarkIcon }}
              />
              <span>{t('meeting.salesforce.field-mapping-item.added', 'Added to CRM')}</span>
              {fieldMapping.destinationUrl && (
                <a
                  href={fieldMapping.destinationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon size={16} style={{ color: colors.white }} />
                </a>
              )}
            </FlexCenter>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          <form id="ignoreField" onSubmit={handleSubmit}>
            <input type="hidden" name="disabled" value="true" />
          </form>
          <form style={style.itemDetail} onSubmit={handleSubmit}>
            <input type="hidden" name="state" value="attempt_to_send" />
            <span style={style.verticalDivider} />
            <Grid>
              <InputContainer>
                <label style={style.inputLabel} htmlFor="previous_value">
                  {t('meeting.salesforce.field-mapping-item.current', 'Current content in CRM')}
                </label>
                <EditableContentTextarea
                  style={{ ...style.input, ...style.inputPreview }}
                  placeholder={`${fieldMapping.label} content preview`}
                  id="previous_value"
                  defaultValue={fieldMapping.previousValue}
                  readOnly
                />
              </InputContainer>
              <InputContainer>
                <label style={style.inputLabel}>
                  {t('meeting.salesforce.field-mapping-item.new-content', 'Briefly new content from call')}
                </label>
                <EditableContentTextarea
                  style={style.input}
                  placeholder={t("meeting.salesforce.field-mapping-item.output-placeholder", "Briefly AI output (editable)")}
                  name="confirmed_value"
                  defaultValue={fieldMapping.suggestedValue}
                />
              </InputContainer>
              <Actions>
                {fieldMapping.status === "needs_review" && (
                  <Button
                    form="ignoreField"
                    $secondary
                    $small
                    $inline
                  >
                    {t('ignore', { ns: 'Actions', defaultValue: 'Ignore' })}
                  </Button>
                )}
                <Button
                  style={{ ...style.buttonSolid }}
                  disabled={fieldMapping.status === "completed"}
                  $small
                  $inline
                >
                  {t('meeting.salesforce.field-mapping-item.save', {label: fieldMapping.label, defaultValue: 'Save to "{{ label }}"'})};
                </Button>
              </Actions>
            </Grid>
          </form>
        </>
      )
      }
    </div >
  );
}

export default FieldMappingContent;

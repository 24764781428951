import React from 'react'

const EXTENSION_BASE_URL = 'https://bjmgcelbpkgmofiogkmleblcmecflldk.chromiumapp.org/#'
const LINK = 'a'
const LINK_ATTR = 'href'

const redirect = (code) => {
	const value = EXTENSION_BASE_URL + code

	const lnk = document.createElement(LINK)
	lnk.setAttribute(LINK_ATTR, value)
	lnk.click()
}

export function ChromeExtensionRedirect() {

	const code = window.location.href.split('#')[1]
	
	redirect(code)

	return <div style={{ margin: 20 }}>
		Chrome extension log in successful
		<br/>
		You can now close this page
	</div>
}

export default ChromeExtensionRedirect
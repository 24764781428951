import {
  setAttendeesEmailNotificationsEnabled,
  setEmailNotificationsEnabled,
  trackEvent,
  updateUserSettings
} from "../utils/genericUtils";
import { useNavigate } from "react-router-dom";
import useUserData from "./hooks/useUserData";
import React from "react";
import backIcon from "../images/back.svg";
import TopNav from "./TopNav";

const OnboardingFinalize = () => {
  const navigate = useNavigate();
  const [userData] = useUserData();
  const {
    is_subscribed_to_attendees_emails,
    is_subscribed_to_emails
  } = userData;

  const handleRedirect = () => {
    updateUserSettings({
      is_onboarding_completed: true
    }, () => {
      navigate('/');
    });
  };

  return (
    <div style={{
      background: '#141414',
      minHeight: '100vh',
      width: '100%',
      position: 'relative'

    }}>
      <TopNav />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          maxWidth: 600,
          width: '100%',
          margin: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}>
        <a href={'/onboarding'} style={{
          width: '100%',
          textDecoration: 'none',
          marginBottom: 24,
          color: 'white',
          fontSize: 14,
          fontFamily: 'Roboto',
          fontWeight: '500',
          letterSpacing: 0.15,
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }}>
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={backIcon}
          />
          Back
        </a>

        <div style={{
          padding: 24,
          background: '#212121',
          borderRadius: 4,
          border: '1px #6D6D6D solid',
          gap: 16,
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}>
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>Finalize your settings
            </p>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            justifyContent: 'space-between',
          }}>
            <div>
              <p style={{
                color: 'white',
                fontSize: 16,
                margin: 0,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }}>Send a summary email to myself
              </p>
              <div style={{
                width: '100%',
                color: '#C6C6C6',
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: '400',
                letterSpacing: 0.30,
                marginTop: 4,
                wordWrap: 'break-word'
              }}>After each call receive an AI summary email to your inbox
              </div>
            </div>
            <label className="switch">
              <input type="checkbox" checked={is_subscribed_to_emails} onClick={e => {
                const checked = e.target.checked;

                if (checked) {
                  trackEvent("email-to-myself-enabled", {});
                } else {
                  trackEvent("email-to-myself-disabled", {});
                }

                setEmailNotificationsEnabled(checked);

              }} />
              <span className="slider round"></span>
            </label>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
            <div>
              <p style={{
                color: 'white',
                fontSize: 16,
                margin: 0,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }}>Send a summary email to everyone in the call
              </p>
              <div style={{
                width: '100%',
                color: '#C6C6C6',
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: '400',
                letterSpacing: 0.30,
                marginTop: 4,
                wordWrap: 'break-word'
              }}>After each call, automatically send an AI summary email to all participants on the call
              </div>
            </div>
            <label className="switch">
              <input type="checkbox" checked={is_subscribed_to_attendees_emails} onClick={e => {
                const checked = e.target.checked;
                if (checked) {
                  trackEvent("email-to-everyone-enabled", {});
                } else {
                  trackEvent("email-to-everyone-disabled", {});
                }
                setAttendeesEmailNotificationsEnabled(checked);
              }} />
              <span className="slider round"></span>
            </label>
          </div>

        </div>
        <button onClick={handleRedirect}>
          Confirm settings and go to dashboard
        </button>

      </div>
    </div>
  );
};


export default OnboardingFinalize;


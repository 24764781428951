import React from "react";
import { SvgSize } from "./types";


const WorldIcon = (svgProps: SvgSize) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={svgProps?.size ?? '16'}
    height={svgProps?.size ?? '16'}
    viewBox="0 0 107.366 107.366"
    {...svgProps}
  >
    <path
      d="M107.366 53.683C107.366 24.082 83.283 0 53.683 0c-4.982 0-9.785.734-14.366 2.011l-.107-.071c-.025.036-.043.075-.068.115C16.595 8.417 0 29.125 0 53.683c0 29.601 24.082 53.683 53.683 53.683 4.985 0 9.785-.734 14.366-2.011l.107.075c.025-.036.047-.075.068-.115 22.546-6.367 39.142-27.078 39.142-51.632zM5.343 56.292H24.69c.193 5.766.837 11.438 2.004 16.942H9.366C7.047 68.002 5.662 62.29 5.343 56.292zm96.679-5.215H82.679c-.197-5.769-.837-11.442-2.008-16.946H98c2.319 5.233 3.704 10.945 4.022 16.946zm-24.554 0h-21.18V34.131h19.061c1.231 5.494 1.915 11.167 2.119 16.946zM56.292 28.924V5.343c2.53.136 5.014.415 7.43.923a85.297 85.297 0 0 1 10.289 22.658H56.292zm-5.215 0h-18.12a86.214 86.214 0 0 1 9.57-22.368 48.285 48.285 0 0 1 8.55-1.213v23.581zm0 5.208v16.946H29.84c.111-5.766.712-11.442 1.85-16.946h19.387zM24.633 51.077H5.34c.319-6.002 1.704-11.717 4.023-16.946h17.046a98.406 98.406 0 0 0-1.776 16.946zm5.265 5.215h21.18v16.942H32.013c-1.228-5.494-1.911-11.166-2.115-16.942zm21.179 22.149v23.581c-2.53-.136-5.018-.415-7.43-.923a85.434 85.434 0 0 1-10.293-22.658h17.723zm5.215 0h18.116a86.375 86.375 0 0 1-9.57 22.368 47.919 47.919 0 0 1-8.55 1.213V78.441h.004zm0-5.207V56.292h21.237c-.111 5.762-.712 11.438-1.854 16.942H56.292zm26.44-16.942h19.294c-.319 6.002-1.704 11.714-4.023 16.942H80.957a98.28 98.28 0 0 0 1.775-16.942zM95.28 28.924H79.393c-1.943-7.147-4.681-13.993-8.235-20.403 10.136 3.933 18.607 11.173 24.122 20.403zM35.219 8.883c-3.31 6.32-5.848 13.041-7.627 20.038H12.086c5.339-8.93 13.428-16.023 23.133-20.038zM12.086 78.441h15.89c1.94 7.147 4.678 13.993 8.231 20.403-10.135-3.933-18.606-11.173-24.121-20.403zm60.06 20.042c3.31-6.32 5.848-13.041 7.627-20.038H95.28c-5.34 8.929-13.428 16.022-23.134 20.038z" fill="currentColor"
    />
  </svg>
)
export default WorldIcon


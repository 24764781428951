import React from "react";
import { SvgSize } from "./types";

const CheckmarkCircleIcon = (props: SvgSize) =>
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.78873 9.01408L9.77459 4.02823C10.0091 3.79372 10.0122 3.41449 9.78157 3.17617V3.17617C9.54554 2.93227 9.15544 2.92907 8.91544 3.16907L4.78873 7.29578L3.07141 5.57846C2.83751 5.34455 2.45827 5.34455 2.22436 5.57846V5.57846C1.99123 5.81159 1.99034 6.18929 2.22238 6.42352L4.78873 9.01408ZM1.74648 1.77465C2.92958 0.591549 4.34742 0 6 0C7.65258 0 9.06103 0.591549 10.2254 1.77465C11.4085 2.93897 12 4.34742 12 6C12 7.65258 11.4085 9.07042 10.2254 10.2535C9.06103 11.4178 7.65258 12 6 12C4.34742 12 2.92958 11.4178 1.74648 10.2535C0.58216 9.07042 0 7.65258 0 6C0 4.34742 0.58216 2.93897 1.74648 1.77465Z"
      fill="currentColor" />
  </svg>

export default CheckmarkCircleIcon



import React from 'react';
import { SvgSize } from './types';

const XCircleIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '12'} height={props?.size ?? '12'} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_443_4376)">
      <path d="M7.2947 8.13121C7.52572 8.36222 7.90027 8.36222 8.13128 8.13121C8.36229 7.90019 8.36229 7.52565 8.13128 7.29463L6.83658 5.99993L8.13128 4.70523C8.36229 4.47421 8.36229 4.09966 8.13128 3.86865C7.90026 3.63764 7.52572 3.63764 7.2947 3.86865L6 5.16335L4.7053 3.86865C4.47428 3.63764 4.09974 3.63764 3.86872 3.86865C3.63771 4.09966 3.63771 4.47421 3.86872 4.70523L5.16342 5.99993L3.86872 7.29463C3.63771 7.52565 3.63771 7.90019 3.86872 8.13121C4.09973 8.36222 4.47428 8.36222 4.7053 8.13121L6 6.83651L7.2947 8.13121ZM5.98008 0.00446091C7.65324 0.00446095 9.07409 0.588737 10.2426 1.75729C11.4112 2.92584 11.9888 4.34005 11.9755 5.99993C11.9888 7.6598 11.4112 9.07402 10.2426 10.2426C9.07409 11.4111 7.65324 11.9954 5.98008 11.9954C4.33349 11.9954 2.92591 11.4111 1.75736 10.2426C0.588808 9.07402 -0.00210798 7.6598 -0.0153871 5.99993C-0.00210794 4.34005 0.588808 2.92584 1.75736 1.75729C2.92591 0.588737 4.33349 0.00446087 5.98008 0.00446091Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_443_4376">
        <rect width={props?.size ?? '12'} height={props?.size ?? '12'} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default XCircleIcon;

type ExtensionLanguageEventPayload = {
  lng: string;
};

export type ExtensionChangeLanguageEvent = CustomEvent<ExtensionLanguageEventPayload>;

export const notifyExtensionLangChanged = (lng) => {
  return window.dispatchEvent(new CustomEvent('language-changed', {detail: { lng }}))
}

export const requestExtensionCurrentLanguage = () => {
  window.dispatchEvent(new CustomEvent('language-requested'));
}
import React from 'react';
import { SvgSize } from './types';


const PlusIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '14'} height={props?.size ?? '14'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#fff" d="M14 7a.987.987 0 0 1-.987.987H7.987v5.026a.987.987 0 1 1-1.973 0V7.987H.986a.987.987 0 0 1 0-1.973h5.026V.986a.987.987 0 1 1 1.973 0v5.026h5.027c.545 0 .987.442.987.987Z" />
  </svg>
);

export default PlusIcon;

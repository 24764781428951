import React, { useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { sendUserFeedback } from "../utils/genericUtils";
import TopNav from "./TopNav";
import { useTranslation } from "react-i18next";

const Onboarding = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [usingCRM, setUsingCRM] = useState(false);
  const [usingSlack, setUsingSlack] = useState(false);
  const [usingApps, setUsingApps] = useState([]);
  const [usingCRMs, setUsingCRMs] = useState([]);
  const [otherCRM, setOtherCRM] = useState('');
  const [otherApp, setOtherApp] = useState('');
  const search = useMemo(() => {
    return createSearchParams({
      usingApps: usingApps.join(','),
      usingCRMs: usingCRMs.join(','),
      usingSlack: usingSlack
    }).toString();
  }, [usingApps, usingSlack, usingCRMs]);


  const handleRedirect = () => {
    if (otherCRM) {
      sendUserFeedback(`Onboarding meeting CRM OTHER: ${otherCRM}`, false, true);
    }
    if (otherApp) {
      sendUserFeedback(`Onboarding meeting APP OTHER: ${otherApp}`, false, true);
    }
    if (usingApps.length === 1 && usingApps[0] === 'Other' && (usingCRMs.length === 0 || usingCRMs.length === 1 && usingCRMs[0] === 'Other') && !usingSlack) {
      navigate(
        '/onboarding/notuser'
      );
    } else {
      navigate({
        pathname: '/onboarding/connecting/', search
      });
    }
  };

  const handleToggleApp = (app, isCRM) => {
    if (isCRM) {
      if (usingCRMs.includes(app.name)) {
        setUsingCRMs(usingCRMs.filter(a => a !== app.name));
      } else {
        setUsingCRMs([...usingCRMs, app.name]);
      }
    } else {
      if (usingApps.includes(app.name)) {
        setUsingApps(usingApps.filter(a => a !== app.name));
      } else {
        setUsingApps([...usingApps, app.name]);
      }
    }
  };

  return (
    <div style={{
      background: '#141414',
      minHeight: '100vh',
      width: '100%',
      position: 'relative'

    }}>
      <TopNav />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          maxWidth: 600,
          margin: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: '25%',
        }}>
        <p style={{
          textAlign: 'center',
          color: 'white',
          margin: 0,
          fontSize: 32,
          marginBottom: 24,
          fontFamily: 'Source Sans Pro',
          fontWeight: '900',
          wordWrap: 'break-word'
        }}>{t('onboarding.title', 'Welcome to Briefly!')}
        </p>

        <div style={{
          padding: 24,
          background: '#212121',
          borderRadius: 4,
          border: '1px #6D6D6D solid',
          gap: 24,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>{t('onboarding.setup.title', 'Customize your setup')}
            </p>
            <div style={{
              width: '100%',
              color: '#C6C6C6',
              fontSize: 12,
              fontFamily: 'Roboto',
              fontWeight: '400',
              margin: 0,
              wordWrap: 'break-word'
            }}>{t(
                'onboarding.setup.step1',
                'Let’s tailor your Briefly setup to match how you work. You’ll also be able to manage and add more integrations later in the settings.'
              )}
            </div>
          </div>
          <p style={{
            width: '100%',
            color: 'white',
            fontSize: 16,
            margin: 0,
            fontFamily: 'Roboto',
            fontWeight: '500',
            wordWrap: 'break-word'
          }}>{t('onboarding.setup.step1-label', 'Which meeting app do you use?*')}
          </p>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 16,
          }}>
            {apps.map((app,) =>
              <AppCard key={app.name} onClick={() => handleToggleApp(app)}
                selected={!!usingApps.filter(a => a === app.name).length}
                app={app} />)}

          </div>
          <div
            style={{
              width: '100%', display: 'flex',
              overflow: 'hidden',
              height: usingApps.find(e => e === 'Other') ? 40 : 0,
              transition: 'height 0.5s',
              transitionTimingFunction: 'ease-in-out'
            }}
          >
            <input
              value={otherApp}
              onChange={e => setOtherApp(e.target.value)}
              className={"other-input"}
              placeholder={t("onboarding.setup.step1-other", "Which other app are you using?")} />
          </div>
          <hr style={{ margin: '1px 0', background: '#434343' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <p style={{
                color: 'white',
                fontSize: 16,
                margin: 0,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }}>{t('onboarding.setup.step1-crm', 'Do you use a CRM system?')}
              </p>
              <label className="switch">
                <input type="checkbox" checked={usingCRM} onClick={e => {
                  const checked = e.target.checked;
                  setUsingCRM(checked);
                }} />
                <span className="slider round"></span>
              </label>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 16,
              marginTop: 24,
              height: usingCRM ? 40 : 0,
              overflow: 'hidden',
              transition: 'height 0.5s',
              transitionTimingFunction: 'ease-in-out'

            }}>
              {CRMs.map(app =>
                <AppCard key={app.name} onClick={() => handleToggleApp(app, true)}
                  selected={!!usingCRMs.filter(a => a === app.name).length}
                  app={app} />)}
            </div>
            <div
              style={{
                width: '100%', display: 'flex',
                overflow: 'hidden',
                marginTop: 24,
                height: usingCRMs.find(e => e === 'Other') ? 40 : 0,
                transition: 'height 0.5s',
                transitionTimingFunction: 'ease-in-out'
              }}
            >
              <input
                value={otherCRM}
                onChange={e => setOtherCRM(e.target.value)}
                className={"other-input"}
                placeholder={t("onboarding.setup.step1-crm-other", "Which other CRM system are you using?")} />
            </div>
          </div>
          <hr style={{ margin: '1px 0', background: '#434343' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
            <p style={{
              color: 'white',
              fontSize: 16,
              margin: 0,
              fontFamily: 'Roboto',
              fontWeight: '500',
              letterSpacing: 0.15,
              wordWrap: 'break-word'
            }}>{t('onboarding.setup.step1-slack', 'Do you use Slack?')}
            </p>
            <label className="switch">
              <input type="checkbox" checked={usingSlack} onClick={e => {
                const checked = e.target.checked;
                setUsingSlack(checked);
              }} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <button disabled={usingApps.length === 0} onClick={handleRedirect}>
          {t('onboarding.setup.confirm', 'Confirm and customize Integrations')}
        </button>

      </div>
    </div >
  );
};


export default Onboarding;


const apps = [
  {
    name: 'Google Meet',
  },
  {
    name: 'Zoom',
  },
  {
    name: 'Other',
  },

];
const CRMs = [
  {
    name: 'Salesforce',
  },
  {
    name: 'Hubspot',
  },
  {
    name: 'Other',
  },
];

const AppCard = ({ app, onClick, selected }) => {
  return (
    <div onClick={() => onClick(e => !e)} style={{
      background: selected === app ? '#E44867' : '#212121',
      borderRadius: 70,
      width: 'fit-content',
      height: 40,
      padding: '8px 16px',
      boxSizing: 'border-box',
      display: 'flex',
      gap: 8,
      margin: 0,
      userSelect: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      border: '2px #E44867 solid',
      color: 'white'
    }}>
      {selected ? <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="14" height="14" rx="1" fill="white" stroke="#434343" strokeWidth="2" />
          <path d="M5 8L6.84515 9.97694C7.31321 10.4784 8.13573 10.3705 8.45855 9.76521L11 5"
            stroke="#E44867" strokeWidth="3" strokeLinecap="round" />
        </svg>
      </> : <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="14" height="14" rx="1" fill="#C6C6C6" stroke="#434343"
            strokeWidth="2" />
        </svg>
      </>}
      <div style={{
        color: 'white',
        fontSize: 16,
        fontFamily: 'Source Sans Pro',
        fontWeight: '600',
        wordWrap: 'break-word'
      }}>
        {app.name}
      </div>
    </div>
  );
};

import ComputerMonitorSvg from '../../images/ComputerMonitorSvg'

export function MobileNotAvailableState(props) {
	return <div className="edge-case-state">
		<ComputerMonitorSvg/>
		<h3>Briefly is not available on mobile, please access from desktop</h3>
		<a href="https://brieflyai.com" target="_blank" rel="noreferrer">
			<button className="inline">Find out more about Briefly</button>
		</a>
	</div>
}

export default MobileNotAvailableState
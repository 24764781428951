import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Tooltip } from 'react-tooltip';
import X from '../Icons/X';
import {
  sendTeamInvitation, openCheckoutSessionInNewTab,
  openCustomerPortalSessionInNewTab,
  confirmTeamInvitation,
  formatDateDDMMYYYY,
  rejectTeamInvitation,
  removeMyselfFromTeam,
  removeTeamMember,
  deleteRejectedInvite
} from '../../utils/genericUtils';
import { ButtonWithTimeoutReplaceOnClick } from '../../utils/ButtonWithTimeoutReplaceOnClick';
import { useUserData } from '../hooks/useUserData';
import CopyToClipboard from '../CopyToClipboard';
import QuestionMark from '../Icons/QuestionMark';
import { useTranslation, Trans } from 'react-i18next';

function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function SettingsTabSubscriptions() {
  const [
    { profile, subscription_is_active, pending_invites, admin_user, managed_users_not_confirmed, managed_users, admin_user_subscription_is_active, briefly_ai_credits_left,
      subscription_cancels_at_period_end, billing_period_end, users_who_rejected, was_upgraded_to_team, min_seats, forecasted_monthly_bill, coupon_percentage_applied,
      subscription_plan, coupon_fixed_price_applied, email, coupon_percentage_applied_team_members
    },
    forceRenewUserData
  ] = useUserData();

  const { t } = useTranslation();

  const [showAddTeamMemberPopup, setShowAddTeamMemberPopup] = useState(false);
  const [csvEmailsToBeInvites, setCsvEmailsToBeInvited] = useState("");

  const emailsToBeInvited = csvEmailsToBeInvites.split(',').map(x => x.trim().toLowerCase()).filter(x => x);
  const emailsAreValid = emailsToBeInvited.length > 0 && !emailsToBeInvited.find(x => !validateEmail(x));
  const isOutOfSeats = managed_users.concat(managed_users_not_confirmed).length >= min_seats;
  const isTeamMemberAccount = !!admin_user;
  const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount;
  const isEnterprise = (subscription_is_active || isProGivenForFree) && !!min_seats;
  const isTeamAccount = (isEnterprise || (managed_users.concat(managed_users_not_confirmed).concat(users_who_rejected)).length > 0 || was_upgraded_to_team);
  const pendingInvite = pending_invites?.[0];
  const seatPrice = 15 * (100 - coupon_percentage_applied) * (100 - coupon_percentage_applied_team_members) / 100 / 100;
  const PRICE_PER_SEAT = seatPrice;

  const stripeSubscriptionState = subscription_is_active && !isTeamMemberAccount && isProGivenForFree ? <div className="row" id="stripe-subscription-state" style={{ alignItems: 'center' }}>
    <span>
      {subscription_cancels_at_period_end ? `${t('settings.subscription.state.canceled', 'Membership cancelled: ends on')} ${formatDateDDMMYYYY(billing_period_end)}` : `${t('settings.subscription.state.renew', 'Monthly billing renews on')} ${formatDateDDMMYYYY(billing_period_end)}`}
    </span>
    <span>
      ${(Math.round((100 - coupon_percentage_applied) * forecasted_monthly_bill - coupon_fixed_price_applied) / 100).toFixed(2)}
      <QuestionMark data-tooltip-id={"tooltip-payment"} style={{ display: 'inline-block', marginLeft: 5, color: '#C6C6C6' }} />
      <Tooltip id="tooltip-payment" place="top" style={{ background: '#2c2c2c' }}>
        <div style={{ maxWidth: '220px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
          <b>{t('settings.subscription.state.tooltip.title', 'How is this calculated?')}</b><br /><br />
          <span style={{ color: '#C6C6C6' }}>{t('settings.subscription.state.tooltip.part1', 'Monthly charges are prorated at')} ${seatPrice} {t('settings.subscription.state.tooltip.part2', 'per user. If you add or remove team members during the month, you\'ll only pay for the time they were part of the team.')}</span>
        </div>
      </Tooltip>
    </span>
  </div> : '';

  const teamMemberPopup = <div className="transcript-info-popup-container">
    <div className="popup-background" style={{}} onClick={() => setShowAddTeamMemberPopup(false)}></div>
    <div className="popup-content">
      <div className="close-icon" onClick={() => setShowAddTeamMemberPopup(false)}>
        <X />
      </div>
      <h1>{isEnterprise && isOutOfSeats ? t('settings.subscription.team-member.out-of-seats', 'You’ve run out of available seats') : t('settings.subscription.team-member.add-members', 'Add members to your team')}</h1>


      {isEnterprise ? <span className="gray-text" style={{
        fontFamily: 'Roboto',
        fontSize: "14px",
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.15px',

      }}>
        {t('settings.subscription.team-member.min_seats', { min_seats, defaultValue: 'Your enterprise membership includes {{min_seats}} seats' })}
      </span> : ''}

      {isEnterprise ? <>
        <span className="gray-text" style={{
          fontFamily: 'Roboto',
          fontSize: "14px",
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.15px'
        }}>
          {t('settings.subscription.team-member.extra_seat', { seatPrice, defaultValue: `You can add extra members for $\{{seatPrice}} each per month` })}
        </span>
      </> : ''}

      {!isEnterprise && !isOutOfSeats ? <div className="row">
        {/* exclamation icon */}
        <svg style={{ width: 19, marginRight: 8, marginTop: 2 }} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1023_50429)">
            <path d="M6 7.09155C6.3267 7.09155 6.59155 6.8267 6.59155 6.5V4.07746C6.59155 3.75076 6.3267 3.48592 6 3.48592C5.6733 3.48592 5.40845 3.75076 5.40845 4.07746V6.5C5.40845 6.8267 5.6733 7.09155 6 7.09155ZM6 9.51408C6.3267 9.51408 6.59155 9.24924 6.59155 8.92253V8.89437C6.59155 8.56766 6.3267 8.30282 6 8.30282C5.6733 8.30282 5.40845 8.56766 5.40845 8.89437V8.92253C5.40845 9.24924 5.6733 9.51408 6 9.51408ZM1.74648 2.27465C2.92958 1.09155 4.34742 0.5 6 0.5C7.65258 0.5 9.06103 1.09155 10.2254 2.27465C11.4085 3.43897 12 4.84742 12 6.5C12 8.15258 11.4085 9.57042 10.2254 10.7535C9.06103 11.9178 7.65258 12.5 6 12.5C4.34742 12.5 2.92958 11.9178 1.74648 10.7535C0.58216 9.57042 0 8.15258 0 6.5C0 4.84742 0.58216 3.43897 1.74648 2.27465Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_1023_50429">
              <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span className="gray-text" style={{ marginTop: 0, fontWeight: 400 }}>
          {t('settings.subscription.team-member.add_member.part1', 'Each new member will be added to your plan as a PRO member')} {isEnterprise ? t('settings.subscription.team-member.add_member.enterprise-part', { seatPrice, defaultValue: 'at {{seatPrice}}/user/month' }) : ' '} {t('settings.subscription.team-member.add_member.part2', 'and your billing will be updated accordingly.')}
        </span>
      </div> : ''}

      <div>
        <input value={csvEmailsToBeInvites} onChange={e => setCsvEmailsToBeInvited(e.target.value)} type="text" placeholder="name@email.com, name2@email.com, name3@email.com" />
      </div>

      <button onClick={() => {
        if (emailsToBeInvited.includes(email)) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: t('settings.subscription.team-member.add_member.is-admin', { email, defaultValue: '{{email}} can\'t be added to the team as it’s already the team admin' }),
            background: '#eee'
          });
          return;
        }

        const alreadyInvitedEmail = managed_users.concat(managed_users_not_confirmed).find(x => emailsToBeInvited.includes(x));

        if (alreadyInvitedEmail) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: t('settings.subscription.team-member.add_member.already-member', { alreadyInvitedEmail, defaultValue: `{{alreadyInvitedEmail}} can't be added to the team as it’s already a member` }),
            background: '#eee'
          });
          return;
        }

        sendTeamInvitation(emailsToBeInvited, () => {
          setShowAddTeamMemberPopup(false);
          forceRenewUserData();
        });
        setCsvEmailsToBeInvited("");
      }} style={{ marginTop: 20, marginBottom: 5, width: '160px !important' }} disabled={!emailsAreValid}>
        {isEnterprise && isOutOfSeats ? t('settings.subscription.team-member.invite-member.priced', { seatPrice, defaultValue: `Invite extra members for $\{{seatPrice}}/user/month` }) : t('settings.subscription.team-member.invite-member.non-priced', 'Send invitation')}
      </button>
    </div>
  </div>;

  const pendingInviteSection = pendingInvite ? <div id="pending-invite-container" style={{
    borderRadius: 4,
    background: '#2C2C2C',
    padding: 24,
    marginBottom: 20
  }}>
    <h2 style={{
      fontSize: 16,
      fontWeight: 900
    }}>{t('settings.subscription.invitation.title', 'You’ve been invited to join a team!')}</h2>

    <p className="gray-text">
      {t('settings.subscription.invitation.body.part1', { pendingInvite, defaultValue: 'By accepting this invitation you will be added to {{pendingInvite}}\'s team on Briefly as a PRO user.' })}{' '}
      {isTeamAccount ? t('settings.subscription.invitation.body.team-terminate', { pendingInvite, defaultValue: 'Your TEAM account will terminate and your monthly fee will be covered by {{pendingInvite}} account.' })
        : subscription_is_active ? t('settings.subscription.invitation.body.team-terminate', { pendingInvite, defaultValue: 'Your TEAM account will terminate and your monthly fee will be covered by {{pendingInvite}} account.' })
          : t('settings.subscription.invitation.body.no-terminate', { pendingInvite, defaultValue: 'Your monthly fee will be covered by {{pendingInvite}} account.' })
      }
    </p>

    <div className="row" style={{ marginTop: 10, width: 220 }}>
      <button className="reject-btn" onClick={() => rejectTeamInvitation(pendingInvite, () => {
        forceRenewUserData();
      })} style={{ padding: '7px 30px', fontWeight: 600 }}>
        {t('reject', { ns: 'Actions', defaultValue: 'Reject' })}
      </button>
      <button className="accept-btn" onClick={() => confirmTeamInvitation(pendingInvite, () => {
        forceRenewUserData();
      })} style={{ padding: '7px 30px', fontWeight: 600 }}>
        {t('accept', { ns: 'Actions', defaultValue: 'Accept' })}
      </button>
    </div>
  </div> : '';

  return <div id="settings-subscriptions">
    {showAddTeamMemberPopup ? teamMemberPopup : ''}

    {subscription_is_active || isTeamMemberAccount || isProGivenForFree ? <>
      <div className="row">
        <h2>{t('settings.subscription.tab-label', 'Subscriptions')}</h2>
        <div className="badge" style={isEnterprise ? { width: 72 } : {}}>{isEnterprise ? 'ENTERPRISE' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}</div>
      </div>
      {pendingInviteSection}
      <p className="gray-text">
        {t('settings.subscription.current.part1', 'You are currently subscribed to Briefly')} {isEnterprise ? 'Enterprise' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : isProGivenForFree ? 'PRO (FREE)' : 'PRO'}{isProGivenForFree ? '' : ','} {isProGivenForFree ? '' : isTeamMemberAccount ? t('settings.subscription.current.managed-part', { admin_user, defaultValue: '{{admin_user}} is managing your subscription' }) : t('settings.subscription.current.stripe-part', 'you can manage your membership on Stripe')}
      </p>

      {(admin_user && !admin_user_subscription_is_active) ? <p className="gray-text">
        {t('settings.subscription.current.inactive', 'Subscription is not active. Please tell account manager to renew subscription')}
      </p> : ''}

      {!isTeamMemberAccount && subscription_is_active && !isProGivenForFree ? <div>
        <button onClick={() => openCustomerPortalSessionInNewTab()}>
          {t('settings.subscription.current.manage-membership', 'Manage my membership')}
        </button>
        <button className="alt-color" onClick={() => window.open('https://www.brieflyai.com/contact-us', '_blank')} style={{ padding: '7px 30px', fontWeight: 600, marginLeft: 10 }}>
          {t('settings.subscription.current.contact-support', 'Contact support')}
        </button>

      </div> : isProGivenForFree ? '' : <button onClick={() => {
        Swal.fire({
          title: t('settings.subscription.current.remove-alert.title', { admin_user, defaultValue: 'Are you sure you want to be removed from {{admin_user}} team account?' }),
          text: t('settings.subscription.current.remove-alert.content', "By doing so you will loose all Briefly Pro account privileges and your account will be switched to a Free subscription"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: t('settings.subscription.current.remove-alert.confirm', 'Yes, remove my team membership'),
          cancelButtonText: t('settings.subscription.current.remove-alert.cancel', 'No, keep my membership'),
          background: '#eee'
        }).then((result) => {
          if (result.isConfirmed) {
            removeMyselfFromTeam(admin_user, () => {
              forceRenewUserData();
            });
          }
        });
      }}>
        {t('settings.subscription.current.remove', 'Remove team membership')}
      </button>}

      {!isTeamMemberAccount && !isProGivenForFree ? <hr style={{ margin: '20px 0', background: '#434343' }} /> : ''}
    </> : <><h2>{t('settings.subscription.tab-label', 'Subscriptions')}</h2> {pendingInviteSection} </>}

    {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) && !isProGivenForFree ? <div style={{
      color: '#C6C6C6',
      fontSize: 14,
      lineHeight: '20px'
    }}>
      {(isTeamMemberAccount && !admin_user_subscription_is_active) ? <hr style={{ margin: '20px 0', background: '#434343' }} /> : ''}
      <b>{briefly_ai_credits_left} {t('settings.subscription.current.free.credits-left', 'free AI credits left')}</b>
      <br />
      <span>{t('settings.subscription.current.free.credits-cost', 'AI summary, BrieflyCreate Output or email follow up generation, will use 1 AI credit. Transcriptions and transcript uploads are unlimited on your free plan.')}</span>
    </div> : ''}

    {subscription_is_active && !isTeamAccount ? stripeSubscriptionState : ''}

    {!isTeamMemberAccount && (subscription_is_active || isProGivenForFree) ? <div className="row">
      <div>
        <h2 style={{ margin: 0 }}>
          {isTeamAccount ? t('subscription.team.title', 'Team') : t('subscription.team.switch-title', 'Switch to team account')}
        </h2>
        <span className="gray-text" style={{
          fontFamily: 'Roboto',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '18px',
          letterSpacing: '0.3px'
        }}>
          {!isTeamAccount ?
            <Trans i18nKey="settings.subscription.team.switch.description" PRICE_PER_SEAT={PRICE_PER_SEAT}>
              By switching you’ll be billed ${{ PRICE_PER_SEAT }}.00 per month plus $\{{ PRICE_PER_SEAT }}.00 per month for each member added
            </Trans> : isEnterprise ?
              <>
                <span>{min_seats} {t('subscription.team.total-seats', 'total seats available with your plan')}</span>
                <span style={{ background: '#434343', color: '#C6C6C6' }} className="gray badge">{Math.min(min_seats, managed_users.concat(managed_users_not_confirmed).length)}{' '}{t('subscription.team.seats.used', 'used')}</span>
                <span style={{ background: '#B8B8B8', color: '#434343' }} className="gray badge">{Math.max(0, min_seats - managed_users.concat(managed_users_not_confirmed).length)}{' '} {t('subscription.team.seats.available', 'available')}</span>
                {managed_users.concat(managed_users_not_confirmed).length > min_seats ? <span className="gray badge" style={{ background: 'white', color: '#434343' }}>{managed_users.concat(managed_users_not_confirmed).length - min_seats}{' '}{t('subscription.team.seats.extra', 'extra added')}</span> : ''}
              </> : ''}
        </span>
      </div>
      <div>
        <button onClick={() => setShowAddTeamMemberPopup(true)} style={{ padding: '7px 30px', fontWeight: 600, minWidth: 168, marginLeft: 15 }}>
          {/* plus icon */}
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 6C12.5 6.46703 12.1214 6.84564 11.6544 6.84564H7.34564V11.1544C7.34564 11.6214 6.96703 12 6.5 12V12C6.03297 12 5.65436 11.6214 5.65436 11.1544V6.84564H1.34564C0.878606 6.84564 0.5 6.46703 0.5 6V6C0.5 5.53297 0.878605 5.15436 1.34564 5.15436H5.65436V0.845639C5.65436 0.378606 6.03297 0 6.5 0V0C6.96703 0 7.34564 0.378605 7.34564 0.845638V5.15436H11.6544C12.1214 5.15436 12.5 5.53297 12.5 6V6Z" fill="white" />
          </svg>
          {t('subscription.team.add-member', 'Add member')}
        </button>
        <span className="gray-text" style={{ fontSize: 10, fontWeight: 400, letterSpacing: '0.4px', textAlign: 'right' }}>
          {isTeamAccount && !isEnterprise &&
            <Trans i18nKey='subscription.team.add-member-price' PRICE_PER_SEAT={PRICE_PER_SEAT} >
              ${{ PRICE_PER_SEAT }}.00 per member per month
            </Trans>}
        </span>
      </div>
    </div> : isTeamMemberAccount || isProGivenForFree ? '' : <div className="row" style={{ marginTop: 30 }}>
      <div>
        <h2 style={{ margin: 0 }}>
          {t('subscription.upgrade.label', 'Upgrade to PRO')}
        </h2>
        <span className="gray-text" style={{ fontSize: 12, fontWeight: 400, letterSpacing: '0.4px' }}>
          <Trans i18nKey='subscription.upgrade.description' PRICE_PER_SEAT={PRICE_PER_SEAT} >
            Upgrade to PRO account for ${{ PRICE_PER_SEAT }}.00 per month
          </Trans>
        </span>
      </div>
      <div>
        <button onClick={() => openCheckoutSessionInNewTab()} style={{ padding: '7px 30px', fontWeight: 600 }}>
          {t('subscription.upgrade.button', 'Upgrade to PRO')}
        </button>
      </div>
    </div>}

    {(subscription_is_active || isProGivenForFree) && isTeamAccount ? <>
      <hr style={{ margin: '20px 0', background: '#434343' }} />
      {stripeSubscriptionState}
    </> : ''}

    {isTeamAccount ? <div id="team-user-table">
      <div className="header row">
        <h3>{t('subscription.team-table.headers.email', 'Email')}</h3>
        <h3>{t('subscription.team-table.headers.role', 'Role')}</h3>
        <div></div>
      </div>
      <div className="row">
        <div>{profile?.email}</div>
        <div>{t('subscription.team-table.row.admin', 'Admin')}</div>
        <div></div>
      </div>
      {managed_users.map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div><a onClick={() => {
              removeTeamMember(email, () => {
                forceRenewUserData();
              });
            }} className="remove-btn">{t('subscription.team-table.row.remove', 'Remove user')}</a></div>
          </div>
        </>;
      })}
      {managed_users_not_confirmed.filter(x => !managed_users.includes(x)).map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div style={{ margin: 0, minWidth: 150, display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left" }}>
                {t('subscription.team-table.row.pending', 'Pending...')}
                <br />
                <ButtonWithTimeoutReplaceOnClick
                  className="remove-btn"
                  style={{ background: 'transparent', whiteSpace: 'nowrap', padding: 0, textAlign: 'left', width: 'unset' }}
                  initialText={t('subscription.team-table.row.resend', 'Re-send invite')}
                  clickedText={t('subscription.team-table.row.sent', 'Email Sent')}
                  onClick={() => {
                    sendTeamInvitation([email], () => {
                      forceRenewUserData();
                    });
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <CopyToClipboard style={{ padding: '2px 5px' }} id="copy_invite_url" text="https://app.brieflyai.com/settings/subscriptions" />
                <Tooltip id="copy_invite_url" place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.copy-invite', 'Copy invite URL')}</b>
                  </div>
                </Tooltip>
                <button
                  style={{ alignSelf: "flex-start", background: "transparent", padding: '2px 5px', width: "unset", }} onClick={() => {
                    removeTeamMember(email, () => {
                      forceRenewUserData();
                    });
                  }}>
                  <X data-tooltip-id="remove_user" style={{ marginRight: "0" }} size='11' />
                </button>
                <Tooltip id="remove_user" place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.remove', 'Remove user')}</b>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </>;
      })}
      {users_who_rejected.map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>{t('subscription.team-table.row.member', 'Team Member')}</div>
            <div style={{ margin: 0, minWidth: 150, display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left" }}>
                {t('subscription.team-table.row.rejected', 'Rejected')}
                <br />
                <ButtonWithTimeoutReplaceOnClick
                  className="remove-btn"
                  style={{ background: 'transparent', whiteSpace: 'nowrap', padding: 0, textAlign: 'left', width: 'unset' }}
                  initialText={t('subscription.team-table.row.resend', 'Re-send invite')}
                  clickedText={t('subscription.team-table.row.sent', 'Email Sent')}
                  onClick={() => {
                    sendTeamInvitation([email], () => {
                      forceRenewUserData();
                    });
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <button
                  style={{ alignSelf: "flex-start", background: "transparent", padding: '2px 5px', width: "unset", }} onClick={() => {
                    deleteRejectedInvite(email, () => {
                      forceRenewUserData();
                    });
                  }}>
                  <X data-tooltip-id={`remove_user-${email}`} style={{ marginRight: "0" }} size='11' />
                </button>
                <Tooltip id={`remove_user-${email}`} place="top" style={{ background: '#2c2c2c' }}>
                  <div style={{ maxWidth: '150px', textAlign: "left", lineHeight: '18px', letterSpacing: '0.3px' }}>
                    <b>{t('subscription.team-table.row.remove', 'Remove user')}</b>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </>;
      })}
    </div > : ''}
  </div >;
}

export default SettingsTabSubscriptions;

import { Reducer, useEffect, useReducer } from "react"
import { FetchLanguageAction, FetchLanguageState } from "../../types/FetchLanguage"
import { LocizeInstance } from "../../i18n/config"

const initialState: FetchLanguageState = {
  result: null,
  status: 'IDLE',
  error: null
}

const fetchLanguageReducer: Reducer<FetchLanguageState, FetchLanguageAction> = (state, action) => {
  if (action.type === "FETCH_START") {
    return {
      status: "IN_PROGRESS",
      error: null,
      result: state.result
    }
  }

  if (action.type === "FETCH_ERROR") {
    return {
      status: "FAILED",
      error: action.payload,
      result: null,
    }
  }

  if (action.type === "FETCH_SUCCESS") {
    return {
      status: "SUCCESS",
      error: null,
      result: action.payload
    }
  }


  return initialState

}

export const useAvailableLanguages = () => {
  const [languageFetch, dispatch] = useReducer(fetchLanguageReducer, initialState)

  useEffect(() => {
    dispatch({ type: "FETCH_START" })

    LocizeInstance.getLanguages((err, resp) => {
      if (err) {
        dispatch({ type: "FETCH_ERROR", payload: err })
        return;
      }

      dispatch({ type: "FETCH_SUCCESS", payload: resp })
    })
  }, [])

  return languageFetch
}


export function ComputerMonitorSvg() {
	return <svg width="129" height="115" viewBox="0 0 129 115" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M93.6239 114.734H43.4811V107.609H93.6239V114.734Z" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M54.8367 57.6853H73.6877L88.3182 107.609H69.4671L54.8367 57.6853V57.6853Z" fill="#434343"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M103.991 26.313H111.219C112.783 26.3126 114.275 26.9697 115.333 28.1241C116.39 29.2786 116.915 30.8246 116.781 32.3856L112.021 87.3415C111.768 90.2294 109.354 92.4444 106.46 92.4434H99.4451L103.991 26.313Z" fill="#434343"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M27.5329 26.313H106.689C108.244 26.3136 109.729 26.9649 110.785 28.1097C111.841 29.2545 112.372 30.7889 112.25 32.3428L107.902 87.3182C107.661 90.2192 105.238 92.4493 102.332 92.4434H22.7775C21.2134 92.4451 19.7207 91.7882 18.6637 90.6333C17.6067 89.4784 17.0828 87.9317 17.2198 86.3708L21.9868 31.4188C22.2359 28.5343 24.643 26.3183 27.5329 26.313V26.313Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M22.776 92.4434H102.759C105.443 92.4436 107.676 90.3663 107.887 87.6725L108.631 78.0013H18.1938L17.4495 86.6014C17.3206 88.1037 17.8239 89.5914 18.8366 90.7021C19.8493 91.8129 21.2785 92.4446 22.776 92.4434Z" fill="#E44867"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M93.6239 114.734H43.4811V107.609H93.6239V114.734Z" fill="#2C2C2C" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M27.5329 26.313H106.689C108.244 26.3136 109.729 26.9649 110.785 28.1097C111.841 29.2545 112.372 30.7889 112.25 32.3428L107.902 87.3182C107.661 90.2192 105.238 92.4493 102.332 92.4434H22.7775C21.2134 92.4451 19.7207 91.7882 18.6637 90.6333C17.6067 89.4784 17.0828 87.9317 17.2198 86.3708L21.9868 31.4188C22.2359 28.5343 24.643 26.3183 27.5329 26.313V26.313Z" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="61.584" cy="84.9109" rx="3.23614" ry="3.24206" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.04551 0.365433H93.1689C95.3267 0.365433 97.0761 2.11171 97.0761 4.26585V65.1127C97.0761 67.2669 95.3267 69.0131 93.1689 69.0131H5.04551C2.88762 69.0131 1.13831 67.2669 1.13831 65.1127V4.26585C1.13831 2.11171 2.88762 0.365433 5.04551 0.365433Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M33.4298 49.8854H65.2056C66.6191 49.8854 67.765 51.0293 67.765 52.4403V58.7258C67.765 60.1368 66.6191 61.2807 65.2056 61.2807H33.4298C32.0163 61.2807 30.8705 60.1368 30.8705 58.7258V52.4403C30.8705 51.0293 32.0163 49.8854 33.4298 49.8854Z" fill="#E44867"/>
		<path d="M42.5707 55.5822H56.0631" stroke="white" strokeWidth="0.483835" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.04551 0.365433H93.1689C95.3267 0.365433 97.0761 2.11171 97.0761 4.26585V65.1127C97.0761 67.2669 95.3267 69.0131 93.1689 69.0131H5.04551C2.88762 69.0131 1.13831 67.2669 1.13831 65.1127V4.26585C1.13831 2.11171 2.88762 0.365433 5.04551 0.365433Z" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M49.3169 8.07664H77.763" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M10.3517 8.07664H45.2705" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M10.3517 11.679H70.5925" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M10.3517 15.283H32.5578" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M36.6108 15.283H57.9667" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M17.9989 32.2902H74.8994" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="16.8325" cy="32.2705" rx="6.48082" ry="6.46304" fill="#F97238"/>
		<path d="M13.9541 32.2297L16.6916 34.4483L19.7108 30.7201" stroke="white" strokeWidth="0.483835" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse opacity="0.1" cx="81.3802" cy="32.2705" rx="6.48082" ry="6.46304" fill="#C6C6C6"/>
		<ellipse cx="49.1072" cy="32.2705" rx="6.48082" ry="6.46304" fill="#F97238"/>
		<path d="M46.2272 32.2297L48.9646 34.4483L51.9855 30.7201" stroke="white" strokeWidth="0.483835" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M89.3799 51.4623L99.645 79.9463C99.9095 80.6783 100.533 81.2224 101.293 81.3859C102.054 81.5494 102.846 81.3094 103.388 80.7507L109.58 74.3666L120.574 84.4245C121.57 85.335 123.113 85.2733 124.032 84.2865L127.982 80.0424C128.437 79.5537 128.673 78.901 128.636 78.2345C128.599 77.568 128.293 76.9453 127.787 76.5096L115.838 66.1996L122.171 59.053C122.614 58.5536 122.761 57.8581 122.558 57.2222C122.356 56.5864 121.834 56.104 121.184 55.9525L91.55 49.0281C90.8877 48.8722 90.1933 49.0915 89.7405 49.5993C89.2877 50.1072 89.1494 50.8222 89.3799 51.4623Z" fill="#434343"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M89.3799 51.4623L99.645 79.9463C99.9095 80.6783 100.533 81.2224 101.293 81.3859C102.054 81.5494 102.846 81.3094 103.388 80.7507L109.58 74.3666L120.574 84.4245C121.57 85.335 123.113 85.2733 124.032 84.2865L127.982 80.0424C128.437 79.5537 128.673 78.901 128.636 78.2345C128.599 77.568 128.293 76.9453 127.787 76.5096L115.838 66.1996L122.171 59.053C122.614 58.5536 122.761 57.8581 122.558 57.2222C122.356 56.5864 121.834 56.104 121.184 55.9525L91.55 49.0281C90.8877 48.8722 90.1933 49.0915 89.7405 49.5993C89.2877 50.1072 89.1494 50.8222 89.3799 51.4623V51.4623Z" stroke="#C6C6C6" strokeWidth="0.322556" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
}

export default ComputerMonitorSvg
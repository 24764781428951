import React from "react";
import { SummarySection } from './meetingTabs/MeetingTabSummary.js';

interface FieldMappingFieldContext {
  summary?: string[],
  completion: {
    field_mapping: string[],
  }
}

interface FieldMapping {
  id: number,
  label: string,
  prompt: string,
}

interface FieldGroup {
  name: string,
  field_mappings: FieldMapping[],
}

interface WorkflowNotification {
  field_group: FieldGroup,
}

interface WorkflowRun {
  fields: Record<number, FieldMappingFieldContext>,
  workflowNotification: WorkflowNotification,
}

interface Props {
  workflowRun: WorkflowRun,
}

export default function LiveChecklistSummaries({ workflowRun }: Props) {

  const { fields, workflowNotification: { field_group: fieldGroup, field_group: { field_mappings } } } = workflowRun;

  return (
    <div>
      <h4>✅ {fieldGroup.name} Checklist</h4>
      {field_mappings.map((fieldMapping) => {
        const fieldValue = fields[fieldMapping.id];

        const points = fieldValue ? fieldValue.summary || fieldValue.completion.field_mapping : ['-'];

        return <SummarySection
          key={`summary-section-${fieldMapping.id}`}
          header={fieldMapping.label}
          keyIdentifier={'live_checklist'}
          text={points.join("\n")}
        />

      })}
    </div>
  )
}

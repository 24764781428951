import React, { useEffect, useMemo, useState, useRef } from "react";
import { ThreeDots } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import useScrollOnDrag from 'react-scroll-ondrag';
import { useDebounce } from 'use-debounce';

import {
  formatDate, formatDatetime, highlightText, mapObject, groupBy, getUploadedTranscriptsRequest, getTranscriptionAsText, getContextStrings, getContextStringsFromList, mergeObjectsByMeetingId, countWordSetMatches, removeMeetingById, removeBackendMeetingById, trackEvent, plural
} from '../utils/genericUtils';
import { useDataFromEndpoint } from "./hooks/useDataFromEndpoint";
import { useUserData } from './hooks/useUserData';
import SortByDropdown from './controls/SortByDropdown';
import { DoubleLeftArrowIcon, FileUploadIcon, GoogleCalendarLogo, GoogleMeetLogo, HubspotCircleLogo, SalesforceLogo, SearchIcon, SlackLogo, TrashCanIcon, XCircleIcon, ZoomLogo } from './Icons';
import { LoaderData } from "../types/LoaderData";
import { MeetingData, SearchMeetingData } from "./meetingTabs/Types";


function getMeetingLabel(m) {
  if (m.meeting_status && m.meeting_status !== 'ready') {
    if (m.meeting_status === "ongoing") {
      if (!m.recording_started_zoom) {
        if (!m.meeting_ended_zoom) {
          return m.meetingName;
        } else {
          return "Meeting ended. Recording was not started";
        }
      } else {
        return "Transcribing...";
      }
    }
    if (m.meeting_status === "processing") {
      return "Processing...";
    }
    if (m.meeting_status === "captions_missing") {
      return "Transcript not available";
    }
    if (m.meeting_status === "recording_permission_denied") {
      return "Permission denied";
    }

    return "error";
  }
  return m.meetingName;
}
function getMeetingIcon({ m, style }: { m: MeetingData, style?: React.CSSProperties; }) {
  if (m.meetingPlatform === 'zoom') {
    return <ZoomLogo size={12} style={style} />;
  }
  if (m.meetingPlatform == 'Google Meets') {
    return <GoogleMeetLogo width="12" style={{ ...style }} />;
  }
  if (m.isBackend) {
    return <FileUploadIcon width="12" style={{ ...style }} />;
  }
  return <GoogleMeetLogo width="12" style={{ ...style }} />;
}

const SHOW_UPLOAD_TIP_KEY = 'SHOW_UPLOAD_TIP_KEY';

interface Props {
  setShowZoomOnboardingPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSlackTip: React.Dispatch<React.SetStateAction<boolean>>;

}

const CallList = ({ setShowZoomOnboardingPopup, setShowSlackTip }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchQueryParam = urlSearchParams.get('search');
  const [searchQuery, setSearchQuery] = useState(searchQueryParam || "");
  const [searchQueryDebounce] = useDebounce(searchQuery, 1000);
  const [searchResults, setSearchResults] = useState(null);

  const [isPastCallsPanelCollapsed, setIsPastCallsPanelCollapsed] = useState(false);
  const [showRemoveMeetingPopupForMeeting, setShowRemoveMeetingPopupForMeeting] = useState<MeetingData | null>(null);


  const showUploadTipIcon = false;
  const [showUploadTip, setShowUploadTip] = useState(!localStorage.getItem(SHOW_UPLOAD_TIP_KEY));

  const SORTING_NEWEST_KEY = 'newest';
  const SORTING_OLDEST_KEY = 'oldest';
  const SORTING_BEST_MATCH_KEY = 'best_match';

  const SORTING_CONFIG = [
    {
      key: SORTING_NEWEST_KEY,
      label: t('root-sidebar.search-dropdown.newest', 'Newest'),
      // @ts-expect-error: use date for sort
      apply: (results: MeetingData) => Object.entries(results).sort((a, b) => new Date(b[0]) - new Date(a[0]))
    }, {
      key: SORTING_OLDEST_KEY,
      label: t('root-sidebar.search-dropdown.oldest', 'Oldest'),
      // @ts-expect-error: use date for sort
      apply: (results: MeetingData) => Object.entries(results).sort((a, b) => -(new Date(b[0]) - new Date(a[0])))
    }, {
      key: SORTING_BEST_MATCH_KEY,
      label: t('root-sidebar.search-dropdown.best-match', 'Best Match'),
      apply: (results: MeetingData, search: string) => {
        const res = [];
        let i = 0;
        for (const date in results) {
          for (const result of results[date]) {
            // @ts-expect-error: todo
            res.push([
              i++,
              [result]
            ]);
          }
        }

        const priority = (meeting: SearchMeetingData) => {
          const { meetingName } = meeting;
          let { transcriptMatchesCount, summaryMatchesCount, outputMatchesCount } = meeting;

          transcriptMatchesCount = transcriptMatchesCount || 0;
          summaryMatchesCount = summaryMatchesCount || 0;
          outputMatchesCount = outputMatchesCount || 0;

          const totalMatchGroups = [transcriptMatchesCount, summaryMatchesCount, outputMatchesCount].filter(x => x > 0).length;
          const totalMatches = [transcriptMatchesCount, summaryMatchesCount, outputMatchesCount].reduce((x, y) => x + y, 0);
          const isMeetingNameMatch = getContextStrings(meetingName, search.split('/\s+/'), 1).length > 0;
          const isFullMeetingNameMatch = countWordSetMatches(meetingName, search.split('/\s+/')) > 0;

          const GROUPS_CNT_WEIGHT = 5;
          const TOTAL_MATCHES_WEIGHT = 1;
          const MEETING_NAME_WEIGHT = 4;
          const MEETING_NAME_FULL_MATCH_WEIGHT = 4;

          // @ts-expect-error: todo
          return totalMatchGroups * GROUPS_CNT_WEIGHT + TOTAL_MATCHES_WEIGHT * totalMatches + isMeetingNameMatch * MEETING_NAME_WEIGHT + isFullMeetingNameMatch * MEETING_NAME_FULL_MATCH_WEIGHT;

        };

        return res.sort((a, b) => priority(b[1][0]) - priority(a[1][0]));
      }
    }];

  const [currentSorting, setCurrentSorting] = useState(SORTING_CONFIG[0]);

  const { meetingDataById: initialMeetingDataById, brieflyIsInstalled } = useLoaderData() as LoaderData;
  const [meetingDataById, setMeetingDataById] = useState(initialMeetingDataById);
  const [removedMeetingIds, setRemovedMeetingIds] = useState<string[]>([""]);

  const [userData] = useUserData();
  const {
    is_integration_active_google_calendar,
    is_integration_active_hubspot,
    is_integration_active_salesforce,
    slack_workspace_name,
    zoom_is_setup,
    features = {}
  } = userData;

  const horizontalScrollRef = useRef();
  const { events: horizontalScrollEvents } = useScrollOnDrag(horizontalScrollRef, {});

  const INTEGRATIONS_CONFIG = [
    {
      icon: <GoogleMeetLogo width="27" style={{ pointerEvents: 'none', }} />,
      is_enabled: brieflyIsInstalled,
      label: 'Google Meet',
      // @ts-expect-error: todo
      onClick: !brieflyIsInstalled ? () => window.open('https://chromewebstore.google.com/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk', '_blank').focus() : null
    },
    {
      icon: <ZoomLogo size="27" style={{ pointerEvents: 'none' }} />,
      is_enabled: zoom_is_setup,
      label: 'Zoom',
      onClick: zoom_is_setup ? () => navigate('/settings/integrations/zoom') : () => setShowZoomOnboardingPopup(true)
    },
    {
      icon: <GoogleCalendarLogo size="27" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_google_calendar,
      label: 'Google Calendar',
      onClick: () => navigate('/settings/integrations')
    },
    // {
    //   icon: GoogleDriveIcon,
    //   is_enabled: is_integration_active_google_drive,
    //   label: 'Google Drive'
    // },
    {
      icon: <SlackLogo width="27" style={{ pointerEvents: 'none' }} />,
      is_enabled: !!slack_workspace_name,
      label: 'Slack',
      onClick: () => navigate('/settings/integrations/slack')
    },
    {
      icon: <HubspotCircleLogo size="27" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_hubspot,
      label: 'Hubspot',
      onClick: () => navigate('/settings/integrations/hubspot')
    },
    {
      icon: <SalesforceLogo width="27" style={{ pointerEvents: 'none' }} />,
      is_enabled: is_integration_active_salesforce,
      label: 'Salesforce',
      onClick: () => navigate('/settings/integrations/salesforce')
    }
  ];

  const removeMeeting = (meetingId) => {
    removeMeetingById(meetingId);

    setRemovedMeetingIds(removedMeetingIds.concat([meetingId]));
  };

  const removeBackendMeeting = (meetingId) => {
    removeBackendMeetingById(meetingId);

    setRemovedMeetingIds(removedMeetingIds.concat([meetingId]));
  };

  const chronologicalMeetingDataByDay = useMemo<Record<string, [MeetingData]>>(() => {
    return mapObject(groupBy(Object.values(meetingDataById).filter(meeting => meeting.meetingStartDate), (meeting: { meetingStartDate: string; }) => meeting.meetingStartDate.split('T')[0]), lst => lst.sort(function (a, b) {
      // @ts-expect-error: sorting by date
      return new Date(b.meetingStartDate) - new Date(a.meetingStartDate);
    }));
  }, [meetingDataById]);

  const [workflowRuns] = useDataFromEndpoint(`/api/workflow_runs`, { params: { taskSet: 'sync_field_mappings' } } );

  const workflowRunsByTranscriptId = useMemo(() => {
    if (workflowRuns == null) return {};

    return workflowRuns.reduce((result, workflowRun) => {
      if (result[workflowRun.transcriptId]) return result;
      result[workflowRun.transcriptId] = workflowRun;
      return result;
    }, {});
  }, [workflowRuns?.length]);

  useEffect(() => {
    if (!searchQuery) {
      setCurrentSorting(SORTING_CONFIG[0]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (meetingDataById?.['demo-meeting'] && userData?.features?.show_demo_meeting === false) {
      const { ...otherMeetings } = meetingDataById;
      delete otherMeetings['demo-meeting'];
      setMeetingDataById(otherMeetings);
    }
  }, [userData?.features?.show_demo_meeting, meetingDataById]);

  useEffect(() => {

    async function f() {

      if (!searchQueryDebounce) {
        return;
      }

      const words = searchQueryDebounce.split(' ').map(w => w.toLowerCase());
      const localItemsThatMatch = Object.values(meetingDataById).filter(x => !x.isBackend).filter(meeting => {
        return !words.map(w => {
          if (!meeting.transcriptionBlocks) {
            return false;
          }
          return !!meeting.meetingName.toLowerCase().startsWith(w) || !!meeting.transcriptionBlocks.find(b => b.text.toLowerCase().startsWith(w));
        }).includes(false);

      }).map(meeting => {
        return {
          ...meeting,
          transcriptContextStrings: getContextStrings(getTranscriptionAsText(meeting), words, 5),
          transcriptMatchesCount: countWordSetMatches(getTranscriptionAsText(meeting), words)
        };
      });

      const backendItemsThatMatch = (await getUploadedTranscriptsRequest(searchQueryDebounce)).transcripts.map(x => {
        return {
          ...meetingDataById?.['bkend-' + x.transcriptId],
          ...x
        };
      }).map(meeting => ({
        ...meeting,
        transcriptContextStrings: meeting.search_context_strings,
        transcriptMatchesCount: meeting.search_matches_count
      }));

      const promptsSummariesItemsThatMatch = Object.values(meetingDataById).map(meeting => {
        // @ts-expect-error: let null pass through
        const prompts = JSON.parse(localStorage.getItem(meeting.meetingId + '-customised-prompts')) || [];
        // @ts-expect-error: let null pass through
        const summary = JSON.parse(localStorage.getItem('summary-cache-' + meeting.meetingId));
        const outputContextStrings = prompts ? getContextStringsFromList(prompts.map(x => x.output), words, 5) : [];

        const summaryItemsList = summary ? [
          summary.short_summary,
          summary.long_summary,
          summary.key_insights,
          summary.action_items
        ] : [];

        const summaryContextStrings = summary ? getContextStringsFromList(summaryItemsList, words, 5) : [];

        const outputMatchesCount = prompts ? countWordSetMatches(prompts.map(x => x.output).join(' '), words) : 0;
        const summaryMatchesCount = prompts ? countWordSetMatches(summaryItemsList.join(' '), words) : 0;

        return {
          ...meeting,
          outputContextStrings,
          summaryContextStrings,
          outputMatchesCount,
          summaryMatchesCount
        };
      }).filter(x => x.outputMatchesCount > 0 || x.summaryMatchesCount > 0);

      setSearchResults(
        mapObject(
          groupBy(
            mergeObjectsByMeetingId(localItemsThatMatch.concat(backendItemsThatMatch).concat
              // @ts-expect-error: todo
              // eslint-disable-next-line no-unexpected-multiline
              (promptsSummariesItemsThatMatch)),
            meeting => meeting.meetingStartDate.split('T')[0]
          ),
          lst => lst.sort(function (a, b) {
            // @ts-expect-error: use date for sort
            return new Date(b.meetingStartDate) - new Date(a.meetingStartDate);
          })
        ));
    }

    f();
  }, [searchQueryDebounce]);

  return (
    <>
      {showRemoveMeetingPopupForMeeting && <div className="transcript-info-popup-container" id="slack-tip-popup">
        <div className="popup-background" style={{}} onClick={() => setShowSlackTip(false)}></div>
        <div className="popup-content">

          <h1 style={{
            "color": "#FFF",
            "textAlign": "center",
            "fontFamily": "\"Source Sans Pro\"",
            "fontSize": "24px",
            "fontStyle": "normal",
            "fontWeight": "900",
            "lineHeight": "normal"
          }}>
            Are you sure you want to delete this call?
          </h1>

          <p style={{
            marginTop: 20,
            marginBottom: 20,
            "color": "var(--text-text-primary, #FFF)",
            "textAlign": "center",
            "fontFeatureSettings": "'clig' off, 'liga' off",
            "fontFamily": "Roboto",
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": "500",
            "lineHeight": "22px",
            "letterSpacing": "0.15px"
          }}>
            This action cannot be reversed and this call and its associated data will be permanently removed.
          </p>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button className="alt-color" onClick={() => {
              setShowRemoveMeetingPopupForMeeting(null);
            }} style={{
              fontSize: 16,
              padding: '1px 30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 10
            }}>
              Cancel
            </button>
            <button onClick={() => {
              const m = showRemoveMeetingPopupForMeeting;
              if (m.isBackend)
                removeBackendMeeting(m.meetingId);
              else
                removeMeeting(m.meetingId);

              setShowRemoveMeetingPopupForMeeting(null);
            }} style={{
              fontSize: 16,
              padding: '1px 30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 10
            }}>
              Yes, delete this call
            </button>
          </div>

        </div>
      </div>}
      <div id="calls-list" className={isPastCallsPanelCollapsed ? 'collapsed' : ''}>
        <button id="hide-past-calls"
          onClick={() => setIsPastCallsPanelCollapsed(!isPastCallsPanelCollapsed)}>
          <DoubleLeftArrowIcon />
          {isPastCallsPanelCollapsed ? '' : t('hide', { ns: 'Actions', defaultValue: 'Hide' })}
        </button>

        {features?.integrations && !isPastCallsPanelCollapsed && <>
          <h4>{t('root-sidebar.integrations.title', 'Connect your apps')}</h4>
          <p style={{
            marginTop: 0,
            "color": "var(--text-text-secondary, #C6C6C6)",
            "fontFeatureSettings": "'clig' off, 'liga' off",
            "fontFamily": "Roboto",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "400",
            "lineHeight": "20px",
            "letterSpacing": "0.15px"
          }}>
            {t('root-sidebar.integrations.description.part1', 'Select which app you want to integrate with Briefly to get started.')} <a
              onClick={() => navigate('/settings/integrations')} style={{
                cursor: 'pointer',
                fontWeight: 700,
                "color": "var(--color-brand-briefly, #E44867)",
                "textDecorationLine": "underline"
              }}>
              {t('root-sidebar.integrations.description.link', 'Manage your integrations in settings.')}
            </a>
          </p>

          <div className="connect-your-apps-container" {...horizontalScrollEvents} ref={horizontalScrollRef}
            style={{ overflowX: 'scroll', overflowY: 'hidden', paddingTop: 5 }}>
            <div style={{ width: 342, display: 'flex' }}>
              {INTEGRATIONS_CONFIG.map((integration, i) => {
                const { onClick, icon, label, is_enabled } = integration;

                return <div key={label}>
                  <div className="integration-item" style={{
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 10,
                    marginBottom: 15,
                    position: 'relative',
                    width: 46,
                    height: 46,
                    cursor: 'pointer',
                    borderRadius: 5,
                    background: '#212121'
                  }} onClick={() => {
                    navigate('/settings/integrations');
                    if (onClick) {
                      onClick();
                    }
                  }} data-tooltip-id={"tooltip-button-" + i}
                    data-tooltip-content={label + (is_enabled ? '' : ' not') + (label === "Google Meet" ? ' installed' : label === "Hubspot" || label === "Salesforce" ? ' enabled' : ' connected')}>
                    {icon}
                    <div style={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      border: '1px solid white',
                      background: is_enabled ? '#80BF07' : '#FF3A3A',
                      position: 'absolute',
                      top: -3,
                      right: -4
                    }} />
                  </div>
                  <Tooltip id={"tooltip-button-" + i} place="bottom"
                    content={label + (is_enabled ? '' : ' not') + (label === "Google Meet" ? ' installed' : label === "Hubspot" || label === "Salesforce" ? ' enabled' : ' connected')} />
                </div>;
              })}
            </div>
          </div>

          <div style={{
            borderBottom: '1px solid #2C2C2C',
            height: 0,
            width: 400,
            position: 'relative',
            left: -50
          }}></div>

        </>}

        {!isPastCallsPanelCollapsed && <h4>{t('root-sidebar.title', 'Past calls')}</h4>}
        {!isPastCallsPanelCollapsed && <button id="upload-transcript-btn" style={{
          position: 'relative', background: showUploadTip ? 'transparent' : ''
        }} onClick={() => {
          trackEvent("briefly-upload-transcript-navigate", {});
          setShowUploadTip(false);
          localStorage.setItem(SHOW_UPLOAD_TIP_KEY, '1');

          navigate('/upload-transcript');
        }}>
          {t('root-sidebar.upload.button', 'Upload a transcript')}
          {showUploadTipIcon && <div className="ping" onClick={e => {
            e.stopPropagation();
            setShowUploadTip(true);
            localStorage.setItem(SHOW_UPLOAD_TIP_KEY, '1');
          }}></div>}
          {showUploadTip && <div className="upload-tip-container">
            <div className="upload-tip-background" onClick={e => {
              e.stopPropagation();
              setShowUploadTip(false);
              localStorage.setItem(SHOW_UPLOAD_TIP_KEY, '1');
            }}></div>
            <div className="upload-tip-content">
              <button id="upload-transcript-btn-duplicate" style={{ width: '100%', height: '100%', marginTop: 0 }}
                onClick={() => {
                  // @ts-expect-error: todo
                  document.getElementById("upload-transcript-btn").click();
                }}>
                {t('root-sidebar.upload.button', 'Upload a transcript')}
              </button>
              <div className="tip" onClick={e => e.stopPropagation()} style={{ cursor: 'default' }}>
                <div className="badge">NEW</div>
                <p style={{ marginBottom: 0 }}>{t('root-sidebar.upload.tip', 'You can now upload any transcript or text into Briefly')}</p>
              </div>
            </div>
          </div>}
        </button>}
        <div style={{ position: 'relative' }}>
          <input style={!searchQuery ? {} : { borderColor: '#C6C6C6' }} id="call-search" type="text"
            placeholder={t("root-sidebar.search.placeholder", "Search calls, transcripts and summaries")} value={searchQuery} onChange={e => {
              setSearchResults(null);
              setSearchQuery(e.target.value);
            }} />
          <SearchIcon color="#C6C6C6" />
          <XCircleIcon
            size={14}
            color="#C6C6C6"
            style={!searchQuery ? { display: 'none' } : {}}
            onClick={() => {
              setSearchResults(null);
              setSearchQuery('');
            }}
          />

        </div>

        {searchQueryDebounce && <div style={{ marginTop: 10 }}>
          <SortByDropdown items={SORTING_CONFIG} selectedItem={currentSorting}
            onChange={item => setCurrentSorting(item)} />
        </div>}
        {/* @ts-expect-error: use dates to sort */}
        {!searchQuery && Object.entries(chronologicalMeetingDataByDay).sort((a, b) => new Date(b[0]) - new Date(a[0])).map(entry => {
          const date = entry[0];
          const meetings = entry[1];
          if (!meetings.find(m => !removedMeetingIds.includes(m.meetingId))) {
            return '';
          }
          return <div key={date}>
            <span className="meeting-start-date">{formatDate(date)}</span>
            {meetings.filter(m => m.meetingId == 'demo-meeting' || (!removedMeetingIds.includes(m.meetingId) && m.isBackend)).map(m => {
              const hasTasks = workflowRunsByTranscriptId[m.transcriptId]?.tasks.some(({ complete }) => complete === false);
              // @ts-expect-error meetinid is or highlighting
              return <div key={m.meetingId} meetingid={m.meetingId} className="call-label" onClick={() => navigate(`/meeting/${m.meetingId}`)}>
                <h4 style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  {hasTasks &&
                    <span style={{ lineHeight: 0, color: '#FF3A3A', fontSize: 24 }}>•</span>
                  }
                  {getMeetingIcon({ m })} {getMeetingLabel(m)}
                </h4>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <span className="meeting-start-date">{formatDatetime(m.meetingStartDate)}</span>
                  <span className="meeting-start-date recording-in-progress">
                    {m.meeting_status === "ongoing" ? <>
                      <svg style={{ marginRight: 3 }} xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                        viewBox="0 0 8 8" fill="none">
                        <circle opacity="0.6" cx="4" cy="4" r="2" fill="#FF3A3A" />
                      </svg>
                      {t('root-sidebar.call-item.in-progress', 'Recording in progress...')}
                    </> : ''}
                  </span>
                </div>
                <span className="delete-icon" onClick={e => {
                  e.stopPropagation();
                  setShowRemoveMeetingPopupForMeeting(m);
                }}>
                  <TrashCanIcon />
                </span>

              </div>;
            })}
          </div>;
        })}
        {searchResults && currentSorting.apply(searchResults, searchQueryDebounce).map(entry => {
          const date = entry[0];
          const meetings = entry[1];
          if (!meetings.find(m => !removedMeetingIds.includes(m.meetingId))) {
            return '';
          }

          return <div className="search-result" key={date}>
            {currentSorting.key !== SORTING_BEST_MATCH_KEY &&
              <span className="meeting-start-date">{formatDate(date)}</span>}
            {meetings.filter(m => !removedMeetingIds.includes(m.meetingId)).map(m => {


              const hasTranscriptMatch = m.transcriptMatchesCount > 0;
              const hasCreateMatch = m.outputMatchesCount > 0;
              const hasSummaryMatch = m.summaryMatchesCount > 0;

              return <div key={m.meetingId} className="call-label" onClick={() => navigate(`/meeting/${m.meetingId}${hasCreateMatch ? '?tab=create' : hasTranscriptMatch ? '?tab=transcript' : hasSummaryMatch ? '?tab=summary' : ''}`)}>
                <h4>
                  {getMeetingIcon({ m, style: { marginRight: 5 } })}
                  {highlightText(m.meetingName, searchQuery.split(/\s+/), { "fontWeight": "bold" })}
                </h4>
                <span className="meeting-start-date">{formatDatetime(m.meetingStartDate)}</span>
                <span style={m.isBackend ? { display: 'none' } : {}} className="delete-icon" onClick={e => { e.stopPropagation(); setShowRemoveMeetingPopupForMeeting(m); }}>
                  <TrashCanIcon />
                </span>
                {hasTranscriptMatch && <div className="search-match-info">
                  <div className="search-badge-container" onClick={e => {
                    e.stopPropagation();
                    navigate(`/meeting/${m.meetingId}?tab=transcript`);
                  }}>
                    <div className="search-badge-name">Transcript</div>
                    <div
                      className="search-badge-count">{m.transcriptMatchesCount} {plural('match', 'matches', m.transcriptContextStrings.length)}</div>
                  </div>
                  <p>
                    {highlightText(m.transcriptContextStrings?.slice(0, 3)?.join(" ... ") + (m.transcriptContextStrings.length > 1 ? " ... " : ""), searchQuery.split(/\s+/), { "fontWeight": "bold" })}
                  </p>
                </div>}
                {hasSummaryMatch && <div className="search-match-info">
                  <div className="search-badge-container" onClick={e => {
                    e.stopPropagation();
                    navigate(`/meeting/${m.meetingId}?tab=summary`);
                  }}>
                    <div className="search-badge-name">AI Summary</div>
                    <div
                      className="search-badge-count">{m.summaryMatchesCount} {plural('match', 'matches', m.summaryContextStrings.length)}</div>
                  </div>
                  <p>
                    {highlightText(m.summaryContextStrings?.slice(0, 3)?.join(" ... ") + (m.summaryContextStrings.length > 1 ? " ... " : ""), searchQuery.split(/\s+/), { "fontWeight": "bold" })}
                  </p>
                </div>}
                {hasCreateMatch && <div className="search-match-info">
                  <div className="search-badge-container" onClick={e => {
                    e.stopPropagation();
                    navigate(`/meeting/${m.meetingId}?tab=create`);
                  }}>
                    <div className="search-badge-name">Create</div>
                    <div
                      className="search-badge-count">{m.outputMatchesCount} {plural('match', 'matches', m.outputMatchesCount)}</div>
                  </div>
                  <p>
                    {highlightText(m.outputContextStrings?.slice(0, 3)?.join(" ... ") + (m.outputContextStrings.length > 1 ? " ... " : ""), searchQuery.split(/\s+/), { "fontWeight": "bold" })}
                  </p>
                </div>}
              </div>;
            })}
          </div>;
        })}
        {searchQuery && !searchResults && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <ThreeDots
            height="35"
            width="35"
            radius="9"
            color="#FFFFFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
          />
        </div>}
      </div>
    </>
  );
};

export default CallList;
import React, { useState } from 'react'

interface Props {
  initialIcon: React.JSX.Element,
  clickedIcon: React.JSX.Element,
  onClick: () => void,
}

export function IconWithTimeoutReplaceOnClick({ initialIcon, clickedIcon, onClick }: Props) {

  const [isClicked, setIsClicked] = useState(false)

  if (isClicked) {
    return <span>{clickedIcon}</span>
  } else {
    return <span onClick={() => {
      setIsClicked(true)
      setTimeout(() => {
        setIsClicked(false)
      }, 1000)

      onClick()
    }}>
      {initialIcon}
    </span>
  }
}

export default IconWithTimeoutReplaceOnClick
// ZoomOAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sendZoomCodeToBackend, getUserAccessToken, csrfGet } from '../utils/genericUtils'

import { BACKEND_URL } from '../config/config'

const InjectUser = () => {

  const searchParams = useParams();
  
  useEffect(() => {
    
    getUserAccessToken(({ access }) => {
      csrfGet(BACKEND_URL + '/api/debug/debug_token?email=' + searchParams.email, {
          cache: 'no-cache',
          headers: {
            'Authorization': 'Bearer ' + access
          },
          referrerPolicy: 'no-referrer'
      })
      .then(response => {
          if(response.ok) {
              return response.json()
          } else {
              alert("Error. Probably you don't have permission for this")
          }
      })
      .then(data => {
        var accessTokenKey = "user-access-token"
        var refreshTokenKey = "user-refresh-token"

        localStorage.setItem(accessTokenKey, data.access)
        localStorage.setItem(refreshTokenKey, data.refresh)

        window.location.pathname = '/'
      })
    })

  }, []);

  return "Loading..."; // Return null since we're navigating away immediately
};

export default InjectUser;

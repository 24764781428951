

export function CalendarSvg() {
	return <svg width="124" height="95" viewBox="0 0 124 95" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M18.6265 14.0782H107.461C109.636 14.0782 111.399 15.8417 111.399 18.017V90.725C111.399 92.9003 109.636 94.6637 107.461 94.6637H18.6265C16.4512 94.6637 14.6878 92.9003 14.6878 90.725V18.017C14.6878 15.8417 16.4512 14.0782 18.6265 14.0782Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M18.6265 14.0782H107.461C109.636 14.0782 111.399 15.8417 111.399 18.017V90.725C111.399 92.9003 109.636 94.6637 107.461 94.6637H18.6265C16.4512 94.6637 14.6878 92.9003 14.6878 90.725V18.017C14.6878 15.8417 16.4512 14.0782 18.6265 14.0782Z" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="44.8149" cy="80.3589" rx="6.53309" ry="6.52709" fill="#434343"/>
		<ellipse cx="63.0435" cy="80.3589" rx="6.53309" ry="6.52709" fill="#2C2C2C"/>
		<ellipse cx="81.2704" cy="80.3589" rx="6.53309" ry="6.52709" fill="#E44867"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M45.8141 80.8192C45.7055 80.7622 45.6376 80.6497 45.6376 80.5272C45.6376 80.4046 45.7055 80.2922 45.8141 80.2351L46.757 79.6907L47.7 79.1462C47.8031 79.0826 47.9328 79.0805 48.0379 79.1408C48.143 79.201 48.2066 79.3139 48.2037 79.4349V81.6112C48.2084 81.7314 48.1474 81.8446 48.0443 81.9067C47.9412 81.9689 47.8125 81.97 47.7083 81.9098L46.7653 81.3653L45.8141 80.8192Z" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M42.7126 78.506H44.9901C45.5181 78.506 45.9462 78.9341 45.9462 79.4621V81.7357C45.9462 82.2638 45.5181 82.6919 44.9901 82.6919H42.7126C42.1846 82.6919 41.7565 82.2638 41.7565 81.7357V79.4621C41.7565 78.9341 42.1846 78.506 42.7126 78.506Z" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M63.0427 76.6086C63.9105 76.6086 64.614 77.3121 64.614 78.18V79.9425C64.614 80.8103 63.9105 81.5138 63.0427 81.5138C62.1749 81.5138 61.4713 80.8103 61.4713 79.9425V78.18C61.4713 77.3121 62.1749 76.6086 63.0427 76.6086Z" fill="white"/>
		<path d="M65.5322 79.0802V79.9382C65.5327 80.5979 65.2707 81.2307 64.8039 81.6974C64.3371 82.1641 63.7038 82.4262 63.0435 82.4262V82.4262C61.6681 82.4262 60.5531 81.3123 60.5531 79.9382V79.0802" stroke="white" strokeWidth="0.580645" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M63.0435 82.4262V83.9706" stroke="white" strokeWidth="0.580645" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M61.9668 84.1092H64.1186" stroke="white" strokeWidth="0.580645" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M81.2324 78.3196C81.2324 78.3196 76.8644 78.2585 77.2112 81.0304L77.2591 81.3769C77.2975 81.6547 77.554 81.849 77.8321 81.8108L79.6041 81.5683C79.8823 81.5299 80.0767 81.2736 80.0385 80.9958L79.9906 80.6493C79.9906 80.6493 79.761 79.7352 81.2324 79.7352C81.2324 79.7352 82.7187 79.6065 82.5668 80.6493V80.6493L82.514 81.0353C82.4966 81.1584 82.529 81.2832 82.604 81.3823C82.679 81.4814 82.7904 81.5465 82.9136 81.5633L84.7649 81.8174C85.0211 81.8516 85.2566 81.6723 85.2917 81.4165L85.3445 81.0304V81.0304C85.3445 81.0304 86.1868 78.2948 81.2324 78.3196Z" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M120.536 6.63717C112.483 6.27983 80.2726 5.00398 63.6916 6.53651C62.6638 6.62969 61.8658 7.47297 61.8294 8.50438C61.6029 15.1529 60.8228 38.9939 60.6341 55.6579C60.6213 56.7744 61.506 57.6949 62.6221 57.7265C70.7755 57.9555 101.819 58.7331 119.414 57.7768C120.469 57.7215 121.307 56.8699 121.346 55.8139C121.646 48.0129 122.758 17.9084 122.491 8.62014C122.461 7.54859 121.607 6.68293 120.536 6.63717Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M120.536 6.63717C112.483 6.27983 80.2726 5.00398 63.6916 6.53651C62.6638 6.62969 61.8658 7.47297 61.8294 8.50438C61.6029 15.1529 60.8228 38.9939 60.6341 55.6579C60.6213 56.7744 61.506 57.6949 62.6221 57.7265C70.7755 57.9555 101.819 58.7331 119.414 57.7768C120.469 57.7215 121.307 56.8699 121.346 55.8139C121.646 48.0129 122.758 17.9084 122.491 8.62014C122.461 7.54859 121.607 6.68293 120.536 6.63717Z" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M60.6341 17.2265C60.7574 13.2379 60.8656 10.0545 60.9285 8.18983C60.9687 7.13313 61.7878 6.27087 62.8411 6.17665C79.875 4.60135 112.977 5.91242 121.246 6.27731C122.347 6.32429 123.226 7.21366 123.259 8.31565C123.322 10.5553 123.309 13.9727 123.259 17.9965L60.6341 17.2265Z" fill="#E44867"/>
		<path d="M77.3233 17.6819L76.4702 58.0435" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M92.5404 17.8656V58.0435" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M107.44 18.2406L106.801 58.0435" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M61.2028 28.8022L122.177 31.1501" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M60.8329 43.7022L121.762 44.3489" stroke="#C6C6C6" strokeWidth="0.387097" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M103.925 45.122L106.004 46.5403L98.91 56.9362L93.6907 50.913L95.5925 49.265L98.6724 52.8194L103.925 45.122Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M111.903 0.318321C112.966 0.318321 113.827 1.17967 113.827 2.24219V9.35618C113.827 10.4187 112.966 11.28 111.903 11.28H110.748C109.686 11.28 108.824 10.4187 108.824 9.35618V2.24219C108.824 1.17967 109.686 0.318321 110.748 0.318321H111.903ZM74.1236 0.177399C75.1861 0.177399 76.0475 1.03875 76.0475 2.10127V9.21525C76.0475 10.2778 75.1861 11.1391 74.1236 11.1391H72.9686C71.9061 11.1391 71.0447 10.2778 71.0447 9.21525V2.10127C71.0447 1.03875 71.9061 0.177399 72.9686 0.177399H74.1236Z" fill="#C6C6C6"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.38954 26.0303L1.1142 50.0929H45.612L48.9514 26.0303H5.38954V26.0303Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.38954 26.0303L1.1142 50.0929H45.612L48.9514 26.0303H5.38954V26.0303Z" stroke="#C6C6C6" strokeWidth="0.31871" strokeLinecap="round" strokeLinejoin="round"/>
		<circle cx="15.7788" cy="38.1807" r="1.49526" fill="#EC8E00"/>
		<circle cx="24.5898" cy="38.1807" r="1.49526" fill="#EC8E00"/>
		<circle cx="33.362" cy="38.1807" r="1.49526" fill="#EC8E00"/>
	</svg>
}

export default CalendarSvg
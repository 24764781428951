import {GoogleOAuthProvider} from "@react-oauth/google";

const GoogleProvider = ({children}) => {
    return (
        <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
            {children}
        </GoogleOAuthProvider>
    )
}

export default GoogleProvider



export function GoToExtension() {
	return <svg width="103" height="53" viewBox="0 0 103 53" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M58 43.4474C61.6025 46.1178 65.7739 47.1987 70.0716 47.771C75.3174 48.4704 80.3104 47.5166 85.2402 45.6092C90.1067 43.7653 93.3301 40.3955 95.1629 35.5633C98.007 27.9971 98.4494 20.3037 96.3006 12.4831C95.9845 11.2115 95.6685 9.93987 95.3525 8.60466C95.3525 8.54108 95.4157 8.41392 95.5421 8.09601C97.8806 9.74913 100.156 11.4023 102.494 12.9918C102.684 12.8646 102.81 12.7375 103 12.5467C102.937 12.0381 103.063 11.3387 102.747 11.0208C98.9551 7.46019 95.1629 3.9632 91.2444 0.529787C90.1699 -0.423938 89.2219 -0.0424495 88.5899 1.29277C88.2107 1.99216 88.0843 2.88231 87.7683 3.5817C87.073 4.91692 86.3146 6.25214 85.6194 7.58735C84.9874 8.7954 84.3553 10.067 83.6601 11.4023C85.7458 11.4658 86.2514 11.0843 90.0435 6.69721C90.3596 7.39661 90.6756 7.90526 90.9284 8.54108C93.2669 15.3443 94.5941 22.2747 93.0772 29.523C91.118 38.9967 88.3371 42.3665 77.5927 45.1641C71.5885 46.6901 65.7107 45.5456 60.0225 43.511C59.3905 43.2567 58.7584 43.0023 58.1264 42.748C58.1896 43.0659 58.0632 43.2567 58 43.4474Z" fill="#F97238"/>
		<path d="M16.7098 40.0982C17.3318 40.0982 17.869 40.3125 18.3214 40.7411C18.7738 41.1429 19 41.6384 19 42.2277C19 42.817 18.7738 43.3259 18.3214 43.7545C17.869 44.1563 17.3318 44.3571 16.7098 44.3571H15.3527V47.8125C15.3527 48.2679 15.1689 48.6696 14.8013 49.0179C14.4621 49.3393 14.0521 49.5 13.5714 49.5H10.1362V48.2143C10.1362 47.5714 9.89583 47.0357 9.41518 46.6071C8.93452 46.1518 8.35491 45.9241 7.67634 45.9241C6.99777 45.9241 6.41816 46.1518 5.9375 46.6071C5.45685 47.0357 5.21652 47.5714 5.21652 48.2143V49.5H1.78125C1.3006 49.5 0.876488 49.3393 0.508929 49.0179C0.169643 48.6696 0 48.2679 0 47.8125V44.558H1.35714C2.03571 44.558 2.60119 44.3304 3.05357 43.875C3.53423 43.4196 3.77455 42.8705 3.77455 42.2277C3.77455 41.5848 3.53423 41.0357 3.05357 40.5804C2.60119 40.125 2.03571 39.8973 1.35714 39.8973H0V36.6429C0 36.1875 0.169643 35.7991 0.508929 35.4777C0.876488 35.1295 1.3006 34.9554 1.78125 34.9554H5.42857V33.6696C5.42857 33.0804 5.64063 32.5714 6.06473 32.1429C6.51711 31.7143 7.05432 31.5 7.67634 31.5C8.29836 31.5 8.82143 31.7143 9.24554 32.1429C9.69792 32.5714 9.92411 33.0804 9.92411 33.6696V34.9554H13.5714C14.0521 34.9554 14.4621 35.1295 14.8013 35.4777C15.1689 35.7991 15.3527 36.1875 15.3527 36.6429V40.0982H16.7098Z" fill="white"/>
		<rect x="25" y="28" width="25" height="25" rx="12.5" fill="url(#paint0_linear_164_25249)"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M44.6217 35.9815C44.7691 35.8929 44.9605 35.9405 45.0492 36.088L45.2028 36.3434C46.4439 38.4076 45.2245 41.0743 42.8513 41.4857L42.6659 41.5178L42.8221 41.6565C44.9143 43.5142 43.687 46.9741 40.8919 47.0982L39.9364 47.1406L29.5394 47.5254C29.3674 47.5318 29.2229 47.3975 29.2165 47.2256C29.2102 47.0537 29.3444 46.9091 29.5163 46.9028L39.9099 46.5181L39.9122 46.518L40.8642 46.4757C43.1003 46.3764 44.0821 43.6085 42.4084 42.1224L41.285 41.1249L42.7449 40.8718C44.6866 40.5352 45.6843 38.3533 44.6688 36.6645L44.5152 36.409C44.4266 36.2616 44.4742 36.0702 44.6217 35.9815Z" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M32.1829 43.3075C32.2113 40.9114 33.078 38.5782 34.409 36.7774L34.7876 40.8232L34.7618 40.8256L34.788 40.8271L34.7904 40.8533L34.7919 40.8274L38.996 41.0731C37.1924 42.5321 34.9176 43.4354 32.4282 43.5171C31.8676 44.6911 30.9582 45.6702 29.8173 46.3158L29.7377 46.3609C30.2112 45.0559 31.0249 44.0601 32.1829 43.3075ZM34.7922 40.8228L35.0765 35.959C35.4975 35.4902 35.9581 35.0576 36.4533 34.6664L36.9194 38.6142L36.8944 38.6171L36.92 38.6192L36.923 38.6448L36.9251 38.6197L41.0023 38.9573C40.6412 39.4557 40.2392 39.923 39.8014 40.354L34.7922 40.8228ZM36.9256 38.6134L37.3028 34.0582C37.6893 33.8082 38.0925 33.5817 38.5105 33.3807L39.0335 36.422L39.0103 36.426L39.0347 36.4294L39.0389 36.4537L39.0421 36.4304L42.1962 36.8601C42.0171 37.2747 41.8133 37.6764 41.5865 38.0631L36.9256 38.6134ZM39.0435 36.4203L39.5152 32.9578C40.6335 32.5512 41.8373 32.32 43.0937 32.2981C43.1151 33.5226 42.9362 34.704 42.5871 35.8109L39.0435 36.4203Z" fill="white"/>
		<defs>
		<linearGradient id="paint0_linear_164_25249" x1="25" y1="40.5" x2="50" y2="40.5" gradientUnits="userSpaceOnUse">
		<stop stopColor="#BF00B8"/>
		<stop offset="1" stopColor="#FD792F"/>
		</linearGradient>
		</defs>
	</svg>
}

export default GoToExtension;

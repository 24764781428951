import { useTranslation } from 'react-i18next';
import { useAvailableLanguages } from '../hooks/useAvailableLanguages';
import { notifyExtensionLangChanged } from '../../utils/extension';

function SettingsTabLanguage() {
  const { t, i18n } = useTranslation();
  const { result, status } = useAvailableLanguages();

  const onLanguageSelected = (language: string) => {
    i18n.changeLanguage(language, () => {
      notifyExtensionLangChanged(language)
    })
  }

  if (status === "IDLE" || status === "IN_PROGRESS") {
    return (
      <div>
        <h2> {t('settings.language.tab-label', 'Language')} </h2>
        <span className='gray-text'>Loading...</span>
      </div>
    )
  }

  if (status === 'SUCCESS') {
    const languageSelected = result[i18n.language].nativeName;

    return (
      <div>
        <h2>
          {t('settings.language.tab-label', 'Language')}
        </h2>
        <div className='row'>
          <div>
            <span>{t('settings.language.ui-language.title', 'Application Language')}</span>
            <span className='gray-text'>
              {t('settings.language.ui-language.description', 'Change the current UI language, this does not affect your transcriptions or AI Outputs.')}
            </span>
          </div>
          <div className='dropdown-wrapper'>
            <button className='dropdown-button'>{languageSelected}</button>
            <div className='dropdown-options-container'>
              {Object.entries(result).map(([key, value]) => {
                return <button className='option-button' key={key} onClick={() => onLanguageSelected(key)}>{value.nativeName}</button>
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { SettingsTabLanguage };
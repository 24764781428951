import React from 'react';
import { SvgSize } from './types';

const EmailIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="email">
      <path id="icon" d="M18.0282 6.27193V4.38596L10 9.07895L1.97183 4.38596V6.27193L10 10.9211L18.0282 6.27193ZM18.0282 2.5C18.5603 2.5 19.0141 2.69006 19.3897 3.07018C19.7966 3.45029 20 3.88889 20 4.38596V15.614C20 16.1111 19.7966 16.5497 19.3897 16.9298C19.0141 17.3099 18.5603 17.5 18.0282 17.5H1.97183C1.43975 17.5 0.970266 17.3099 0.56338 16.9298C0.187793 16.5497 0 16.1111 0 15.614V4.38596C0 3.88889 0.187793 3.45029 0.56338 3.07018C0.970266 2.69006 1.43975 2.5 1.97183 2.5H18.0282Z" fill="currentColor" />
    </g>
  </svg>
);

export default EmailIcon;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openCheckoutSessionInNewTab, setupZoom, trackEvent, updateUserSettings } from '../utils/genericUtils';
import SlackFirstStepImage from '../images/slack-step-1.png';
import { useUserData } from './hooks/useUserData';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CheckMark from '../images/checkmark.svg';
import Radio from "./controls/Radio";
import { SLACK_CLIENT_ID } from "../config/config";
import { SlackLogo } from './Icons';


function SlackOnboardingStep1({ closePopup, goToNextStep }) {
  const [userData] = useUserData();
  const {
    subscription_is_active,
  } = userData;

  return <div>
    <h1 style={{
      marginBottom: 20,
      "color": "#FFF",
      "fontFeatureSettings": "'clig' off, 'liga' off",
      "fontFamily": "\"Source Sans Pro\"",
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly note-taker
    </h1>
    <div style={{ display: 'flex' }}>
      <div className="left-sidebar" style={{}}>
        <div style={{
          marginBottom: 12,
          maxWidth: '100%',
          overflowX: 'hidden',
          margin: "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 220,
          maxHeight: 300
        }}>
          <img src={SlackFirstStepImage} />
        </div>
      </div>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{
          margin: 0,
          "color": "var(--text-text-primary, #FFF)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "500",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          How does it work?
        </h6>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "color": "var(--text-text-primary, #FFF)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🤖 Add Briefly to your Slack workspace
        </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--text-text-secondary, #C6C6C6)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Add the Briefly app to your Slack workspace.
        </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "color": "var(--text-text-primary, #FFF)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          🛡️ Configuration permission
        </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--text-text-secondary, #C6C6C6)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          If you do not have administrative privileges in your Slack workspace, you will need to request an
          admin to add Briefly for you
        </p>
        <h6 style={{
          margin: 0,
          marginTop: 8,
          "color": "var(--text-text-primary, #FFF)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "700",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          ✍️ Personalize your experience
        </h6>
        <p style={{
          margin: 0,
          marginTop: 4,
          "color": "var(--text-text-secondary, #C6C6C6)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "20px",
          "letterSpacing": "0.15px"
        }}>
          Receive AI summaries in a private channel or via direct message
        </p>
      </div>
    </div>
    {!subscription_is_active ?
      <div
        style={{
          background: '#434343',
          border: '1px solid #6D6D6D',
          display: "flex",
          flexDirection: 'column',
          textAlign: 'center',
          padding: 16,
          marginTop: 32
        }}
      >
        <p
          style={
            {
              margin: 0,
              color: 'white',
              fontSize: 16,
              fontFamily: 'Source Sans Pro',
              fontWeight: '900',
              wordWrap: 'break-word'
            }
          }>Slack integration is a PRO feature only</p>

        <p style={{
          color: '#C6C6C6',
          fontSize: 12,

          fontFamily: 'Roboto',
          fontWeight: '400',
          wordWrap: 'break-word',
          marginTop: 16,
          marginBlock: 8
        }}>Upgrade to a PRO account to use this feature
        </p>
        <button onClick={openCheckoutSessionInNewTab}>
          Upgrade to PRO now
        </button>
      </div>
      :
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <button onClick={goToNextStep} style={{
          marginRight: 0,
          "display": "inline-flex",
          "padding": "8px 32px",
          "justifyContent": "center",
          "alignItems": "center",
          "gap": "8px",
          "borderRadius": "4px",
          "background": "var(--surface-surface-brand, #E44867)"
        }}>
          Start Slack setup
        </button>
        <div style={{}} />
        <button onClick={closePopup} style={{
          marginRight: 0,
          background: 'transparent',
          "color": "var(--color-brand-briefly, #E44867)",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "\"Source Sans Pro\"",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "600",
          "lineHeight": "16px",
          "textDecorationLine": "underline"
        }}>
          Quit Setup
        </button>
      </div>
    }
  </div>;
}

function SlackOnboardingStep2({ closePopup, goToNextStep }) {


  return <div>
    <h1 style={{
      "color": "#FFF",
      "textAlign": "left",
      "fontFeatureSettings": "'clig' off, 'liga' off",
      "fontFamily": "\"Source Sans Pro\"",
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": "900",
      "lineHeight": "normal"
    }}>
      Set up your Briefly integration for Slack
    </h1>
    <div style={{ display: "flex", flexDirection: 'row', gap: 16 }}>
      <div>
        <p style={{
          color: "var(--text-text-primary, #FFF)",
          fontFamily: 'Roboto',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '0.15px',
          textAlign: 'left'
        }}>
          Add the Briefly app to your Slack workspace.
        </p>
        <p style={{
          "color": "#C6C6C6",
          "textAlign": "left",
          "fontFeatureSettings": "'clig' off, 'liga' off",
          "fontFamily": "Roboto",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "22px",
          "letterSpacing": "0.15px"
        }}>
          If you do not have administrative privileges in your Slack workspace, you will need to request an
          admin to add Briefly app for you.
        </p>
      </div>
      <button style={{
        background: '#fff',
        textDecoration: 'none',
        width: '100px',
      }}>
        <a style={{
          textDecoration: 'none',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          textWrap: 'nowrap',
          gap: 8,
        }}
          href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:write,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,pins:write,reminders:read,reminders:write,users:read,users:read.email,groups:history&user_scope=`}
          target={'_blank'} rel="noreferrer">
          <SlackLogo width='24' height='24' />
          <div><span style={{
            color: '#212121',
            fontSize: 12.18,
            fontWeight: '600',
          }}>
            Add to Slack
          </span>
          </div>
        </a>
      </button>
    </div>

    <div style={{ textAlign: 'center', marginTop: 120 }}>
      <button onClick={goToNextStep} style={{
        marginRight: 0,
        "display": "inline-flex",
        "padding": "8px 32px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "borderRadius": "4px",
        "background": "var(--surface-surface-brand, #E44867)"
      }}>
        Confirm and complete setup
      </button>
      <div style={{}} />
      <button onClick={closePopup} style={{
        marginRight: 0,
        background: 'transparent',
        "color": "var(--color-brand-briefly, #E44867)",
        "fontFeatureSettings": "'clig' off, 'liga' off",
        "fontFamily": "\"Source Sans Pro\"",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "600",
        "lineHeight": "16px",
        "textDecorationLine": "underline"
      }}>
        Quit Setup
      </button>
    </div>
  </div>;
}

function SlackOnboardingStep3({
  closePopup,
}) {
  const [userData, forceRenewUserData] = useUserData();

  const {
    is_subscribed_to_slack_summaries,
    slack_workspace_name,
    is_summaries_dm
  } = userData;


  return <div>
    <>
      <h1 style={{
        "color": "#FFF",
        "textAlign": "left",
        "fontFeatureSettings": "'clig' off, 'liga' off",
        "fontFamily": "\"Source Sans Pro\"",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": "900",
        "lineHeight": "normal"
      }}>
        Set up your Briefly integration for Slack
      </h1>
      <div style={{ display: "flex", flexDirection: 'column', width: '100%', gap: 24 }}>
        <div style={{ display: "flex", flexDirection: 'row', gap: 24, alignItems: 'center' }}>
          <p style={{
            color: 'white',
            flex: 1,
            fontSize: 16,
            fontFamily: 'Roboto',
            fontWeight: '500',
            letterSpacing: 0.15,
            wordWrap: 'break-word'
          }}>Briefly added to Slack
          </p>
          <div style={
            {
              display: 'flex',
              alignItems: 'center',
              gap: 6,
              fontSize: 12,
              fontFamily: 'Roboto', fontWeight: 700,
              padding: '2px 6px',
              borderRadius: 2,
              margin: 0,
              height: 'fit-content',
              backgroundColor: 'var(--surface-surface-secondary, #E44867)',
            }
          }>
            <img src={CheckMark} alt="" />
            {slack_workspace_name}
          </div>
        </div>
        <hr style={{ borderTop: '1px solid #6D6D6D', width: '100%' }} />
        <div>
          <div style={{ display: 'flex' }}>
            <p style={
              {
                color: 'white',
                margin: 0,
                flex: 1,
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }
            }>
              Get AI Summaries to Slack
            </p>
            <label className="switch">
              <input type="checkbox" checked={is_subscribed_to_slack_summaries} onClick={e => {
                const checked = e.target.checked;
                updateUserSettings({ is_subscribed_to_slack_summaries: checked }, () => {
                  forceRenewUserData();

                  if (checked) {
                    trackEvent("summaries-to-slack-enabled", {});
                  } else {
                    trackEvent("summaries-to-slack-disabled", {});
                  }
                });

              }} />
              <span className="slider round"></span>
            </label>
          </div>
          <p style={{
            width: '100%',
            color: '#C6C6C6',
            fontSize: 12,
            margin: 0,
            fontFamily: 'Roboto',
            fontWeight: '400',
            letterSpacing: 0.30,
            wordWrap: 'break-word'
          }}>After each call receive your AI summary directly into Slack
          </p>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <p style={
              {
                color: 'white',
                margin: 0,
                flex: 1,
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }
            }>
              Where would you like to receive this? </p>
            <Radio isChecked={is_summaries_dm} setIsChecked={() => {
              updateUserSettings({
                is_summaries_dm: true
              }, () => {
                forceRenewUserData();
              });
            }} label="DM" />
            <Radio isChecked={!is_summaries_dm} setIsChecked={() => {
              updateUserSettings({
                is_summaries_dm: false
              }, () => {
                forceRenewUserData();
              });
            }} label="Private channel" />

          </div>
        </div>
      </div>
    </>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      {<button onClick={closePopup} style={{
        marginRight: 0,
        "display": "inline-flex",
        "padding": "8px 32px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "borderRadius": "4px",
        "background": "var(--surface-surface-brand, #E44867)"
      }}>
        Confirm and complete setup
      </button>}
      <div style={{}} />
      <button onClick={closePopup} style={{
        marginRight: 0,
        background: 'transparent',
        "color": "var(--color-brand-briefly, #E44867)",
        "fontFeatureSettings": "'clig' off, 'liga' off",
        "fontFamily": "\"Source Sans Pro\"",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "600",
        "lineHeight": "16px",
        "textDecorationLine": "underline"
      }}>
        Quit Setup
      </button>
    </div>
  </div>;
}

const STEPS_CONFIG = [
  {
    component: SlackOnboardingStep1,
    label: 'Preview'
  },
  {
    component: SlackOnboardingStep2,
    label: 'Add to Slack'
  },
  {
    component: SlackOnboardingStep3,
    label: 'Confirm and complete setup'
  },
];

function SlackOnboardingPopup({ onClose }) {

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [_, forceRenewUserData] = useUserData();

  const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false);

  const currentStep = STEPS_CONFIG[currentStepIndex];
  const CurrentStepComponent = currentStep.component;

  useEffect(() => {
    if (currentStepIndex === 4) {
      setupZoom(botShouldJoinAllCalls, () => {
        forceRenewUserData();
      });
    }
  }, [currentStepIndex]);

  return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
    <div className="transcript-info-popup-container">
      <div className="popup-background" onClick={() => onClose()}></div>
      <div className="popup-content" style={{ padding: 20 }}>
        <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls}
          setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose}
          goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)}
          goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)} />
      </div>
    </div>
  </GoogleOAuthProvider>;
};

export default SlackOnboardingPopup;

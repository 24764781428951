export const LANGUAGE_AUTO_DETECT = 'Auto detect'


export const AVAILABLE_LANGUAGES = [
  LANGUAGE_AUTO_DETECT,
  "Afrikaans (South Africa)",
  "Albanian (Albania)",
  "Amharic (Ethiopia)",
  "Arabic (Arab Emirates)",
  "Arabic (Egypt)",
  "Arabic (Levant)",
  "Arabic (Maghrebi)",
  "Armenian (Armenia)",
  "Basque (Spain)",
  "Bengali (Bangladesh)",
  "Bulgarian (Bulgaria)",
  "Burmese (Myanmar)",
  "Catalan (Spain)",
  "Chinese, Mandarin (Simplified)",
  "Chinese, Mandarin (Traditional)",
  "Dutch",
  "English",
  "English (Australia)",
  "English (India)",
  "English (Philippines)",
  "English (UK)",
  "Estonian (Estonia)",
  "Filipino (Philippines)",
  "Finnish (Finland)",
  "French",
  "French (Canada)",
  "Galician (Spain)",
  "Georgian (Georgia)",
  "German",
  "Gujarati (India)",
  "Hebrew (Israel)",
  "Hindi",
  "Hungarian (Hungary)",
  "Indonesian (Indonesia)",
  "Italian",
  "Japanese",
  "Javanese (Indonesia)",
  "Kannada (India)",
  "Khmer (Cambodia)",
  "Kinyarwanda (Rwanda)",
  "Korean",
  "Latvian (Latvia)",
  "Macedonian (North Macedonia)",
  "Malayalam (India)",
  "Marathi (India)",
  "Mongolian (Mongolia)",
  "Nepali (Nepal)",
  "Northern Sotho (South Africa)",
  "Norwegian (Norway)",
  "Persian (Iran)",
  "Polish (Poland)",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Romanian (Romania)",
  "Russian",
  "Sesotho (South Africa)",
  "Sinhala (Sri Lanka)",
  "Slovak (Slovakia)",
  "Slovenian (Slovenia)",
  "Spanish (Mexico)",
  "Spanish (Spain)",
  "Sundanese (Indonesia)",
  "Swahili",
  "Swati (South Africa)",
  "Swedish (Sweden)",
  "Tamil (India)",
  "Telugu (India)",
  "Thai (Thailand)",
  "Tshivenda (South Africa)",
  "Tswana (South Africa)",
  "Turkish (Turkey)",
  "Urdu (Pakistan)",
  "Uzbek (Uzbekistan)",
  "Vietnamese (Vietnam)",
  "Xhosa (South Africa)",
  "Xitsonga (South Africa)",
  "Zulu (South Africa)"
]
import { useState, useEffect } from 'react';

export function useBrieflyStorage({ storageKey, defaultValue, processOnInitialLoad, delay, isBrieflyInstalled }) {
  const [data, setData] = useState(undefined);

  const useLocalStorageKey = 'use-local-storage-' + storageKey;

  async function syncDataFromStorage() {

    const useLocalStorage = !isBrieflyInstalled || localStorage.getItem(useLocalStorageKey) === '1'

    if (useLocalStorage) {
      const storageData = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : defaultValue

      setData(processOnInitialLoad ? processOnInitialLoad(storageData) : storageData)
    } else {
      const promise = new Promise(resolve => {
        const handler = e => {
          if (e.detail.getStorageDataByKey) {
            window.removeEventListener("background-message-response", handler)
            resolve(e.detail.getStorageDataByKey.data)
          }
        }

        window.addEventListener("background-message-response", handler)
      })

      window.dispatchEvent(new CustomEvent('background-message', {
        detail: {
          getStorageDataByKey: {
            key: storageKey,
            defaultValue: defaultValue
          }
        }
      }))

      const storageData = await promise

      setData(processOnInitialLoad ? processOnInitialLoad(storageData) : storageData)
    }

  }

  function syncDataToStorage(newValue) {
    if (typeof newValue == 'function') {
      newValue = newValue(data)
    }

    setData(newValue)

    localStorage.setItem(useLocalStorageKey, '1')
    localStorage.setItem(storageKey, JSON.stringify(newValue))
  }

  useEffect(() => {
    setTimeout(syncDataFromStorage, delay ? 100 : 0)

    return () => {

    };
  }, []);

  useEffect(() => {
    setTimeout(syncDataFromStorage, delay ? 100 : 0)
  }, [storageKey]);

  return [data, syncDataToStorage, data === undefined];
}

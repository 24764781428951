import { useState, useEffect, useCallback } from "react";
import { getUserAccessToken, csrfGet } from "../../utils/genericUtils";
import { BACKEND_URL } from "../../config/config";

export function useDataFromEndpoint(endpointUrl, options = {}) {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { ready, params = {} } = options;

  const fetchData = useCallback(async () => {
    setLoading(true);
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, values]) => {
      [values].flat().forEach((value) => searchParams.append(key, value));
    })

    getUserAccessToken(async ({ access }) => {
      try {
        const response = await csrfGet(`${BACKEND_URL}${endpointUrl}?${searchParams.toString()}`, {
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access,
          },
          referrerPolicy: "no-referrer",
        });

        if (!response.ok) {
          return;
        }

        const data = await response.json();
        setJsonData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    });
  }, [endpointUrl, JSON.stringify(params)]);

  useEffect(() => {
    if (ready !== false) {
      fetchData();
    }
  }, [fetchData, ready]);

  return [jsonData, loading, error, fetchData];
}

export default useDataFromEndpoint;

import React from 'react';
import { SvgSize } from './types';

const X = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white" />
  </svg>
);

export default X;

/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard, generateSummary, getTranscriptionAsText, getUserAccessToken, addBulletListTags, addNewlineTags, addColourToNames, trackEvent, highlightText, checkIfStandardSummaryIsEligible } from '../../utils/genericUtils';
import { ButtonWithTimeoutReplaceOnClick } from '../../utils/ButtonWithTimeoutReplaceOnClick';
import CopyToClipboard from '../CopyToClipboard';
import { CheckmarkCircleIcon, CopyIcon } from '../Icons';
import AIRocket from './AIRocket';
import LanguageBox from './LanguageBox';
import useDataFromEndpoint from '../hooks/useDataFromEndpoint';
import LiveChecklistSummaries from '../LiveChecklistSummaries';

export const SummarySection = ({ custom_bullet_summary_label, header, keyIdentifier, text, setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup, isBackend, getSpeakerColourByName, searchWords }) => {
  const { t } = useTranslation();
  const isError = text?.error;

  let formattedText;

  if (keyIdentifier === 'short_summary') {
    formattedText = text;
  }

  if (keyIdentifier === 'long_summary') {
    if (custom_bullet_summary_label?.trim()?.length) {
      keyIdentifier = custom_bullet_summary_label;
    }
    formattedText = addBulletListTags(text);
  }

  if (keyIdentifier === 'key_insights') {
    formattedText = addNewlineTags(addBulletListTags(text));
  }

  if (keyIdentifier === 'action_items') {
    formattedText = addColourToNames(addBulletListTags(text), getSpeakerColourByName);
  }

  if (keyIdentifier === 'live_checklist') {
    formattedText = addNewlineTags(addBulletListTags(text, ''));
  }

  return <div className="summary-section">
    <div>
      <h4>{header}</h4>
      {text && !isError ? <CopyToClipboard text={text} /> : null}

    </div >
    {keyIdentifier === 'action_items' && isBackend ? <div className="black">
      {t('meeting.ia-summary.action-items.not-good.question', 'Action items not looking good? Make sure you upload the transcript with participants names.')} <a onClick={() => {

        trackEvent("briefly-how-to-get-zoom-transcripts-click", {
          source: 'SUMMARY_TAB'
        });

        setShowZoomTranscriptInfoPopup(true);

      }}>{t('meeting.ia-summary.action-items.not-good.zoom', 'How to get transcripts for Zoom')}</a>, <a onClick={() => {

        trackEvent("briefly-how-to-get-ms-teams-transcripts-click", {
          source: 'SUMMARY_TAB'
        });

        setShowMsTeamsTranscriptInfoPopup(true);
      }

      }>{t('meeting.ia-summary.action-items.not-good.ms-teams', 'How to get transcripts for MS Teams')}</a>
    </div> : ''}
    <p>
      {isError ?
        t('meeting.ia-summary.gen-failed.message', 'OpenAI error. Please try again later...') :
        searchWords
          ? highlightText(text, searchWords, { background: '#F9C338', color: 'black' }) :
          text ?
            <span dangerouslySetInnerHTML={{ __html: formattedText }}></span> :
            t('meeting.ia-summary.action-items.no-text', 'Please ensure that you have uploaded the transcript in the correct format as action items are displayed for each meeting participant.')}
    </p>
  </div>;
};

const getTranslatedColumns = (t) => [
  [
    {
      label: `🤖 ${t('meeting.ia-summary.call-summary.title', 'Call summary')}`,
      key: 'short_summary'
    },
    {
      label: `💡 ${t('meeting.ia-summary.key-insights.title', 'Key insights')}`,
      key: 'key_insights'
    }
  ],
  [
    {
      label: `📍 ${t('meeting.ia-summary.bullet-summary.title', 'Bullet summary')}`,
      key: 'long_summary'
    }
  ],
  [
    {
      label: `🎯 ${t('meeting.ia-summary.action-items.title', 'Action items')}`,
      key: 'action_items'
    }
  ]
];

export default function MeetingTabSummary({ isBackend, userData, aiSummaryCostsAiCredit, meetingSummaryWasRequestedBefore, meetingData, backendMeetingData, setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup, setShowOutOfCreditsPopup, searchWords, loadBackendMeetingData }) {
  const { t } = useTranslation();

  const { meetingId, noteBlocks: unfilteredNoteBlocks } = meetingData;


  const [workflowRuns] = useDataFromEndpoint(`/api/workflow_runs`, {
    params: {
      transcriptId: meetingData.transcriptId,
      taskSet: 'live_checklist',
      includeFieldContext: true,
    },
    ready: meetingData.transcriptId != null,
  });

  const noteBlocks = useMemo(() => {
    if (unfilteredNoteBlocks == null) {
      return [];
    }
    const noteBlocks = unfilteredNoteBlocks.filter((note) => !note.match(/^[\s•]*$/));
    // Remove No Notes text that comes from backend so that we get the translated text.
    if (noteBlocks.length === 1 && noteBlocks[0].match((/^[\s• No notes]*$/))) {
      noteBlocks.pop();
    }

    if (noteBlocks.length === 0) {
      noteBlocks.push(t('meeting.ia-summary.note-block.no-notes', 'No Notes'));
    }

    return noteBlocks.map((note) => note.replace(" •", '').trim());
  }, [unfilteredNoteBlocks]);

  const { custom_bullet_summary_label } = userData;

  const [meetingSummary, setMeetingSummary] = useState(null);

  const [newLanguageSummaryIsLoading, setNewLanguageSummaryIsLoading] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false);

  const LOCAL_STORAGE_SUMMARY_KEY = 'summary-cache-' + meetingId;

  const MAX_STORED_SUMMARIES = 1000;


  // Clearing latest local storage entries if is it going to be overloaded soon
  const isStorageOverloaded = () => {
    let count = 0;
    for (const key in localStorage) {
      if (key.includes('summary-cache-')) {
        count++;
      }
    }
    return count >= MAX_STORED_SUMMARIES;
  };

  const removeOldestSummary = () => {
    let oldestTimestamp = null;
    let oldestEntryKey = null;
    for (const key in localStorage) {
      if (key.includes('summary-cache-')) {
        const { timestamp } = JSON.parse(localStorage.getItem(key));
        if (!timestamp) {
          oldestEntryKey = key;
          break;
        }
        if (!oldestTimestamp || timestamp.localeCompare(oldestTimestamp) < 0) {
          oldestTimestamp = timestamp;
          oldestEntryKey = key;
        }
      }
    }
    localStorage.removeItem(oldestEntryKey);
  };

  const setMeetingSummaryInCache = (summaryData) => {
    localStorage.setItem(LOCAL_STORAGE_SUMMARY_KEY, JSON.stringify({
      ...summaryData,
      timestamp: new Date()
    }));
  };

  useEffect(() => {
    if (backendMeetingData.long_summary) {
      setMeetingSummaryInCache({
        short_summary: backendMeetingData.short_summary,
        long_summary: backendMeetingData.long_summary,
        key_insights: backendMeetingData.key_insights,
        action_items: backendMeetingData.action_items
      });

      setMeetingSummary({
        ...backendMeetingData
      });
    } else {
      setMeetingSummary(null);
    }
  }, [backendMeetingData]);

  function handleGenerateSummary(language = null) {

    checkIfStandardSummaryIsEligible(getTranscriptionAsText(meetingData), isEligible => {
      if (isEligible) {
        setIsGenerating(true);

        getUserAccessToken(({ access }) => {


          trackEvent("briefly-create-generate-ai-summary", {
            meetingSource: meetingData.isBackend ? 'UPLOAD' : 'BRIEFLY_EXTENSION'
          });

          generateSummary(
            getTranscriptionAsText(meetingData), meetingData.transcriptId, language, access, summaryData => {
              setIsGenerating(false);
              setNewLanguageSummaryIsLoading(false);

              summaryData.selectedLanguage = language;

              setMeetingSummary(summaryData);

              if (isStorageOverloaded()) {
                removeOldestSummary();
              }

              setMeetingSummaryInCache(summaryData);
            }
          );


        });
      } else {
        setShowOutOfCreditsPopup(true);
      }
    });
  }

  let body;
  if (!meetingSummary || isGenerating) {
    body = (
      <div className="generate-ai-summary" style={{ display: 'flex', justifyContent: 'center', marginTop: 60, flex: 1 }}>
        <div style={{ width: 400, textAlign: 'center' }}>
          <AIRocket />
          <h5>{isGenerating ? t('meeting.ia-summary.generate.is-generating', 'Generating AI summary...') : t('meeting.ia-summary.generate.ready-to-generate', 'Your AI Summary is ready to be generated!')} </h5>

          {
            !isGenerating &&
            <button style={{ height: 40, width: 260 }} onClick={() => handleGenerateSummary()}>
              {meetingSummaryWasRequestedBefore ?
                t('meeting.ia-summary.generate.view-summary', 'View AI summary') :
                t('meeting.ia-summary.generate.generate-summary', 'Generate AI summary')}
              {' '}
              {aiSummaryCostsAiCredit ?
                t('meeting.ia-summary.generate.free-cost', '[1 AI Credit]') :
                ''}
            </button>
          }

          {
            isGenerating && <div className="loader-container">
              <div className="loader-inner" />
            </div>
          }
        </div>

      </div>
    );
  } else {
    const SUMMARY_COLUMNS_CONFIG = getTranslatedColumns(t);
    const anyError = meetingSummary && SUMMARY_COLUMNS_CONFIG.find(items => items.find(x => meetingSummary?.[x.key]?.error));

    body = (
      <div id="summary-outer-container">
        <div id="summary-container" className={anyError && 'is-error'}>
          {
            meetingSummary && <div>
              {anyError && <div className="summary-error">
                {t('meeting.ia-summary.generate.error.title', 'There was an issue with OpenAI while generating some AI summaries, try again later')}
                <br />
                <a onClick={() => handleGenerateSummary()}>{t('meeting.ia-summary.generate.error.retry', 'Try again')}</a>
              </div>}

              <ButtonWithTimeoutReplaceOnClick
                initialText={
                  <>
                    <CopyIcon />
                    {t('meeting.ia-summary.generate.copy-clipboard', 'Copy entire AI summary')}
                  </>
                }

                clickedText={
                  <>
                    <CheckmarkCircleIcon size="14" />
                    {t('meeting.ia-summary.generate.copied', 'Copied to clipboard')}
                  </>
                }

                onClick={() => {
                  let concat = '';
                  SUMMARY_COLUMNS_CONFIG.map(items => items.map(({ label, key }) => {
                    concat += label + ':\n';
                    concat += meetingSummary[key] + '\n\n';
                  }));
                  copyToClipboard(concat);
                }}

              />
              <div className="summary-columns">
                {
                  SUMMARY_COLUMNS_CONFIG.map((items) => <div key={items.map((i) => i.key).join('||')} className="summary-column">
                    {items.map(({ label, key }) => {
                      return <SummarySection
                        key={key}
                        custom_bullet_summary_label={custom_bullet_summary_label}
                        keyIdentifier={key}
                        header={label}
                        text={meetingSummary[key]}
                        setShowZoomTranscriptInfoPopup={setShowZoomTranscriptInfoPopup}
                        setShowMsTeamsTranscriptInfoPopup={setShowMsTeamsTranscriptInfoPopup}
                        isBackend={isBackend}
                        getSpeakerColourByName={isBackend ? null : speakerName => {
                          let speakerId = null;
                          for (const id in meetingData.deviceNamesById) {
                            if (meetingData.deviceNamesById[id] === speakerName) {
                              speakerId = id;
                              break;
                            }
                          }

                          if (speakerId) {
                            return meetingData.deviceColorsById[speakerId];
                          } else {
                            return 'white';
                          }
                        }}
                        searchWords={searchWords}
                      />;

                    })}
                  </div>)}
              </div>
              {workflowRuns?.map((workflowRun) => (
                <>
                  <hr style={{ background: '#434343', marginTop: 24, marginBottom: 24 }} />
                  <LiveChecklistSummaries key={workflowRun.id} workflowRun={workflowRun} />
                </>
              ))}
              <hr style={{ background: '#434343', marginTop: 24, marginBottom: 24 }} />
              <div className="summary-section">
                <div>
                  <h4>📝 {t('meeting.ia-summary.note-block.title', 'Notes')}</h4>
                  <CopyToClipboard text={noteBlocks?.join(", ")} />
                </div>
                <div className="box">
                  <ul>
                    {noteBlocks.map((block, i) => <li key={i}>{block}</li>)}
                  </ul>
                </div>
              </div>
            </div >
          }
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
      <LanguageBox
        backendMeetingData={backendMeetingData}
        handleGenerateSummary={handleGenerateSummary}
        isGenerating={isGenerating}
        loadBackendMeetingData={loadBackendMeetingData}
        meetingData={meetingData}
        newLanguageSummaryIsLoading={newLanguageSummaryIsLoading}
        setNewLanguageSummaryIsLoading={setNewLanguageSummaryIsLoading}
        userData={userData}
      />
      {body}
    </div>
  );
}

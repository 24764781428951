import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSlackDeepLink, resendKeyInsightsSlack, resendSummaryEmail, openCheckoutSessionInNewTab } from '../../utils/genericUtils';
import { AVAILABLE_LANGUAGES, LANGUAGE_AUTO_DETECT } from '../../utils/const';
import { Dropdown } from '../controls/Dropdown';
import { HoverDropdownMenu } from '../controls/HoverDropdownMenu';
import { CheckmarkCircleIcon, EmailIcon, PlugIcon, RedoArrowIcon, SettingsIcon, SlackLogo, XCircleIcon, WorldIcon } from '../Icons';
import Alert from '../Reusable/Alert';
import ProBadge from "../Reusable/ProBadge";

import { BackendMeetingData, MeetingData, UserData } from './Types';


const greenSuccessCheckBoxOrGrayCross = (isSuccess, successText, notSuccessText) => (
  <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
    {isSuccess ?
      <CheckmarkCircleIcon style={{ color: "#80BF28" }} size="12" />
      :
      <XCircleIcon style={{ color: "#6D6D6D" }} />}
    <span style={{ marginLeft: 10, "color": isSuccess ? "#C6C6C6" : '#6D6D6D', "fontSize": "14px", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
      {isSuccess ? successText : notSuccessText}
    </span>
  </div>
);

interface Props {
  backendMeetingData: BackendMeetingData,
  handleGenerateSummary: (language?: null) => void;
  isGenerating: boolean,
  loadBackendMeetingData: (boolean) => void,
  meetingData: MeetingData,
  newLanguageSummaryIsLoading: boolean,
  setNewLanguageSummaryIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  userData: UserData;
}

const LanguageBox = ({
  backendMeetingData,
  handleGenerateSummary,
  isGenerating,
  loadBackendMeetingData,
  meetingData,
  newLanguageSummaryIsLoading,
  setNewLanguageSummaryIsLoading,
  userData,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const autoDetectLabel = t('meeting.ia-summary.summary-language.auto-detect', LANGUAGE_AUTO_DETECT);
  const [selectedLanguage, setSelectedLanguage] = useState(autoDetectLabel);
  const [selectedUnconfirmedLanguage, setSelectedUnconfirmedLanguage] = useState(null);
  const [langDropdownIsOpen, setLangDropdownIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const { isBackend } = meetingData;
  const {
    email_summary_sent_to_myself, email_summary_sent_to_everyone,
    slack_summary_sent_to_team_id, slack_summary_sent_to_channel_id,
    meeting_participants_emails,
  } = backendMeetingData;
  const { admin_user, admin_user_subscription_is_active, features, slack_workspace_name, subscription_is_active } = userData;

  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active));
  const slackIsConnected = !!slack_workspace_name;

  const onLanguageChange = lang => () => {
    if (lang !== selectedLanguage) {
      setSelectedUnconfirmedLanguage(lang);
    }
  };

  const LANGUAGE_DROPDOWN_ITEMS = AVAILABLE_LANGUAGES.map(lang => {
    const label = lang === LANGUAGE_AUTO_DETECT ? autoDetectLabel : lang;

    return {
      label,
      onSelect: userIsPaid ? onLanguageChange(label) : () => { }
    };
  });

  const handleSendEmailEveryone = () => {
    resendSummaryEmail({ ...meetingData, ...backendMeetingData }, { sendToEveryone: true });
    setTimeout(() => {
      loadBackendMeetingData(false);
    }, 4000);
  };

  return (
    <div style={{
      overflowY: 'auto',
      maxHeight: '100%',
      width: 302,
      minWidth: 302
    }}>
      <Alert
        open={alertIsOpen}
        onOpenChange={setAlertIsOpen}
        onConfirm={handleSendEmailEveryone}
        cancelContent={t('meeting.ia-summary.send-email.confirmation.cancel', 'Cancel')}
        confirmContent={t('meeting.ia-summary.send-email.confirmation.confirm', 'Send')}
        description={t('meeting.ia-summary.send-email.confirmation.description', 'This will send an email to everyone on the invite.')}
        title={t('meeting.ia-summary.send-email.confirmation.title', 'Are you sure?')}
      />
      <div style={{ width: 252, minWidth: 252, padding: 24, background: '#212121', borderRadius: 4, marginLeft: 10, marginTop: 12, marginBottom: 10 }} className="summary-left-sidebar">
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WorldIcon />
            <h5 style={{ marginTop: 0, marginLeft: 10, marginBottom: 0 }}>
              {t('meeting.ia-summary.summary-language.title', 'Language')}
              {userIsPaid ? '' : <ProBadge />}
            </h5>

          </div>
          <div>
            <p style={{ marginTop: 15, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
              {t('meeting.ia-summary.summary-language.description', 'Change the AI summary for this call.')}
            </p>
            <div style={{ display: 'flex' }}>
              <Dropdown
                disabled={isGenerating || !userIsPaid}
                isOpen={langDropdownIsOpen}
                setIsOpen={setLangDropdownIsOpen}
                items={LANGUAGE_DROPDOWN_ITEMS}
                selectedItem={LANGUAGE_DROPDOWN_ITEMS.find(x => x.label === (selectedUnconfirmedLanguage ?? selectedLanguage))}
                style={selectedUnconfirmedLanguage ? { maxWidth: 170 } : { flex: 1 }}
                isLoading={newLanguageSummaryIsLoading}
              />
              {selectedUnconfirmedLanguage && <button onClick={() => {
                setNewLanguageSummaryIsLoading(true);
                setSelectedUnconfirmedLanguage(null);
                setSelectedLanguage(selectedUnconfirmedLanguage);
                handleGenerateSummary(selectedUnconfirmedLanguage);
              }} style={{
                margin: 0,
                marginLeft: 10,
                height: 40
              }}>
                {t('meeting.ia-summary.summary-language.change', 'Change')
                }
              </button >}
            </div >
          </div >
        </div >

        {isBackend && <>
          <hr style={{ background: '#434343', marginTop: 24, marginBottom: 24 }} />

          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon />
                <h5 style={{ marginTop: 0, marginLeft: 10, marginBottom: 0 }}>
                  {t('meeting.ia-summary.email.title', 'Email')}
                </h5>
              </div>
              <HoverDropdownMenu
                menuItems={[
                  {
                    label: `${email_summary_sent_to_myself ? t('meeting.ia-summary.email.hover-options.myself.resend-label', 'Re-send to myself') : t('meeting.ia-summary.email.hover-options.myself.send-label', 'Send to myself')}`,
                    labelOnClick: t('meeting.ia-summary.email.hover-options.myself.clicked', 'Sent to myself'),
                    onClick: () => {
                      resendSummaryEmail({ ...meetingData, ...backendMeetingData }, { sendToEveryone: false });
                      setTimeout(() => {
                        loadBackendMeetingData(false);
                      }, 4000);
                    },
                    icon: email_summary_sent_to_myself ? <RedoArrowIcon /> : <EmailIcon size="16" />
                  },
                  {
                    hidden: !features?.email_attendees,
                    label: `${email_summary_sent_to_myself ? t('meeting.ia-summary.email.hover-options.everyone.resend-label', 'Re-send to everyone') : t('meeting.ia-summary.email.hover-options.everyone.send-label', 'Send to everyone')}`,
                    labelOnClick: t('meeting.ia-summary.email.hover-options.everyone.clicked', 'Sent to everyone'),
                    disabled: meeting_participants_emails?.length == 0,
                    onClick: () => setAlertIsOpen(true),

                    icon: email_summary_sent_to_everyone ? <RedoArrowIcon /> : <EmailIcon size="16" />
                  },
                  {
                    label: t('meeting.ia-summary.email.hover-options.settings', 'Email settings'),
                    onClick: () => navigate('/settings/ai-summary-email'),
                    icon: <SettingsIcon />
                  }
                ]}
              />
            </div>
            <div>
              {greenSuccessCheckBoxOrGrayCross(email_summary_sent_to_myself,
                t("meeting.ia-summary.email.checklist.myself.done", "AI summary sent to myself"),
                t("meeting.ia-summary.email.checklist.myself.not-done", "AI summary not sent to myself")
              )}
              {greenSuccessCheckBoxOrGrayCross(email_summary_sent_to_everyone,
                t("meeting.ia-summary.email.checklist.everyone.done", "AI summary sent to everyone"),
                t("meeting.ia-summary.email.checklist.everyone.not-done", "AI summary not sent to everyone")
              )}
            </div>
          </div>

          {features?.integrations && (<>
            <hr style={{ background: '#434343', marginTop: 24, marginBottom: 24 }} />

            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SlackLogo />

                  <h5 style={{ marginTop: 0, marginLeft: 10, marginBottom: 0 }}>Slack
                    {userIsPaid ? '' : <ProBadge />}
                  </h5>
                </div>
                <HoverDropdownMenu
                  menuItems={slackIsConnected ? [
                    {
                      label: `${slack_summary_sent_to_team_id || slack_summary_sent_to_channel_id ? t('meeting.ia-summary.slack.hover-options.resend.label', 'Re-send to Slack') : t('meeting.ia-summary.slack.hover-options.send.label', 'Send to Slack')}`,
                      labelOnClick: t('meeting.ia-summary.slack.hover-options.resend.clicked', 'Sent to Slack'),
                      hidden: !userIsPaid,
                      onClick: () => {
                        resendKeyInsightsSlack({ ...meetingData, ...backendMeetingData });
                        setTimeout(() => {
                          loadBackendMeetingData(false);
                        }, 2000);
                      },
                      icon: slack_summary_sent_to_team_id || slack_summary_sent_to_channel_id ? <RedoArrowIcon /> : <EmailIcon size="16" />
                    },
                    {
                      label: t('meeting.ia-summary.slack.hover-options.settings', 'Manage Slack settings'),
                      onClick: () => {
                        navigate('/settings/integrations/slack');
                      },
                      icon: <PlugIcon />
                    }

                  ] : [
                    {
                      label: t('meeting.ia-summary.slack.hover-options.connect', 'Connect Slack'),
                      onClick: () => {
                        navigate('/settings/integrations');
                      },
                      icon: <PlugIcon />
                    }
                  ]
                  }
                />
              </div>
              <div>
                {greenSuccessCheckBoxOrGrayCross(!!slack_summary_sent_to_team_id,
                  <>
                    <span>{t('meeting.ia-summary.slack.checklist.done.pt1', 'AI summary sent to')}</span>{' '}
                    <a href={getSlackDeepLink(slack_summary_sent_to_team_id, slack_summary_sent_to_channel_id)} target="_blank" style={{ "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px", textDecoration: 'underline', cursor: 'pointer' }} rel="noreferrer">
                      {t('meeting.ia-summary.slack.checklist.done.pt2', 'Slack channel')}
                    </a>
                  </>,
                  t("meeting.ia-summary.slack.checklist.not-done", "AI summary not sent to Slack")
                )}
              </div>
            </div>
          </>
          )}
        </>}

        {
          !userIsPaid && <>
            <hr style={{ background: '#434343', marginTop: 24, marginBottom: 24 }} />

            <button style={{ fontSize: 14, height: 32, margin: 0 }} onClick={openCheckoutSessionInNewTab}>
              {t('meeting.ia-summary.upgrade', 'Upgrade to PRO now')}
            </button>
          </>
        }

      </div >
    </div>
  );
};

export default LanguageBox;
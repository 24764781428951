import { useState } from 'react'

export const HoverDropdownMenu = ({icon, menuItems: allMenuItems}) => {
  const menuItems = allMenuItems.filter(({ hidden }) => !hidden)

  const [itemClicked, setItemClicked] = useState(null)

  if(!icon) {
    icon = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0514 10.5682C14.7477 10.8723 14.3972 11.0244 14 11.0244C13.6028 11.0244 13.2523 10.8723 12.9486 10.5682C12.6449 10.2641 12.493 9.91327 12.493 9.51561C12.493 9.11795 12.6449 8.76707 12.9486 8.46298C13.2523 8.15888 13.6028 8.00684 14 8.00684C14.3972 8.00684 14.7477 8.15888 15.0514 8.46298C15.3552 8.76707 15.507 9.11795 15.507 9.51561C15.507 9.91327 15.3552 10.2641 15.0514 10.5682ZM15.0514 15.0595C14.7477 15.3636 14.3972 15.5156 14 15.5156C13.6028 15.5156 13.2523 15.3636 12.9486 15.0595C12.6449 14.7554 12.493 14.4045 12.493 14.0068C12.493 13.6092 12.6449 13.2583 12.9486 12.9542C13.2523 12.6501 13.6028 12.4981 14 12.4981C14.3972 12.4981 14.7477 12.6501 15.0514 12.9542C15.3552 13.2583 15.507 13.6092 15.507 14.0068C15.507 14.4045 15.3552 14.7554 15.0514 15.0595ZM12.9486 17.4454C13.2523 17.1413 13.6028 16.9893 14 16.9893C14.3972 16.9893 14.7477 17.1413 15.0514 17.4454C15.3552 17.7495 15.507 18.1004 15.507 18.4981C15.507 18.8957 15.3551 19.2466 15.0514 19.5507C14.7477 19.8548 14.3972 20.0068 14 20.0068C13.6028 20.0068 13.2523 19.8548 12.9486 19.5507C12.6449 19.2466 12.493 18.8957 12.493 18.4981C12.493 18.1004 12.6449 17.7495 12.9486 17.4454Z" fill="white"/>
    </svg>
  }

  return (
    <div className="user-dropdown" style={{ marginRight: 0, padding: 0 }}>
        <div className="label" style={{ marginRight: 0 }}>
          {icon}
        </div>
        <div className="user-dropdown-content">
          {menuItems.map((item) => {
            const { label, onClick, labelOnClick } = item
            return (
              <div
                onClick={() => {
                  onClick()
                  setItemClicked(item)
                  setTimeout(() => setItemClicked(null), 500)
                }} 
                // Might be ideal to define on TS which item field should be considered as an unique key
                key={item.key || item.label}
                className="clickable"
                style={item.disabled ? { pointerEvents: 'none', opacity: 0.7 } : undefined}
              >
                  {item.icon}
                  {itemClicked === item && labelOnClick ? labelOnClick : label}
              </div>
            )
          })}
        </div>
    </div>
  )
}

export default HoverDropdownMenu
function LogoWithNameSvg() {
    return (
        <svg width="170" height="51" viewBox="0 0 170 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_596_13210" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="170"
                  height="51">
                <rect x="0.420532" y="0.461914" width="169.159" height="50" fill="url(#paint0_linear_596_13210)"/>
            </mask>
            <g mask="url(#mask0_596_13210)">
                <path
                    d="M56.248 42.9963V18.0898H65.2143C66.9514 18.0898 68.5352 18.2686 69.9657 18.6262C71.3963 18.9839 72.533 19.6097 73.376 20.5038C74.219 21.3979 74.6405 22.6624 74.6405 24.2973C74.6405 25.3702 74.3595 26.4431 73.7975 27.516C73.2355 28.5633 72.4691 29.2786 71.4984 29.6617V29.815C72.6991 30.1471 73.7081 30.7857 74.5255 31.7309C75.3685 32.6761 75.79 33.9789 75.79 35.6393C75.79 37.3508 75.343 38.7558 74.4489 39.8543C73.5548 40.9272 72.367 41.7191 70.8853 42.23C69.4037 42.7409 67.7688 42.9963 65.9807 42.9963H56.248ZM62.8386 27.8225H65.061C66.1339 27.8225 66.9258 27.5926 67.4367 27.1328C67.9477 26.673 68.2031 26.0599 68.2031 25.2935C68.2031 24.5272 67.9477 23.9779 67.4367 23.6459C66.9258 23.3138 66.1339 23.1477 65.061 23.1477H62.8386V27.8225ZM62.8386 37.9384H65.5209C68.0754 37.9384 69.3526 37.0188 69.3526 35.1795C69.3526 34.2599 69.0333 33.6085 68.3947 33.2253C67.7816 32.8421 66.8237 32.6505 65.5209 32.6505H62.8386V37.9384Z"
                    fill="white"/>
                <path
                    d="M79.1737 42.9963V23.8374H84.5381L84.998 27.1328H85.1512C85.8665 25.83 86.7095 24.8848 87.6802 24.2973C88.6765 23.6842 89.6472 23.3776 90.5924 23.3776C91.1799 23.3776 91.6525 23.416 92.0101 23.4926C92.3933 23.5437 92.7126 23.6331 92.968 23.7608L91.8952 29.4318C91.5631 29.3552 91.231 29.2913 90.8989 29.2403C90.5668 29.1636 90.1836 29.1253 89.7494 29.1253C89.0596 29.1253 88.3316 29.368 87.5652 29.8533C86.8244 30.3131 86.2241 31.1434 85.7643 32.344V42.9963H79.1737Z"
                    fill="white"/>
                <path
                    d="M95.1893 42.9963V23.8374H101.78V42.9963H95.1893ZM98.4846 21.4617C97.4117 21.4617 96.5432 21.168 95.879 20.5804C95.2148 19.9674 94.8827 19.1627 94.8827 18.1664C94.8827 17.1702 95.2148 16.3783 95.879 15.7907C96.5432 15.1776 97.4117 14.8711 98.4846 14.8711C99.5575 14.8711 100.426 15.1776 101.09 15.7907C101.754 16.3783 102.086 17.1702 102.086 18.1664C102.086 19.1627 101.754 19.9674 101.09 20.5804C100.426 21.168 99.5575 21.4617 98.4846 21.4617Z"
                    fill="white"/>
                <path
                    d="M115.118 43.4561C113.253 43.4561 111.567 43.0602 110.06 42.2683C108.578 41.4764 107.403 40.3396 106.535 38.858C105.666 37.3508 105.232 35.5371 105.232 33.4169C105.232 31.3477 105.666 29.5723 106.535 28.0907C107.429 26.5836 108.578 25.4212 109.983 24.6038C111.388 23.7864 112.87 23.3776 114.428 23.3776C116.318 23.3776 117.877 23.7991 119.103 24.6421C120.355 25.4596 121.274 26.5708 121.862 27.9758C122.475 29.3808 122.781 30.939 122.781 32.6505C122.781 33.2125 122.743 33.7617 122.666 34.2982C122.615 34.8091 122.564 35.1795 122.513 35.4094H111.593C111.899 36.5589 112.448 37.3636 113.24 37.8234C114.032 38.2577 114.965 38.4748 116.037 38.4748C117.264 38.4748 118.566 38.0917 119.946 37.3253L122.092 41.2337C121.07 41.949 119.92 42.4982 118.643 42.8814C117.366 43.2646 116.191 43.4561 115.118 43.4561ZM111.516 31.1178H117.187C117.187 30.377 116.995 29.7384 116.612 29.2019C116.255 28.6399 115.578 28.3589 114.581 28.3589C113.866 28.3589 113.227 28.5761 112.665 29.0103C112.103 29.4446 111.72 30.1471 111.516 31.1178Z"
                    fill="white"/>
                <path
                    d="M127.322 42.9963V28.972H124.793V24.0674L127.322 23.8758V23.6075C127.322 22.2281 127.552 20.9508 128.012 19.7758C128.497 18.6007 129.289 17.6555 130.388 16.9403C131.512 16.225 133.019 15.8674 134.909 15.8674C135.803 15.8674 136.595 15.944 137.285 16.0973C138 16.2505 138.562 16.4038 138.971 16.5571L137.821 21.3851C137.132 21.1297 136.493 21.0019 135.905 21.0019C135.292 21.0019 134.807 21.1808 134.449 21.5384C134.092 21.8705 133.913 22.4836 133.913 23.3776V23.8374H137.132V28.972H133.913V42.9963H127.322Z"
                    fill="white"/>
                <path
                    d="M145.882 43.4561C144.4 43.4561 143.225 43.1624 142.356 42.5748C141.513 41.9617 140.9 41.1188 140.517 40.0459C140.159 38.9474 139.981 37.6829 139.981 36.2524V16.3272H146.571V36.4823C146.571 37.1209 146.686 37.568 146.916 37.8234C147.172 38.0533 147.414 38.1683 147.644 38.1683C147.772 38.1683 147.874 38.1683 147.951 38.1683C148.053 38.1427 148.181 38.1172 148.334 38.0917L149.1 42.9197C148.768 43.073 148.334 43.2007 147.797 43.3029C147.261 43.405 146.622 43.4561 145.882 43.4561Z"
                    fill="white"/>
                <path
                    d="M154.229 50.2001C153.591 50.2001 153.054 50.1617 152.62 50.0851C152.186 50.0085 151.751 49.9191 151.317 49.8169L152.467 44.8356C152.645 44.8611 152.85 44.8994 153.08 44.9505C153.31 45.0272 153.514 45.0655 153.693 45.0655C154.587 45.0655 155.264 44.8867 155.724 44.529C156.183 44.1714 156.503 43.7116 156.681 43.1496L156.911 42.3066L149.631 23.8374H156.222L158.521 31.1945C158.802 32.063 159.044 32.9443 159.249 33.8384C159.453 34.7325 159.67 35.6649 159.9 36.6356H160.053C160.258 35.716 160.449 34.8091 160.628 33.915C160.833 33.0209 161.05 32.1141 161.28 31.1945L163.196 23.8374H169.48L163.042 42.6898C162.378 44.4013 161.663 45.8063 160.896 46.9047C160.156 48.0287 159.249 48.8589 158.176 49.3954C157.129 49.9318 155.813 50.2001 154.229 50.2001Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M46.8352 11.9068C47.2776 11.6408 47.8518 11.7838 48.1178 12.2261L48.5785 12.9924C52.3019 19.1848 48.6437 27.1849 41.5242 28.4192L40.9681 28.5156L41.4366 28.9316C47.713 34.5047 44.0312 44.8844 35.6458 45.2567L32.7795 45.3839L1.58831 46.5384C1.07251 46.5575 0.638891 46.1548 0.6198 45.639C0.60071 45.1232 1.00337 44.6896 1.51917 44.6705L32.7 43.5165L32.7069 43.5162L35.5629 43.3894C42.2712 43.0915 45.2167 34.7878 40.1956 30.3293L36.8253 27.3368L41.205 26.5775C47.0299 25.5677 50.0231 19.0221 46.9767 13.9556L46.5159 13.1893C46.2499 12.7469 46.3929 12.1727 46.8352 11.9068Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.51899 33.8844C9.60411 26.696 12.2042 19.6964 16.1972 14.2942L17.3329 26.4314L17.2555 26.4386L17.334 26.4432L17.3414 26.5216L17.3459 26.4439L29.9582 27.1813C24.5475 31.5582 17.7229 34.2681 10.2547 34.5131C8.57288 38.0351 5.84472 40.9725 2.422 42.9094L2.18335 43.0444C3.60362 39.1295 6.04477 36.1421 9.51899 33.8844ZM17.3467 26.4301L18.1998 11.8387C19.4627 10.4325 20.8446 9.13465 22.33 7.96115L23.7284 19.8043L23.6533 19.8132L23.7302 19.8196L23.7392 19.8962L23.7454 19.8208L35.9771 20.8337C34.8936 22.3291 33.6879 23.7308 32.3743 25.0239L17.3467 26.4301ZM23.747 19.8021L24.8786 6.13635C26.0381 5.38647 27.2477 4.70699 28.5016 4.10408L30.0706 13.228L30.0011 13.2399L30.0744 13.2499L30.0869 13.3229L30.0965 13.2529L39.5589 14.542C39.0216 15.7861 38.41 16.9911 37.7297 18.1512L23.747 19.8021ZM30.1006 13.2228L31.5157 2.83517C34.8708 1.61544 38.482 0.921999 42.2514 0.856204C42.3155 4.52963 41.7786 8.07372 40.7314 11.3946L30.1006 13.2228Z"
                      fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_596_13210" x1="0.420532" y1="25.4619" x2="169.579" y2="25.4619"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BF00B8"/>
                    <stop offset="1" stopColor="#FD792F"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default LogoWithNameSvg
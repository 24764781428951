/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { SettingsTabAISummaryEmail } from './settingsTabs/SettingsTabAISummaryEmail';
import { SettingsTabIntegrations } from './settingsTabs/SettingsTabIntegrations';
import { SettingsTabSubscriptions } from './settingsTabs/SettingsTabSubscriptions';
import { SettingsTabLanguage } from './settingsTabs/SettingsTabLanguage';
import SettingsManageAdminOrganization from './settingsTabs/ManageAdminOrganization';
import { GoogleOAuthProvider } from '@react-oauth/google';
import BuildingIcon from './Icons/Building';
import WorldIcon from './Icons/World';
import useUserData from './hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { I18N_SUPPORT } from '../config/config';

const loadTabsWithTranslation = (t) => [
  {
    icon: <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9225 3.01754V1.50877L8.5 5.26316L2.07746 1.50877V3.01754L8.5 6.73684L14.9225 3.01754ZM14.9225 0C15.3482 0 15.7113 0.152047 16.0117 0.45614C16.3372 0.760234 16.5 1.11111 16.5 1.50877V10.4912C16.5 10.8889 16.3372 11.2398 16.0117 11.5439C15.7113 11.848 15.3482 12 14.9225 12H2.07746C1.6518 12 1.27621 11.848 0.950704 11.5439C0.650235 11.2398 0.5 10.8889 0.5 10.4912V1.50877C0.5 1.11111 0.650235 0.760234 0.950704 0.45614C1.27621 0.152047 1.6518 0 2.07746 0H14.9225Z" fill="white" />
    </svg>,
    label: t('settings.ai-summary.tab-label', 'AI summary email'),
    component: SettingsTabAISummaryEmail,
    path: 'ai-summary-email'
  },
  {
    icon: <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.03755 12.1822C7.70966 11.9732 7.29034 11.9732 6.96245 12.1822L4.57922 13.7015C3.82781 14.1805 2.87242 13.5116 3.06541 12.6417L3.73172 9.63798C3.80912 9.28906 3.69441 8.92535 3.43084 8.68396L1.17237 6.61557C0.531328 6.02848 0.892938 4.95946 1.75874 4.88208L4.6377 4.62478C5.01141 4.59138 5.33499 4.35159 5.4757 4.00378L6.57299 1.29144C6.9101 0.458144 8.0899 0.458144 8.42701 1.29144L9.5243 4.00378C9.66501 4.35159 9.98858 4.59138 10.3623 4.62478L13.2413 4.88208C14.1071 4.95946 14.4687 6.02848 13.8276 6.61557L11.5692 8.68396C11.3056 8.92535 11.1909 9.28906 11.2683 9.63798L11.9346 12.6417C12.1276 13.5116 11.1722 14.1805 10.4208 13.7015L8.03755 12.1822Z" fill="white" />
    </svg>,
    label: t('settings.subscription.tab-label', 'Subscriptions'),
    component: SettingsTabSubscriptions,
    path: 'subscriptions',
    isVisible: ({ features }) => Boolean(features?.manage_subscription),
  },
  {
    icon: <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.20521 10.6645L2.31587 11.7553C1.82507 12.0387 1.19749 11.8705 0.914132 11.3797C0.63077 10.8889 0.798929 10.2613 1.28973 9.97798L3.17907 8.88717C2.68852 7.12619 3.42621 5.18982 5.08254 4.23354L9.02601 1.95678C9.30208 1.79739 9.65509 1.89198 9.81448 2.16805L10.0389 2.55679L12.7048 1.01763C13.1956 0.734264 13.8232 0.902423 14.1066 1.39322C14.3899 1.88402 14.2218 2.5116 13.731 2.79496L11.0651 4.33413L12.0912 6.1114L14.757 4.5723C15.2478 4.28894 15.8753 4.4571 16.1587 4.9479C16.4421 5.4387 16.2739 6.06628 15.7831 6.34964L13.1173 7.88873L13.3419 8.27764C13.5012 8.55372 13.4067 8.90673 13.1306 9.06612L9.18712 11.3429C7.53078 12.2992 5.48498 11.9698 4.20521 10.6645Z" fill="white" />
    </svg>,
    label: t('settings.integrations.tab-label', 'Integrations'),
    component: SettingsTabIntegrations,
    path: 'integrations',
    isVisible: ({ features }) => Boolean(features?.integrations),
  },
  {
    icon: <BuildingIcon size={16} style={{width: '16px'}} />,
    label: t('settings.organization.tab-label', 'Manage Organization'),
    component: SettingsManageAdminOrganization,
    path: 'organization',
    isVisible: ({ administering_organization }) => administering_organization != null,
  },
  {
    icon: <WorldIcon size={15} color='white' style={{ marginTop: 1 }} />,
    label: t('settings.language.tab-label', 'Language'),
    component: SettingsTabLanguage,
    path: 'language',
    isVisible: () => I18N_SUPPORT,
  }
];

export default function Settings(props) {
  const { isManageSlackMode, isManageHubspotMode, isManageSalesforceMode, isManageZoom } = props;
  const [userData] = useUserData();

  const { t, i18n } = useTranslation();
  const settingsTabs = loadTabsWithTranslation(t);

  const navigate = useNavigate();

  const searchParams = useParams();
  const { tab } = searchParams;

  const visibleTabs = useMemo(() => {
    return settingsTabs.filter(({ isVisible }) => {
      if (isVisible == null) return true;

      return isVisible({ administering_organization: userData.administering_organization, features: userData.features });
    });
  }, [userData.administering_organization, i18n.language]);

  const [currentTab, setCurrentTab] = useState(isManageSlackMode || isManageHubspotMode || isManageSalesforceMode || isManageZoom ? visibleTabs[2] : tab ? visibleTabs.find(x => x.path === tab) || visibleTabs[0] : visibleTabs[0]);

  const { brieflyIsInstalled, brieflyUserData, setShowSlackTip, setShowZoomOnboardingPopup } = useOutletContext();

  useEffect(() => {
    const newTabConfig = visibleTabs.find(x => x.path === tab);

    if (newTabConfig && newTabConfig !== currentTab) {
      setCurrentTab(newTabConfig);
    }
  }, [tab, visibleTabs]);

  useEffect(() => {
    const targetTabPath = localStorage.getItem('targetTab');
    if (targetTabPath) {
      const targetTab = visibleTabs.find(tab => tab.path === targetTabPath);
      if (targetTab) {
        setCurrentTab(targetTab);
      }
      localStorage.removeItem('targetTab'); // Clear the target tab from localStorage
    }
  }, [visibleTabs]);

  const TabComponent = currentTab.component;

  return <div id="settings-outer-container">
    <div id="settings-container">
      <h1>{t('settings.title', 'Settings')}</h1>
      <div>
        <div className="settings-tabs">
          {visibleTabs.map(tab => {

            const isSelected = tab.label === currentTab.label;

            return <div key={tab.path} className={isSelected ? 'selected' : ''} onClick={() => {
              setCurrentTab(tab);
              navigate(`/settings/${tab.path}`);
            }}>
              {tab.icon}
              {tab.label}
            </div>;
          })}
        </div>
        <div className={"settings-tab-content " + currentTab.path}>
          {currentTab.path === settingsTabs[0].path ? <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
            <TabComponent setShowZoomOnboardingPopup={setShowZoomOnboardingPopup} brieflyIsInstalled={brieflyIsInstalled} brieflyUserData={brieflyUserData} />
          </GoogleOAuthProvider> : <TabComponent setShowZoomOnboardingPopup={setShowZoomOnboardingPopup} brieflyIsInstalled={brieflyIsInstalled} brieflyUserData={brieflyUserData} isManageHubspotMode={isManageHubspotMode} isManageSlackMode={isManageSlackMode} isManageSalesforceMode={isManageSalesforceMode} isManageZoom={isManageZoom} setShowSlackTip={setShowSlackTip} />
          }
        </div>
      </div>
    </div>
  </div>;
}

import React, { useState } from 'react'

interface Props {
	initialText: string,
	clickedText: string,
	onClick: () => void,
	style?: React.CSSProperties,
	className?: string

}

export function ButtonWithTimeoutReplaceOnClick({ initialText, clickedText, onClick, style = {}, className = "" }: Props) {

	const [isClicked, setIsClicked] = useState(false)

	if (isClicked) {
		return <button style={style} className={className}>
			{clickedText}
		</button>
	} else {
		return <button onClick={() => {
			setIsClicked(true)
			setTimeout(() => {
				setIsClicked(false)
			}, 1000)

			onClick()
		}} style={style} className={className}>
			{initialText}
		</button>
	}
}

export default ButtonWithTimeoutReplaceOnClick
import React from 'react';
import { SvgSize } from './types';

const ExternalLinkIcon = (props: SvgSize) => (
  <svg width={props?.size ?? '16'} height={props?.size ?? '16'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M9.56771 1.78385C9.56771 1.35094 9.91865 1 10.3516 1H14.621C14.8303 1 15 1.16968 15 1.379V5.64844C15 6.08135 14.6491 6.43229 14.2161 6.43229C13.7832 6.43229 13.4323 6.08135 13.4323 5.64844V3.66146L8.04682 8.96182C7.74412 9.25974 7.2578 9.2578 6.95748 8.95748C6.65376 8.65376 6.65572 8.16072 6.96185 7.85943L12.3385 2.56771H10.3516C9.91865 2.56771 9.56771 2.21676 9.56771 1.78385ZM11.6823 13.4323V8.78385C11.6823 8.35094 12.0332 8 12.4661 8C12.8991 8 13.25 8.35094 13.25 8.78385V13.4323C13.25 13.8455 13.092 14.2101 12.776 14.526C12.4601 14.842 12.0955 15 11.6823 15H2.56771C2.13021 15 1.75347 14.8542 1.4375 14.5625C1.14583 14.2465 1 13.8698 1 13.4323V4.31771C1 3.88021 1.14583 3.51563 1.4375 3.22396C1.75347 2.90799 2.13021 2.75 2.56771 2.75H7.21615C7.64906 2.75 8 3.10094 8 3.53385C8 3.96676 7.64906 4.31771 7.21615 4.31771H2.56771V13.4323H11.6823Z" fill="currentColor" />
  </svg>
)

export default ExternalLinkIcon;

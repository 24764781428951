import { useState } from 'react';
import { getUserInfo } from '../../utils/genericUtils'
import useInterval from 'use-interval'

export function useUserData() {
  const [userData, setUserData] = useState(getUserInfo())

  useInterval(() => {
    const newData = getUserInfo()

    if (JSON.stringify(newData) !== JSON.stringify(userData)) {
      setUserData(newData)
    }
  }, 500)

  function forceRefreshNow() {
    // Processed in Root.js
    localStorage.setItem('user-force-refresh-now', '1')
  }

  return [userData, forceRefreshNow]
}

export default useUserData;

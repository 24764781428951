import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGet } from '../../utils/genericUtils';
import { CHROME_EXTENSION_ID } from '../../config/config';

export default function SSOSamlHandler() {
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (typeof params.access_token !== 'string') {
      throw new Error('Missing access token');
    }
    if (typeof params.refresh_token !== 'string') {
      throw new Error('Missing refresh token');
    }

    var key = "user-refresh-token"
    localStorage.setItem(key, params.refresh_token);
    localStorage.setItem('user-access-token', params.access_token);
    apiGet({ path: '/get_user' })
      .then(user_response => {
        const user_data_key = 'current-user-data'
        localStorage.setItem(user_data_key, JSON.stringify(user_response))
        localStorage.setItem("LOGGED_IN_AT_LEAST_ONCE_KEY", '1')
        if (global.chrome.runtime == null) {
          console.error('Failed to detect chrome runtime');
          return navigate('/')
        }
        global.chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { eventType: 'SAML_SSO_SUCCESS', ...params });
        navigate('/')
      })
  }, [])
  return null
}

import React, { useState } from "react";
import styled from "styled-components";

interface Props extends React.HTMLProps<HTMLDivElement> {}

const Pre = styled.div`
  margin: 0;
  white-space: pre;
  text-wrap: pretty;
`;
export default function EditableContentTextarea({ name, ...props }: Props): JSX.Element {
  const [value, setValue] = useState(props.defaultValue || '');
  const handleInput: React.FormEventHandler<HTMLDivElement> = (e) => {
    setValue(e.currentTarget.textContent || '');
  };

  return <>
    <Pre contentEditable={!props.readOnly} onBlur={handleInput} {...props} suppressContentEditableWarning>{value}</Pre>
    <input type="hidden" value={value} name={name} />
  </>
}

import { useState } from 'react'


export function SortByDropdown({ items, selectedItem, onChange }) {

	const [isOpen, setIsOpen] = useState(false)

	return <div id="sort-by-dropdown" className={(isOpen && 'open')}>
		<div className="label" onClick={() => setIsOpen(!isOpen)}>
			
			<span><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.74497 0.5L6.73993 3.59896H4.4849L4.4849 8.32778C4.4849 8.73643 4.15362 9.06771 3.74497 9.06771C3.33631 9.06771 3.00503 8.73643 3.00503 8.32778L3.00503 3.59896H0.75L3.74497 0.5ZM5.26007 11.401H7.5151L7.5151 6.67222C7.5151 6.26357 7.84638 5.93229 8.25503 5.93229C8.66369 5.93229 8.99497 6.26357 8.99497 6.67222V11.401H11.25L8.25503 14.5L5.26007 11.401Z" fill="#C6C6C6"/>
			</svg>{selectedItem.label}</span>
		
			<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.384039 0.341255C0.173742 0.537354 0.16692 0.8684 0.368959 1.073L4 4.75L7.63104 1.073C7.83308 0.868401 7.82626 0.537355 7.61596 0.341255C7.4127 0.151714 7.09513 0.159696 6.90165 0.359209L4 3.35127L1.09835 0.359209C0.90487 0.159696 0.587302 0.151714 0.384039 0.341255Z" fill="#C6C6C6"/>
			</svg>

		</div>
		{isOpen && <>
			<div className="dropdown-content">
				{items.map(item => <div className="dropdown-item" onClick={() => { setIsOpen(false); onChange(item) }}>
					{item.label}
				</div>)}
			</div>
			<div className="dropdown-background" onClick={() => setIsOpen(false)}>
			</div>
		</>}
	</div>
}

export default SortByDropdown
import CalendarSvg from '../../images/CalendarSvg'
import GoToExtension from '../../images/GoToExtension'

export function BlockedAccessState(props) {
	const {organizationData} = props
	return <div className="edge-case-state" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<div style={{ maxWidth: 500, textAlign: 'center' }}>
			<img src={organizationData.logo} width="100" height="100" style={{ borderRadius: '100%' }}/>

	        <h1 style={{ fontSize: 20, color: 'white', marginTop: 25, lineHeight: '28px' }}>Welcome to Briefly! Your organization already has a Briefly Team Account.</h1>

	        <h6 style={{ marginTop: 20, marginBottom: 20, fontSize: 16, color: 'white' }}>You need to request access to join {organizationData.name} Team account.</h6>

	        <p style={{ fontSize: 14, color: '#C6C6C6', marginBottom: 20 }} className="blocked-access-text">
	        	{organizationData.free_access_blocked_message}
	        </p>

	        <a style={{ color: '#E44867', textDecoration: 'underline', fontWeight: 600}} href="https://brieflyai.com" target="_blank" rel="noreferrer">
	            Find out more about Briefly
	        </a>
		</div>
	</div>
}

export default BlockedAccessState
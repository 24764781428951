import { useNavigate } from "react-router-dom";
import React from "react";
import BackIcon from "../images/back.svg";
import uploadImage from "../images/upload.png";
import appsImage from "../images/apps.png";
import TopNav from "./TopNav";

const OnboardingNotUser = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div style={{
      background: '#141414',
      minHeight: '100vh',
      width: '100%',
      position: 'relative'

    }}>
      <TopNav />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          maxWidth: 600,
          width: '100%',
          margin: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}>
        <a href={'/onboarding'} style={{
          width: '100%',
          textDecoration: 'none',
          marginBottom: 24,
          color: 'white',
          fontSize: 14,
          fontFamily: 'Roboto',
          fontWeight: '500',
          letterSpacing: 0.15,
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }}>
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={BackIcon}
          />
          Back
        </a>

        <div style={{
          padding: 24,
          background: '#212121',
          borderRadius: 4,
          border: '1px #6D6D6D solid',
          gap: 16,
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}>
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 20,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>Not Using Popular Meeting Apps? No Problem!
            </p>
            <div style={{
              display: 'flex', flexDirection: 'row',
              gap: 10,
              alignItems: 'center'

            }}>
              <img src={uploadImage} alt={'uploadImage'} />

              <div style={{
                width: '100%',
                color: 'white',
                fontSize: 14,
                fontFamily: 'Roboto',
                fontWeight: '400',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }}>Even if you&apos;re not using mainstream meeting platforms, Briefly has you covered. You can
                manually upload any transcript or document right from your dashboard. This ensures you
                can always capture and analyze your discussions, no matter how you conduct them.
              </div>
            </div>

          </div>
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 20,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>Ready When You Are: Expand with Integrations Anytime
            </p>
            <div style={{
              display: 'flex', flexDirection: 'row',
              gap: 10,
              alignItems: 'center'

            }}>
              <img src={appsImage} alt={'uploadImage'} />

              <div style={{
                width: '100%',
                color: 'white',
                fontSize: 14,
                fontFamily: 'Roboto',
                fontWeight: '400',
                letterSpacing: 0.15,
                wordWrap: 'break-word'
              }}>While Briefly offers robust functionality on its own, integrating tools like Slack can
                elevate your experience. You&apos;re fully equipped to add and manage other integrations
                through your dashboard settings whenever you need. Briefly adapts to your workflow, so
                you can add new tools as your business evolves and grows.
              </div>
            </div>

          </div>

        </div>
        <button onClick={handleRedirect}>
          Continue to Briefly settings
        </button>
      </div>

    </div>
  );
};


export default OnboardingNotUser;


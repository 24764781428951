import { ChangeEventHandler } from 'react'
import { useAvailableLanguages } from '../hooks/useAvailableLanguages';
import { useTranslation } from 'react-i18next';
import { notifyExtensionLangChanged } from '../../utils/extension';

function LanguageSelector() {
  const {status, error, result} = useAvailableLanguages();
  const {i18n} = useTranslation();

  
  if(status === 'IN_PROGRESS' || status === 'IDLE') {
    return <div>Loading...</div>
  }
  
  if(status === 'FAILED') {
    return <div>Something went wrong: {error.message}</div>
  }
  
  const onLanguageChanged: ChangeEventHandler<HTMLSelectElement> = (evt) => {
    const {value} = evt.target;
    i18n.changeLanguage(value, () => notifyExtensionLangChanged(value))
  }


  return (
    <div className='language-picker'>
      <select onChange={onLanguageChanged} value={i18n.language}>
      {Object.entries(result).map(([lang, info]) => {
        return <option key={lang} value={lang}>{info.nativeName}</option>
      })}
      </select>
    </div>
  )
}

export default LanguageSelector
import { useState } from 'react'


export function PromptTemplateList({ isEditable, isDeletable, onEdit, onDelete, onClick, items, currentItem }) {

	const [promptBeingEdited, setPromptBeingEdited] = useState(null)
	const [promptNameValue, setPromptNameValue] = useState(null)

	return <div className="recommended-prompts">
            <div className="recommended-prompts-container">
		{items.map(prompt => <div key={prompt.label} className={ "dropdown-item" + (prompt.prompt === currentItem?.prompt ? ' active' : '')} onClick={() => { onClick(prompt) }} style={{
			display: 'flex'
		}}>
			{promptBeingEdited !== prompt ? <span style={{ flex: 1, textOverflow: 'ellipsis', overflowX: 'hidden' }}>{prompt.label}</span> : ''}
			{promptBeingEdited === prompt ? <input id="enter-new-prompt-name" type="text" value={promptNameValue} placeholder="Enter new name" onChange={e => setPromptNameValue(e.target.value)} style={{ padding: 0, flex: 1, background: 'transparent', border: 'none', outline: 'none', color: 'white', fontStyle: 'normal', fontWeight: 500, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.15px', minWidth: 50}} onClick={e => e.stopPropagation()}/> : ''}
			{isEditable ? <div style={{ display: 'flex', width: promptBeingEdited !== prompt  ? (isDeletable ? 45 : 25 ) : 25}} className="icons-container" >
				{promptBeingEdited !== prompt ? <>
					<div className="edit-icon" style={{ marginRight: 5, cursor: 'pointer' }} onClick={e => {
						e.stopPropagation()
						setPromptBeingEdited(prompt)
						setPromptNameValue(prompt.label)
						setTimeout(() => {
							document.getElementById("enter-new-prompt-name").focus()
						}, 100)
					}}>
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.78357 3.01223C9.68622 2.90954 9.5402 2.90954 9.44285 3.01223L4.13733 8.6088L3.89396 10.9963C3.84528 11.3044 4.11299 11.5868 4.40504 11.5355L6.66841 11.2787L11.9739 5.68215C12.0713 5.57946 12.0713 5.42543 11.9739 5.32274L9.78357 3.01223ZM13.7262 2.42176L12.5337 1.16381C12.1686 0.778729 11.5602 0.778729 11.1951 1.16381L10.3433 2.06235C10.246 2.16504 10.246 2.31907 10.3433 2.42176L12.5337 4.73227C12.631 4.83496 12.7771 4.83496 12.8744 4.73227L13.7262 3.83374C14.0913 3.44866 14.0913 2.80685 13.7262 2.42176ZM9.3455 9.76406V12.357H1.55758V4.14181H7.13081C7.22816 4.14181 7.30117 4.11614 7.34985 4.06479L8.32334 3.0379C8.4937 2.83252 8.37201 2.49878 8.1043 2.49878H1.16819C0.511082 2.49878 0 3.06357 0 3.73105V12.7677C0 13.4609 0.511082 14 1.16819 14H9.7349C10.3677 14 10.9031 13.4609 10.9031 12.7677V8.73716C10.9031 8.45477 10.5867 8.32641 10.392 8.50611L9.41851 9.53301C9.36984 9.58435 9.3455 9.66137 9.3455 9.76406Z" fill="white"/>
						</svg>
					</div>
					{isDeletable ? <div className="delete-icon" style={{ position: 'relative', left: 3, top: 1, cursor: 'pointer' }} onClick={e => {
						e.stopPropagation()
						onDelete(prompt);
					}}>
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.75 12.6875C1.75 13.4258 2.32422 14 3.0625 14H10.9375C11.6484 14 12.25 13.4258 12.25 12.6875V3.5H1.75V12.6875ZM9.1875 5.6875C9.1875 5.46875 9.37891 5.25 9.625 5.25C9.84375 5.25 10.0625 5.46875 10.0625 5.6875V11.8125C10.0625 12.0586 9.84375 12.25 9.625 12.25C9.37891 12.25 9.1875 12.0586 9.1875 11.8125V5.6875ZM6.5625 5.6875C6.5625 5.46875 6.75391 5.25 7 5.25C7.21875 5.25 7.4375 5.46875 7.4375 5.6875V11.8125C7.4375 12.0586 7.21875 12.25 7 12.25C6.75391 12.25 6.5625 12.0586 6.5625 11.8125V5.6875ZM3.9375 5.6875C3.9375 5.46875 4.12891 5.25 4.375 5.25C4.59375 5.25 4.8125 5.46875 4.8125 5.6875V11.8125C4.8125 12.0586 4.59375 12.25 4.375 12.25C4.12891 12.25 3.9375 12.0586 3.9375 11.8125V5.6875ZM12.6875 0.875H9.40625L9.13281 0.382812C9.02344 0.164062 8.77734 0 8.55859 0H5.41406C5.19531 0 4.92187 0.164062 4.83984 0.382812L4.59375 0.875H1.3125C1.06641 0.875 0.875 1.09375 0.875 1.3125V2.1875C0.875 2.43359 1.06641 2.625 1.3125 2.625H12.6875C12.9062 2.625 13.125 2.43359 13.125 2.1875V1.3125C13.125 1.09375 12.9062 0.875 12.6875 0.875Z" fill="white"/>
						</svg>
					</div> : ''}
				</> : ''}
				{promptBeingEdited === prompt ? <div onClick={e => { e.stopPropagation(); onEdit(prompt, promptNameValue);  }} style={{ position:'relative', top: 2}}>
					<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.44267 8.0472L12.3294 0.291477C12.635 -0.0090283 13.125 -0.00902804 13.4306 0.291477V0.291477C13.7435 0.599185 13.7435 1.10361 13.4306 1.41132L4.44267 10.25L0.569377 6.44104C0.256473 6.13333 0.256474 5.62891 0.569378 5.3212V5.3212C0.874958 5.02069 1.36504 5.02069 1.67062 5.3212L4.44267 8.0472Z" fill="white"/>
					</svg>
				</div>
				 : ''}
			</div> :''}
		</div>)}
		</div>
	</div>
}

export default PromptTemplateList
import {useState} from 'react'


export function Radio({isChecked, textStyle, setIsChecked, label}) {

    return <div className="radio" onClick={() => setIsChecked(true)} style={{marginRight: 20, marginBottom: 10}}>
        {isChecked ? <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="7" fill="white" stroke="#434343" strokeWidth="2"/>
                <circle cx="8" cy="8" r="4" fill="#E44867"/>
            </svg>

        </> : <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="7" fill="#C6C6C6" stroke="#434343" strokeWidth="2"/>
            </svg>
        </>}

        <span className="label" style={textStyle}>
			{label}
		</span>
    </div>
}

export default Radio
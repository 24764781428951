import styled, { css } from 'styled-components';

export const colors = {
  brandBriefly: "#E44867",
  gray200: "#C6C6C6",
  gray300: "#6D6D6D",
  gray500: "#434343",
  gray800: "#212121",
  gray900: "#141414",
  green500: "#80BF28",
  white: "#FFF",
};

interface Theme {
  link: React.CSSProperties,
  buttons: {
    primary: React.CSSProperties,
    secondary: React.CSSProperties,
    link: React.CSSProperties,
    small: React.CSSProperties,
    inline: React.CSSProperties,
  },
  typography: {
    title: React.CSSProperties,
    body: React.CSSProperties,
  },
}

const theme: Theme = {
  link: {
    color: colors.brandBriefly,
  },
  buttons: {
    primary: {},
    secondary: {
      border: `1px solid ${colors.brandBriefly}`,
      backgroundColor: colors.gray900,
    },
    link: {
      width: 'auto',
      height: 'auto',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      background: 'transparent',
      display: 'inline',
      margin: 0,
      opacity: 1,
      textDecorationLine: 'underline',
    },
    small: {
      padding: '8px 32px',
      height: 'auto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      margin: 0,
    },
    inline: {
      width: 'auto',
    },
  },
  typography: {
    title: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: 'normal',
      letterSpacing: -0.2,
    },
    body: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 0.15,
      margin: 0,
    }
  },
};

// !important are here because there are historical !important on all buttons
export const Button = styled.button<{ $secondary?: boolean, $small?: boolean, $inline?: boolean, $link?: boolean; }>`
  border: 1.5px solid ${colors.brandBriefly} !important;
  margin-top: 0 !important;
  ${({ $inline }) => $inline && css`
    width: auto !important;
  `}

  ${({ $secondary }) => $secondary && css`
    border: 1.5px solid ${colors.brandBriefly} !important;
    background-color: ${colors.gray900} !important;
  `}

  ${({ $small }) => $small && css`
    box-sizing: border-box;
    padding: 6px 30px !important;
    height: auto !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  `}

  ${({ $link }) => $link && css`
    color: ${colors.brandBriefly} !important;
    width: auto;
    height: auto;
    border: none !important;
    border-radius: 0;
    background-color: transparent !important;
    background: transparent !important;
    display: inline;
    margin: 0;
    opacity: 1;
    text-decoration: underline;
  `}
`;

export const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
`;

export const Body = styled.p`
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin: 0;
`;

export default theme;

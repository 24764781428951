import { useState, useEffect } from 'react';
import { getUserAccessToken, csrfGet, csrfPut } from '../../utils/genericUtils'
import { BACKEND_URL } from '../../config/config'
import { useDebounce } from 'use-debounce';

export function useBackendStorage({ defaultValue, processOnInitialLoad, delay, getUrl, putUrl, putTranscriptId }) {
  const [data, setData] = useState(undefined);

  async function syncDataFromStorage() {

    getUserAccessToken(({ access }) => {
      csrfGet(BACKEND_URL + getUrl, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer'
      }).then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return Promise.resolve({ error: true })
        }
      }).then(response => {
        var storageData = response.prompts
        if (!storageData.length) {
          storageData = defaultValue
        }
        setData(processOnInitialLoad ? processOnInitialLoad(storageData) : storageData)
      })
    })

  }

  const [syncDataFromStorageDebounced] = useDebounce(syncDataFromStorage, 100)

  function syncDataToStorage(newValue) {
    if (typeof newValue == 'function') {
      newValue = newValue(data)
    }

    if (JSON.stringify(newValue) !== JSON.stringify(data)) {
      getUserAccessToken(({ access }) => {
        csrfPut(BACKEND_URL + putUrl, {
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access
          },
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            transcript_id: putTranscriptId,
            prompts: newValue
          })
        }).then(response => {
          if (response.ok) {
            return response.json()
          } else {
            return Promise.resolve({ error: true })
          }
        }).then(response => {

        })
      })

      setData(newValue)
    }
  }

  useEffect(() => {
    setTimeout(syncDataFromStorageDebounced, delay ? 100 : 0)

    return () => {

    };
  }, []);

  useEffect(() => {
    setTimeout(syncDataFromStorageDebounced, delay ? 100 : 0)
  }, [getUrl]);

  const [syncDataToStorageDebounced] = useDebounce(syncDataToStorage, 1000)

  return [data, syncDataToStorageDebounced, data === undefined];
}

export function useBackendStorageMyPrompts({ defaultValue, processOnInitialLoad, delay }) {
  return useBackendStorage({
    defaultValue,
    processOnInitialLoad,
    delay,
    getUrl: '/api/briefly/get_briefly_my_saved_prompts',
    putUrl: '/api/briefly/update_briefly_my_saved_prompts'
  })
}

export function useBackendStoragePromptsList({ transcriptId, defaultValue, processOnInitialLoad, delay }) {
  return useBackendStorage({
    defaultValue,
    processOnInitialLoad,
    delay,
    getUrl: '/api/briefly/get_briefly_transcript_prompts?transcript_id=' + transcriptId.replace('bkend-', ''),
    putUrl: '/api/briefly/update_briefly_transcript_prompts?transcript_id=' + transcriptId.replace('bkend-', '')
  })
}

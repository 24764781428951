import { EVENT_TRACKING_URL, EVENT_TRACKING_TOKEN } from "../config/config";
import { csrfPost } from "./genericUtils";

const isEnabled = EVENT_TRACKING_URL != null && EVENT_TRACKING_TOKEN != null;
const ANONYMOUS_ID_KEY = 'ANONYMOUS_ID';

function uid() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

function getTrackingIds(id) {
  let anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);
  if (!anonymousId) {
    anonymousId = uid();
    localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId);
  }

  return { id, anonymousId };
}

const logPage = (e, { id }) => {
  try {
    csrfPost(EVENT_TRACKING_URL, {
      body: JSON.stringify({
        ...e,
        event: "page",
        user: getTrackingIds(id),
        document: {
          title: document.title,
          referrer: document.referrer,
        },
        window: {
          host: window.location.host,
          outerHeight: window.outerHeight,
          outerWidth: window.outerWidth,
        },
      }),
      headers: {
        Authorization: `Basic ${btoa(`:${EVENT_TRACKING_TOKEN}`)}`,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const logClick = (e, { id }) => {
  try {
    const { target } = e;
    const parent = target.parentElement;

    csrfPost(EVENT_TRACKING_URL, {
      body: JSON.stringify({
        event: "click",
        user: getTrackingIds(id),
        tagName: target.tagName,
        text: target.innerText?.substr(0, 100),
        value: target.value,
        id: target.id,
        classes: target.className,
        parent: {
          tagName: parent?.tagName,
          text: parent?.innerText?.substr(0, 100),
          value: parent?.value,
          id: parent?.id,
          classes: parent?.className,
        },
        window: {
          host: window.location.host,
          outerHeight: window.outerHeight,
          outerWidth: window.outerWidth,
        },
      }),
      headers: {
        Authorization: `Basic ${btoa(`:${EVENT_TRACKING_TOKEN}`)}`,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

const userEventTracking = {
  logClick,
  logPage,
  isEnabled,
};

export default userEventTracking;

import { ThreeDots } from 'react-loader-spinner';
import { CaretIcon } from '../Icons';


export function Dropdown({ items, isOpen, setIsOpen, selectedItem, disabled, style, isLoading }) {

  const currentItem = selectedItem ?? items[0];

  return <div id="prompt-template-dropdown" className={isOpen ? 'open' : ''} style={disabled ? { opacity: 0.8, pointerEvents: 'none', ...style } : { ...style }}>
    <div className="label" onClick={() => setIsOpen(!isOpen)}>
      {isLoading ? <span style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'space-between' }}>
        <div style={{ marginRight: 5, position: 'relative', top: 3 }}>
          <ThreeDots
            height="14"
            width="14"
            radius="9"
            color="white"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
            style={{ margin: 'auto' }}
          />
        </div>
        <span>Translating AI summary...</span>
      </span> : <span>{currentItem.label}</span>}

      <CaretIcon style={{ transform: isOpen ? 'rotate(180deg)' : 'none' }} />

    </div>
    {isOpen && <>
      <div className="dropdown-content">

        {items.map((item) => <div key={item.label} className="dropdown-item" onClick={() => { setIsOpen(false); item.onSelect(); }}>
          {item.label}
        </div>)}

      </div>
      <div className="dropdown-background" onClick={() => setIsOpen(false)}>
      </div>
    </>}
  </div>;
}

export default Dropdown;

import { useState } from 'react'


export function Checkbox({ isChecked, setIsChecked, label, style }) {

	return <div className="checkbox" onClick={() => setIsChecked(!isChecked)} style={style}>
		{isChecked ? <>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="1" y="1" width="14" height="14" rx="1" fill="white" stroke="#434343" strokeWidth="2"/>
				<path d="M5 8L6.84515 9.97694C7.31321 10.4784 8.13573 10.3705 8.45855 9.76521L11 5" stroke="#E44867" strokeWidth="3" strokeLinecap="round"/>
			</svg>
		</> : <>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="1" y="1" width="14" height="14" rx="1" fill="#C6C6C6" stroke="#434343" strokeWidth="2"/>
			</svg>
		</>}

		<span className="label">
			{label}
		</span>
	</div>
}

export default Checkbox
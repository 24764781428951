import React from 'react'
import {
  setEmailNotificationsEnabled,
  authCalendar,
  setAttendeesEmailNotificationsEnabled,
  trackEvent
} from '../../utils/genericUtils'
import { useUserData } from '../hooks/useUserData'
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

export function SendSummaryToMyselfConfig() {
  const [{
    is_subscribed_to_emails,
    subscription_is_active,
    admin_user_subscription_is_active
  }] = useUserData()

  const { t } = useTranslation();

  const subscriptionIsActive = subscription_is_active || admin_user_subscription_is_active

  return <div className="email-notifications-switch">
    <span style={{ marginTop: 2 }}>
      {t('settings.ai-summary.myself.label.part1', 'Send a summary email to myself')} {!subscriptionIsActive ? t('settings.ai-summary.myself.label.part2', '[Each email will use 1 AI Credit]') : ''}
      <span className="gray-text">
        {t('settings.ai-summary.myself.details', 'After each call receive an AI summary email to your inbox')}
      </span>
    </span>
    <label className="switch">
      <input type="checkbox" checked={is_subscribed_to_emails} onChange={e => {
        const checked = e.target.checked

        if (checked) {
          trackEvent("email-to-myself-enabled", {
          })
        } else {
          trackEvent("email-to-myself-disabled", {
          })
        }

        setEmailNotificationsEnabled(checked)

      }} />
      <span className="slider round"></span>
    </label>
  </div>

}

export function SendSummaryToEveryoneConfig() {
  const [{
    is_integration_active_google_calendar,
    is_subscribed_to_attendees_emails,
  }, forceRenewUserData] = useUserData()

  const navigate = useNavigate()

  const { t } = useTranslation();

  function onLoginSuccess(data) {
    authCalendar(data.code, () => {
      setTimeout(() => {
        forceRenewUserData()
      }, 500)
    })
  }

  const login = useGoogleLogin({
    onSuccess: codeResponse => onLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  const calendarIsIntegrated = is_integration_active_google_calendar

  return <>
    {calendarIsIntegrated ? <>
      <div className="email-notifications-switch">
        <span style={{ marginTop: 2 }}>
          {t('settings.ai-summary.everyone.title', 'Send a summary email to everyone')}
          <span className="gray-text">
            {t('settings.ai-summary.everyone.detail', 'After each call, automatically send an AI summary email to all participants on the call')}
          </span>
          <a onClick={() => navigate('/settings/integrations')} style={{ textDecoration: 'underline', color: '#E44867', fontSize: 12, fontWeight: 700, cursor: 'pointer' }}>{t('settings.ai-summary.everyone.integrations', 'Manage your integrations')}</a>
        </span>
        <label className="switch">
          <input type="checkbox" checked={is_subscribed_to_attendees_emails} onClick={e => {
            const checked = e.target.checked

            if (checked) {
              trackEvent("email-to-everyone-enabled", {
              })
            } else {
              trackEvent("email-to-everyone-disabled", {
              })
            }

            setAttendeesEmailNotificationsEnabled(checked)

          }} />
          <span className="slider round"></span>
        </label>
      </div>

    </> : <>
      <p className="email-notifications-calendar-info">
        {t('settings.ai-summary.everyone.calendar.label', 'To automatically send an AI summary email to other participants, you need to connect your calendar.')}
      </p>
      <button style={{ width: 220, marginTop: 0 }} onClick={login}>
        {t('settings.ai-summary.everyone.calendar.connect', 'Connect your calendar')}
      </button>
    </>}
  </>
}

export function SettingsTabAISummaryEmail({}) {
  const [{ features }] = useUserData()
  const { t } = useTranslation();

  return <div>
    <h2>{t('settings.ai-summary.tab-label', 'AI summary email')}</h2>

    <SendSummaryToMyselfConfig />

    {features?.integrations && (
      <>
        <hr />
        <SendSummaryToEveryoneConfig />
      </>
    )}

  </div>

}

export default SettingsTabAISummaryEmail

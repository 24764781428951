

export function LoadingSvg() {
	return <svg width="122" height="89" viewBox="0 0 122 89" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M17.6856 14.7314H109.329C111.573 14.7314 113.393 16.5506 113.393 18.7947V82.1824C113.393 84.4264 111.573 86.2456 109.329 86.2456H17.6856C15.4415 86.2456 13.6223 84.4264 13.6223 82.1824V18.7947C13.6223 16.5506 15.4415 14.7314 17.6856 14.7314Z" fill="#2C2C2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M47.2038 66.3193H80.2489C81.7189 66.3193 82.9105 67.511 82.9105 68.9809V75.5288C82.9105 76.9988 81.7189 78.1904 80.2489 78.1904H47.2038C45.7338 78.1904 44.5422 76.9988 44.5422 75.5288V68.9809C44.5422 67.511 45.7338 66.3193 47.2038 66.3193Z" fill="#E44867"/>
		<path d="M56.7098 72.2538H70.7413" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M17.6856 14.7314H109.329C111.573 14.7314 113.393 16.5506 113.393 18.7947V82.1824C113.393 84.4264 111.573 86.2456 109.329 86.2456H17.6856C15.4415 86.2456 13.6223 84.4264 13.6223 82.1824V18.7947C13.6223 16.5506 15.4415 14.7314 17.6856 14.7314Z" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M63.7255 22.7645H93.308" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M23.2038 22.7645H59.5175" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M23.2038 26.5175H85.8511" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M23.2038 30.2719H46.297" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M50.5119 30.2719H72.7209" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M31.1565 47.9892H90.33" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="29.9435" cy="47.9688" rx="6.73971" ry="6.73291" fill="#F97238"/>
		<path d="M26.9501 47.9264L29.797 50.2376L32.9368 46.3538" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse opacity="0.1" cx="97.0697" cy="47.9688" rx="6.73971" ry="6.73291" fill="#C6C6C6"/>
		<ellipse cx="63.5075" cy="47.9688" rx="6.73971" ry="6.73291" fill="#F97238"/>
		<path d="M60.5124 47.9264L63.3593 50.2376L66.5008 46.3538" stroke="white" strokeWidth="0.59901" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M110.945 2.75L98.1386 4.70541C98.1386 4.70541 98.512 10.4535 98.1386 19.1967L112.312 17.197C112.527 12.3409 112.067 7.47849 110.945 2.75V2.75Z" fill="#E44867"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M121.388 14.199H107.711L106.221 29.1895H121.388V14.199V14.199Z" fill="#C6C6C6"/>
		<path d="M110.572 19.9469L117.916 19.6663" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M112.624 22.3808H118.592" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M110.104 25.3346L116.399 24.865" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M101.34 10.9733L107.867 9.87451" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M101.946 13.6701L107.12 12.8726" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M105.095 6.21484C105.239 6.7026 105.437 7.17307 105.683 7.6179Z" fill="white"/>
		<path d="M110.572 19.9469L117.916 19.6663" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M112.624 22.3808H118.592" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M110.104 25.3346L116.399 24.865" stroke="#434343" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M101.34 10.9733L107.867 9.87451" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M101.946 13.6701L107.12 12.8726" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M105.095 6.21484C105.239 6.7026 105.437 7.17307 105.683 7.6179" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M101.661 1.70142C101.608 2.04704 101.716 2.39799 101.955 2.65254C102.206 2.8995 102.572 2.98799 102.907 2.88294L103.201 3.92563C103.272 4.11763 103.264 4.32981 103.181 4.51639C103.007 5.02149 102.869 5.64769 103.231 6.03759C103.423 6.22282 103.674 6.33463 103.939 6.35365C104.738 6.44827 105.519 6.07077 105.944 5.3848C106.048 5.22413 106.105 5.03834 106.112 4.84721C106.1 4.4666 105.817 4.14993 105.442 4.09695C105.341 4.10177 105.24 4.08359 105.148 4.04378C105.067 3.97977 105.008 3.89196 104.98 3.79271L104.568 2.72639C104.517 2.61804 104.488 2.50054 104.483 2.38079C104.526 2.18335 104.595 1.99261 104.689 1.81366C104.773 1.46398 104.647 1.09722 104.366 0.874358C103.545 0.197938 101.822 0.511041 101.661 1.70142Z" fill="#434343"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M7.10342 67.0064C7.10342 67.0064 5.8405 74.2055 11.5451 76.9665C11.5451 76.9665 5.48837 79.7466 5.79156 86.3966L18.8146 86.578C18.8146 86.578 19.1727 79.3192 13.8167 77.0477C13.8167 77.0477 19.1691 74.8668 19.1178 66.7188L7.10342 67.0064Z" fill="white" stroke="#C6C6C6" strokeWidth="0.39934" strokeLinecap="round" strokeLinejoin="round"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M16.0632 72.9761C15.1952 74.7351 14.0586 75.5978 13.2936 76.0054L13.229 76.0393C13.2184 76.0447 13.2078 76.0501 13.1974 76.0554L13.1356 76.086C13.1255 76.0909 13.1155 76.0957 13.1055 76.1005L13.0469 76.128C13.0373 76.1324 13.0278 76.1368 13.0183 76.141L12.963 76.1656V76.1656L12.91 76.1883C12.9013 76.1919 12.8928 76.1954 12.8844 76.1989L12.835 76.2189V76.2189L12.7881 76.2371V76.2371L12.7439 76.2537V76.2537L12.7024 76.2686V76.2686L12.6637 76.282V76.282L12.6278 76.2939V76.2939L12.595 76.3044V76.3044L12.5653 76.3135V76.3135C12.5671 76.3197 12.5683 76.3263 12.569 76.333L12.5695 76.3446V79.7144C12.5695 79.7198 12.5691 79.7251 12.5685 79.7304L12.6121 79.7379V79.7379L12.662 79.7474C13.508 79.9166 16.6322 80.9192 17.5815 86.5649L6.90048 86.3906L6.90785 86.3494V86.3494L6.91984 86.2871V86.2871L6.93125 86.231V86.231L6.94554 86.1637V86.1637L6.96284 86.0858V86.0858L6.98327 85.9978C6.99061 85.9668 6.9985 85.9342 7.00695 85.9001L7.03402 85.7934C7.41801 84.311 8.73729 80.5264 12.3305 79.739C12.3296 79.735 12.3289 79.7305 12.3285 79.726L12.3279 79.7144V76.3446C12.3279 76.3239 12.3331 76.3044 12.3424 76.2873C11.0466 75.5779 9.96441 74.5338 9.20897 73.2635L9.17325 73.2029L16.0632 72.9761Z" fill="#E44867"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.0837 86.2713L19.5714 86.4742V88.6288H5.0837V86.2713ZM20.0763 64.4688V66.9421L5.99209 67.2452V64.9223L20.0763 64.4688Z" fill="#6D6D6D"/>
	</svg>
}

export default LoadingSvg
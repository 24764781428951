/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { updateTranscriptSettings, recallAiBotPause, recallAiBotResume, recallAiBotKickOut, getTranscriptionAsText, formatDatetime, getUploadedTranscriptFullData, checkIfStandardSummaryWasMadeBefore } from '../utils/genericUtils';
import MeetingTabOutput from './meetingTabs/MeetingTabOutput';
import MeetingTabSummary from './meetingTabs/MeetingTabSummary';
import MeetingTabTranscript from './meetingTabs/MeetingTabTranscript';
import { ThreeDots } from 'react-loader-spinner';
import { useUserData } from './hooks/useUserData';
import { SendSummaryToMyselfConfig, SendSummaryToEveryoneConfig } from './settingsTabs/SettingsTabAISummaryEmail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RobotPicture from '../images/robot_picture.png';
import RobotTranscribing from '../images/robot_transcribing.gif';
import EdgeCaseMeeting from "../images/EdgeCaseMeeting";
import MeetingTabSalesforce from "./meetingTabs/MeetingTabSalesforce";
import { useTranslation } from 'react-i18next';
import { AlertIcon, CaretIcon, FileUploadIcon, GoogleMeetLogo, HubspotLogo, SalesforceLogo, SlackLogo, ZoomLogo } from './Icons';

const loadTranslatedTabs = (t) => [
  {
    id: 'ai-summary',
    label: t('meeting.tabs.ai-summary', 'AI Summary'),
    component: MeetingTabSummary,
    // dontShowForBackend: true
  },
  {
    id: 'salesforce',
    label: 'Salesforce',
    component: MeetingTabSalesforce,
    showIf: ({ workflow_ids }) => workflow_ids.length > 0,
  },
  {
    id: 'create',
    label: t('meeting.tabs.create', 'Create'),
    component: MeetingTabOutput,
    version: 3,
    isGpt4: true,
  },
  {
    id: 'transcript',
    label: t('meeting.tabs.transcript', 'Transcript'),
    component: MeetingTabTranscript
  }
];

const ATTENDEE_ICON_COLOR_POOL = [
  '#097449',
  '#771E1E',
  '#F97238',
  '#80BF07',
  '#00A3FF',
  '#BF00B8'
];

function AttendeeWithIcon({ email, name, index, style }) {

  const color = ATTENDEE_ICON_COLOR_POOL[index % ATTENDEE_ICON_COLOR_POOL.length];

  const initials = email ? (email[0] + email.split('@')[1][0]).toUpperCase() : (name[0] + (name.split(' ')?.[1]?.[0] || '')).toUpperCase();

  return <div style={{ display: 'inline-block', marginRight: 10, marginTop: 15, ...style }}>
    <div style={{
      background: color,
      width: 25,
      height: 25,
      borderRadius: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
    }}>
      {initials}
    </div>
    <span style={{ color: '#C6C6C6', "fontFamily": "Roboto", "fontSize": "12px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "16px", "letterSpacing": "0.15px" }}>
      {email ? email : name}
    </span>
  </div>;
}

function HubspotSalesforceSwitches(props) {

  const { loadBackendMeetingData, meetingData } = props;

  const { t } = useTranslation();

  const detected_hubspot_contacts = [...new Set(meetingData.detected_hubspot_contacts)];
  const detected_salesforce_contacts = [...new Set(meetingData.detected_salesforce_contacts)];

  const { meetingId, ignore_salesforce_transcript_upload, ignore_hubspot_transcript_upload, ignore_crm_growth_opportunities } = meetingData;

  const isUploadToHubspotActive = !ignore_hubspot_transcript_upload;
  const isUploadToSalesforceActive = !ignore_salesforce_transcript_upload;
  const isGrowthOpportunitiesActive = !ignore_crm_growth_opportunities;

  const [salesforceContactInfoOpen, setSalesforceContactInfoOpen] = useState(true);
  const [hubspotContactInfoOpen, setHubspotContactInfoOpen] = useState(true);

  return <>
    <div>
      <div className={"email-notifications-switch hubspot-connected save-this-summary-to-hubspot-container " + (isUploadToHubspotActive ? 'checked' : 'not-checked')} style={{ "margin": "10px 0", "marginRight": "0" }}>
        <span>
          <span style={{ "marginTop": "2px", "display": "flex", "fontSize": "16px", "alignItems": "center" }}>
            <HubspotLogo size="18" style={{ marginRight: 9 }} />
            {t('meeting.hubspot-salesforce.save.title', 'Save this AI summary to Hubspot')}
          </span>
          <span className="gray-text">{t('meeting.hubspot-salesforce.save.description', 'Automatically add your call transcripts and AI summaries to Hubspot contacts')}</span>
        </span>
        <label className="switch" style={{ "marginTop": "2px" }}>
          <input type="checkbox" className="hubspot" checked={isUploadToHubspotActive} onClick={() => {
            const newValue = isUploadToHubspotActive;
            updateTranscriptSettings(meetingId, { ignore_hubspot_transcript_upload: newValue }).then(() => {
              loadBackendMeetingData();
            });
          }} />
          <span className="slider round"></span>
        </label>
      </div>

      <div className={"hubspot-contact-info " + (hubspotContactInfoOpen ? 'open' : '')}>
        <div onClick={() => setHubspotContactInfoOpen(!hubspotContactInfoOpen)} className="header" style={{ "display": "flex", "alignItems": "center", "justifyContent": "space-between" }}>
          <div>
            <AlertIcon />
            {detected_hubspot_contacts.length > 0 ?
              <span>{t('meeting.hubspot-salesforce.contact-info.update', 'Hubspot contacts will be updated')}</span>
              : <span>{t('meeting.hubspot-salesforce.contact-info.no-detected', 'No matching contact detected')}</span>}
          </div>
          <CaretIcon />
        </div>
        <p className="body">
          {detected_hubspot_contacts.length > 0 ?
            <span className="text">
              {t('meeting.hubspot-salesforce.contact-info.detected.part1', 'Briefly detected')} {t('meeting.hubspot-salesforce.contact-info.detected.part2', {count: detected_hubspot_contacts.length, defaultValue: (detected_hubspot_contacts.length > 1 ? 'new contacts' : 'a new contact')})}: {detected_hubspot_contacts.join(', ')} {t('meeting.hubspot-salesforce.contact-info.detected.part3', 'will be added to Hubspot.')}
            </span> :
            <span className="text">
              {t('meeting.hubspot-salesforce.contact-info.not-detected', 'Briefly couldn’t detect a contact for this call and will be unable to save call data to HubSpot.')}
            </span>
          }
          <span className="slack-not-connected">
            <span className="is-not-hubspot-admin">
              {t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part1', 'Ask')}{' '}<span className="hubspot-admin-email">{t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part2', 'an admin')}</span>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part3', 'to connect Slack to easily manage this.')}
            </span>
            <span className="is-hubspot-admin">
              <a style={{ "color": "var(--primary-briefly, #E44867)", "textDecoration": "underline" }} target="_blank" href="https://app.brieflyai.com/settings/integrations" rel="noreferrer">{t('meeting.hubspot-salesforce.contact-info.slack-admin.part1', 'Connect Slack')}</a>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-admin.part2', 'to easily manage this.')}
            </span>
          </span>
          <span className="slack-connected">
            {t('meeting.hubspot-salesforce.contact-info.slack-connected.part1', 'You can manage this in Slack.')}{' '}<span className="is-not-hubspot-admin">{t('meeting.hubspot-salesforce.contact-info.slack-connected.part2', 'If you don’t see a message in Slack after this meeting, contact')}{' '}<span className="hubspot-admin-email">{t('meeting.hubspot-salesforce.contact-info.slack-connected.part3', 'an admin')}</span>.</span>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-connected.part4', 'For this meeting, add new contact manually in Hubspot.')}
          </span>
        </p>
      </div>

      <div className={"email-notifications-switch salesforce-connected save-this-summary-to-salesforce-container " + (isUploadToSalesforceActive ? 'checked' : 'not-checked')} style={{ "margin": "10px 0", "marginRight": "0" }}>
        <span>
          <span style={{ "marginTop": "2px", "display": "flex", "fontSize": "16px", "alignItems": "center" }}>
            <SalesforceLogo width="18" style={{ marginRight: 9 }} />
            {t('meeting.zoom-salesforce.title', 'Save this AI summary to Salesforce')}
          </span>
          <span className="gray-text">{t('meeting.zoom-salesforce.description', 'Automatically add your call transcripts and AI summaries to Salesforce contacts')}</span>
        </span>
        <label className="switch" style={{ "marginTop": "2px" }}>
          <input type="checkbox" className="salesforce" checked={isUploadToSalesforceActive} onClick={() => {
            const newValue = isUploadToSalesforceActive;
            updateTranscriptSettings(meetingId, { ignore_salesforce_transcript_upload: newValue }).then(() => {
              loadBackendMeetingData();
            });
          }} />
          <span className="slider round"></span>
        </label>
      </div>

      <div className={"salesforce-contact-info " + (salesforceContactInfoOpen ? 'open' : '')}>
        <div onClick={() => setSalesforceContactInfoOpen(!salesforceContactInfoOpen)} className="header" style={{ "display": "flex", "alignItems": "center", "justifyContent": "space-between" }}>
          <div>
            <AlertIcon />
            {detected_salesforce_contacts.length > 0 ?
              <span>{t('meeting.zoom-salesforce.contacts.updated', 'Salesforce contacts will be updated')}</span>
              : <span>{t('meeting.zoom-salesforce.contacts.no-match', 'No matching contact detected')}</span>}
          </div>
          <CaretIcon />
        </div>
        <p className="body">
          {detected_salesforce_contacts.length > 0 ?
            <span className="text">
              {t('meeting.zoom-salesforce.contact-info.detected.part1', 'Briefly detected')} {t('meeting.zoom-salesforce.contact-info.detected.part2', {count: detected_hubspot_contacts.length, defaultValue: (detected_hubspot_contacts.length > 1 ? 'new contacts' : 'a new contact')})}: {detected_hubspot_contacts.join(', ')} {t('meeting.zoom-salesforce.contact-info.detected.part3', 'will be added to Salesforce.')}
            </span> :
            <span className="text">
              {t('meeting.zoom-salesforce.contact-info.not-detected', 'Briefly couldn’t detect a contact for this call and will be unable to save call data to Salesforce.')}
            </span>
          }
          <span className="slack-not-connected">
            <span className="is-not-salesforce-admin">
              {t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part1', 'Ask')}{' '}<span className="hubspot-admin-email">{t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part2', 'an admin')}</span>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-not-admin.part3', 'to connect Slack to easily manage this.')}
            </span>
            <span className="is-salesforce-admin">
              <a style={{ "color": "var(--primary-briefly, #E44867)", "textDecoration": "underline" }} target="_blank" href="https://app.brieflyai.com/settings/integrations" rel="noreferrer">{t('meeting.hubspot-salesforce.contact-info.slack-admin.part1', 'Connect Slack')}</a>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-admin.part2', 'to easily manage this.')}
            </span>
          </span>
          <span className="slack-connected">
            {t('meeting.hubspot-salesforce.contact-info.slack-connected.part1', 'You can manage this in Slack.')}{' '}<span className="is-not-hubspot-admin">{t('meeting.hubspot-salesforce.contact-info.slack-connected.part2', 'If you don’t see a message in Slack after this meeting, contact')}{' '}<span className="hubspot-admin-email">{t('meeting.hubspot-salesforce.contact-info.slack-connected.part3', 'an admin')}</span>.</span>{' '}{t('meeting.hubspot-salesforce.contact-info.slack-connected.part4', 'For this meeting, add new contact manually in Hubspot.')}
          </span>
        </p>
      </div>

      <div className="email-notifications-switch hubspot-connected-or-salesforce-connected slack-connected growth-opportunities-visible" style={{ "margin": "10px 0", "marginRight": "0" }}>
        <span style={{ "marginTop": "2px", "display": "flex", "fontSize": "16px", "alignItems": "center" }}>
          <SlackLogo width="18" style={{ marginRight: 9 }} />
          {t('meeting.slack-opportunities.title', 'Send this growth opportunity to Slack')}
        </span>
        <label className="switch" style={{ "marginTop": "2px" }}>
          <input type="checkbox" className="slack" checked={isGrowthOpportunitiesActive} onClick={() => {
            const newValue = isGrowthOpportunitiesActive;
            updateTranscriptSettings(meetingId, { ignore_crm_growth_opportunities: newValue }).then(() => {
              loadBackendMeetingData();
            });
          }} />
          <span className="slider round"></span>
        </label>
      </div>

      <div className="email-notifications-switch hubspot-connected-or-salesforce-connected slack-not-connected growth-opportunities-visible" style={{ "margin": "10px 0", "marginRight": "0" }}>
        <span style={{ "marginTop": "2px", "display": "flex", "fontSize": "12px", "alignItems": "center" }}>

          <span>
            <a style={{ "color": "var(--primary-briefly, #E44867)", "textDecoration": "underline" }} target="_blank" href="https://app.brieflyai.com/settings/integrations" rel="noreferrer">{t('meeting.slack-opportunities.connect.part1', 'Connect Slack')}</a>{' '}{t('meeting.slack-opportunities.connect.part2', 'to get growth opportunities')}
          </span>
        </span>
        <label className="switch" style={{ "marginTop": "2px", "width": "0" }}>

        </label>
      </div>

    </div>
  </>;
}

function InProgressZoomMeeting(props) {

  const { loadBackendMeetingData, meetingData } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showKickOutConfirmationPopup, setShowKickOutConfirmationPopup] = useState(false);

  if (!meetingData) {
    return <div style={{ width: '100%', height: '100%', background: '#141414', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="white"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>;
  }

  const { recall_ai_bot_status } = meetingData;

  const isTranscribing = recall_ai_bot_status == "transcribing";
  const isPaused = recall_ai_bot_status == "paused";
  const isKickedOut = recall_ai_bot_status == "kicked_out";

  const backendMeetingId = meetingData.meetingId.split('-')[1];

  const statusLabel = {
    "transcribing": "Transcribing",
    "paused": "Paused",
    "kicked_out": "Bot Removed"
  }[recall_ai_bot_status] || "Error";

  return <div id="in-progress-zoom-meeting-dashboard" style={{ background: '#141414', height: '100%', color: 'white' }}>
    {showKickOutConfirmationPopup ? <div className="transcript-info-popup-container out-of-credits">
      <div className="popup-background" onClick={() => setShowKickOutConfirmationPopup(false)}></div>
      <div className="popup-content" style={{ padding: 20 }}>

        <h1 style={{ "color": "#FFF", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
          {t('meeting.zoom.remove.title', 'Are you sure you want to remove Briefly note-taker from this call?')}
        </h1>

        <h5 style={{ marginTop: 20, marginBottom: 7, "color": "var(--text-text-primary, #FFF)", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          {t('meeting.zoom.remove.subtitle', 'By removing the bot from this Zoom call you will not receive the transcript or the AI summaries after your call ends.')}
        </h5>

        <p onClick={() => navigate('/settings/integrations/zoom')} style={{ "color": "var(--primary-grey, #C6C6C6)", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          {t('meeting.zoom.remove.followup.part1', 'Briefly note-taker will still join future calls unless based on your')}{' '}<a style={{ cursor: 'pointer', "color": "var(--primary-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "18px", "letterSpacing": "0.3px", "textDecorationLine": "underline" }}>{t('meeting.zoom.remove.followup.part2', 'Zoom settings')}</a>.
        </p>

        <div>
          <button onClick={() => {
            recallAiBotKickOut(backendMeetingId, () => loadBackendMeetingData());
            setShowKickOutConfirmationPopup(false);
          }} style={{ "height": "32px !important", "fontSize": "14px", "padding": "0 25px" }}>
            {t('meeting.zoom.remove.confirm', 'Remove the bot from this call')}
          </button>
          <button onClick={() => {
            setShowKickOutConfirmationPopup(false);
          }} style={{ "height": "32px !important", "fontSize": "14px", "padding": "0 25px" }}>
            {t('meeting.zoom.remove.cancel', 'Keep the bot in this call')}
          </button>
        </div>

      </div>
    </div> : ''}
    <GoogleOAuthProvider clientId="576404817524-7ghb7h6lrj86l827mhddqfal0c61bj9n.apps.googleusercontent.com">
      <div style={{ maxWidth: 1000, margin: 'auto', "display": "flex", "alignItems": "center", "justifyContent": "space-between", "padding": "15px 30px" }}>
        <span style={{ "color": "var(--primary-white, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>{meetingData.meetingName}</span>
        <span style={{ "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "20px", "letterSpacing": "0.15px" }}>{formatDatetime(meetingData.meetingDateTime)}</span>
      </div>
      <div style={{ paddingLeft: 25, paddingRight: 25, display: 'flex', "maxWidth": "960px", "margin": "auto", "height": "calc(100% - 75px)" }}>
        <div className="left-sidebar" style={{ paddingBottom: 25, background: '#222', marginRight: 25, paddingLeft: 20, paddingRight: 20 }}>
          <h2 style={{ "color": "var(--primary-white, #FFF)", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>{t('manage', {ns:'Actions', defaultValue: 'Manage'})} Briefly<br />note-taker</h2>
          <span style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
            <ZoomLogo width="18" />
            <span style={{ marginLeft: 7, position: 'relative', top: 1, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>{statusLabel} </span>
          </span>

          <div className="robot-container" style={{ maxWidth: '100%', overflowX: 'hidden', margin: "auto", paddingTop: 25, paddingBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 200, height: 160, "borderRadius": "14px", "background": "var(--gradient-horizontal-dark, linear-gradient(90deg, #B700AF 0%, #F16617 100%))" }}>
            <img src={RobotPicture} width="80" />
            <img src={RobotTranscribing} width="132" style={{ opacity: isTranscribing ? 1 : 0 }} />
          </div>

          {isTranscribing ? <button onClick={() => recallAiBotPause(backendMeetingId, () => loadBackendMeetingData())}>{t('pause', {ns: 'Actions', defaultValue: 'Pause'})}</button> : ''}
          {isPaused ? <button onClick={() => recallAiBotResume(backendMeetingId, () => loadBackendMeetingData())}>{t('resume', {ns: 'Actions', defaultValue: 'Resume'})}</button> : ''}
          {!isKickedOut ? <button onClick={() => setShowKickOutConfirmationPopup(true)}>{t('kick-out', {ns: 'Actions', defaultValue: 'Kick Out'})}</button> : ''}
        </div>
        <div style={{ flex: 1 }}>

          <h2 style={{ "color": "var(--primary-white, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>{t('meeting.zoom.manage.call-settings.title', 'Call Settings')}</h2>

          <HubspotSalesforceSwitches meetingData={meetingData} loadBackendMeetingData={loadBackendMeetingData} />

          <div>
            <h5 style={{ marginBottom: 0, "color": "var(--primary-white, #FFF)", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>Call participants</h5>
            {meetingData.meeting_participants_emails.map((email, i) => <AttendeeWithIcon key={email} index={i} email={email} />)}
            <div style={{ marginTop: 15 }}>
              <SendSummaryToMyselfConfig />
              <SendSummaryToEveryoneConfig />
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  </div>;
}

export default function Meeting() {
  const searchParams = useParams();
  const { meetingDataById, setShowZoomTranscriptInfoPopup, setShowMsTeamsTranscriptInfoPopup, setShowOutOfCreditsPopup, searchWords } = useOutletContext();

  const { t, i18n } = useTranslation();
  const translatedTabs = loadTranslatedTabs(t);

  const urlSearchParams = new URLSearchParams(window.location.search);

  const isTranscriptTabInitial = urlSearchParams.get('transcript') || urlSearchParams.get('tab') === 'transcript';
  const isCreateTabInitial = urlSearchParams.get('tab') === 'create';
  const isSummaryTabInitial = urlSearchParams.get('tab') === 'summary';

  const isInitialTabExplicit = isTranscriptTabInitial || isCreateTabInitial || isSummaryTabInitial;
  const initialTab = isTranscriptTabInitial ? translatedTabs[3] : translatedTabs[0];


  const [previousCurrentTab, setCurrentTab] = useState(initialTab);

  const { meetingId } = searchParams;

  const [backendMeetingData, setBackendMeetingData] = useState(null);
  const tabContext = { workflow_ids: backendMeetingData?.workflow_ids || [] };

  const tabs = useMemo(() => {
    return translatedTabs.filter(({ showIf }) => {
      if (showIf == null) return true;
      return showIf(tabContext);
    });
  }, [tabContext, i18n.language]);

  const currentTab = tabs.find(({ id }) => id === previousCurrentTab.id) || tabs[0];

  const currentTabVersion = currentTab.version;

  const transcriptId = meetingDataById?.[meetingId]?.transcriptId || meetingId;

  const combinedMeetingData = { ...meetingDataById?.[meetingId], ...backendMeetingData };

  const [isBackend, setIsBackend] = useState(false);//meetingDataById?.[meetingId]?.isBackend //|| transcriptId
  const [isLoadingBackendData, setIsLoadingBackendData] = useState(true);

  const [userData] = useUserData();

  const { subscription_is_active, admin_user_subscription_is_active, subscription_plan } = userData;

  const subscriptionIsActive = subscription_is_active || admin_user_subscription_is_active;

  const [meetingSummaryWasRequestedBefore, setMeetingSummaryWasRequestedBefore] = useState(false);
  const [meetingSummaryWasRequestedBeforeMeetingId, setMeetingSummaryWasRequestedBeforeMeetingId] = useState(null);

  const aiSummaryCostsAiCredit = !subscriptionIsActive && !meetingSummaryWasRequestedBefore && meetingSummaryWasRequestedBeforeMeetingId === meetingId && subscription_plan !== 'STANDARD_GIVEN_FOR_FREE';

  useEffect(() => {
    setMeetingSummaryWasRequestedBefore(false);
    setMeetingSummaryWasRequestedBeforeMeetingId(null);
    if (isBackend && !backendMeetingData) {
      return;
    }
    checkIfStandardSummaryWasMadeBefore(isBackend ? getTranscriptionAsText(backendMeetingData) : getTranscriptionAsText(meetingData), meetingData.meetingId, wasMadeBefore => {
      setMeetingSummaryWasRequestedBefore(wasMadeBefore.result);
      setMeetingSummaryWasRequestedBeforeMeetingId(meetingId);
    });
  }, [meetingId, backendMeetingData]);

  function loadBackendMeetingData(showLoader = true) {
    if  (transcriptId === 'demo-meeting') {
      setIsLoadingBackendData(false);
      setBackendMeetingData({});
      return;
    }
    if (showLoader) {
      setIsLoadingBackendData(true);
    }

    getUploadedTranscriptFullData(transcriptId).then(response => {

      if (response.error) {
        setIsBackend(false);
        setIsLoadingBackendData(false);
        setBackendMeetingData({});
        return;
      }

      setIsBackend(true);

      const { transcript, id } = response;

      var COLOR_POOL = [
        '#00A4D8',
        '#80BF07',
        '#EC8E00',
        '#F46FB7',
        '#03C289',
        '#FFF500',
        '#B4B3FE',
        '#FF5B6F',
        '#D88E00'
      ];

      var COLOR_POOL_I = 0;

      const deviceNamesById = {};
      const deviceColorsById = {};
      const transcriptionBlocks = [];

      if (transcript.split("\n").filter(x => x.trim()).map(x => /^.+:\s.+$/.test(x)).includes(false)) {

        const ALL_SPEAKERS_ID = 'all_speakers';
        const ALL_SPEAKERS_NAME = 'All Speakers';

        deviceColorsById[ALL_SPEAKERS_ID] = COLOR_POOL[0];
        deviceNamesById[ALL_SPEAKERS_ID] = ALL_SPEAKERS_NAME;
        transcriptionBlocks.push({
          messageId: '1',
          deviceId: ALL_SPEAKERS_ID,
          text: transcript
        });

      } else {

        const speakerIdByName = {};

        var messageId = 1;

        for (var line of transcript.split("\n")) {
          if (!line.trim())
            continue;

          const speakerName = line.split(":")[0];
          const phrase = line.slice(speakerName.length + 1);

          var speakerId;
          if (speakerIdByName[speakerName]) {
            speakerId = speakerIdByName[speakerName];
          } else {
            speakerId = (Object.keys(speakerIdByName).length + 1 + '');
            speakerIdByName[speakerName] = speakerId;
            deviceNamesById[speakerId] = speakerName;
            deviceColorsById[speakerId] = COLOR_POOL[COLOR_POOL_I++ % COLOR_POOL.length];
          }

          transcriptionBlocks.push({
            messageId: (messageId++) + '',
            deviceId: speakerId,
            text: phrase
          });

        }
      }

      var data = {
        ...response,
        deviceNamesById,
        deviceColorsById,
        transcriptionBlocks,
        meetingId,
        transcriptId: id,
        short_summary: response.overall_summary,
        long_summary: response.bullet_summary,
        key_insights: response.key_insights,
        action_items: response.action_items
      };

      for (var key in data) {
        var value = data[key];

        meetingDataById[meetingId][key] = value;
      }

      setBackendMeetingData(data);
      setIsLoadingBackendData(false);
    });
  }


  useEffect(() => {
    if (isInitialTabExplicit)
      setCurrentTab(initialTab);

    setBackendMeetingData(null);
    loadBackendMeetingData();
  }, [meetingId]);

  const meetingData = meetingDataById[meetingId];

  useEffect(() => {
    loadBackendMeetingData();
  }, [meetingData?.meeting_status]);

  if (!meetingDataById[meetingId]) {

    window.location.pathname = '/';

    return '';
  }

  const highlightSelectedCallStyle = <style>
    {`
          .call-label[meetingid="${searchParams.meetingId}"] {
            border: 2px solid #E44867;
            padding: 6px;
            padding-right: 30px;
          }

          .call-label[meetingid="${searchParams.meetingId}"] .delete-icon {
            top: 8px;
            right: 6px;
          }


        `}
  </style>;


  if (meetingData.meeting_status && meetingData.meeting_status !== 'ready') {

    if (meetingData.meeting_status === "ongoing") {
      return <>
        {highlightSelectedCallStyle}
        <InProgressZoomMeeting meetingData={backendMeetingData} loadBackendMeetingData={loadBackendMeetingData} />
      </>;
    }

    function getUpperText(m) {
      if (m.meeting_status && m.meeting_status !== 'ready') {
        if (m.meeting_status === "ongoing") {
          return "Briefly is transcribing this meeting...";
        }
        if (m.meeting_status === "processing") {
          return "Briefly is processing your Zoom recording...";
        }
        if (m.meeting_status === "captions_missing") {
          return "Transcript not available";
        }
        if (m.meeting_status === "recording_permission_denied") {
          return <>
            Permission to record was denied
          </>;
        }

        return "error";
      }
      return '';
    }

    function getLowerText(m) {
      if (m.meeting_status && m.meeting_status !== 'ready') {
        if (m.meeting_status === "ongoing") {
          return <>
            The AI summary, transcript and access to BrieflyCreate will be available after the call ends. Make sure you have turned on closed captions from your Zoom Workspace settings.
            <br />
            <a href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0058810" target="_blank" style={{ textDecoration: 'underline', color: '#E44867' }} rel="noreferrer">How to turn on Zoom captions</a>
          </>;
        }
        if (m.meeting_status === "processing") {
          return "This may take few minutes. The AI summary, transcript and access to BrieflyCreate will be available soon.";
        }
        if (m.meeting_status === "captions_missing") {
          return <>
            To ensure that Briefly captures your transcript, remember to turn on closed captions from your Zoom Workspace settings.
            <br />
            <a href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0058810" target="_blank" style={{ textDecoration: 'underline', color: '#E44867' }} rel="noreferrer">How to turn on Zoom captions</a>
          </>;
        }
        if (m.meeting_status === "recording_permission_denied") {
          return <>
            To ensure that Briefly captures your transcript, please grant record permission to Briefly Bot next time.
          </>;
        }

        return "error";
      }
      return '';
    }

    return <div className="edge-case-state">
      {highlightSelectedCallStyle}
      <EdgeCaseMeeting />
      <h3>{getUpperText(meetingData)}</h3>
      <p style={{
        maxWidth: 310,
        fontSize: 14,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: 0.15,
        marginTop: 0
      }}>
        {getLowerText(meetingData)}
        <br /><br />
        {/*Please refresh page in a few minutes (temporary need, auto-refresh will be implemented soon)*/}
        {/*The AI summary, transcript and access to BrieflyCreate will be available soon*/}
      </p>
    </div>;
  }


  const sourceIcon = meetingData.meetingPlatform === 'zoom' ? <ZoomLogo size={24} style={{ position: 'relative', top: 4, marginRight: 5 }} /> : meetingData.meetingPlatform === 'Google Meets' ? <GoogleMeetLogo width={24} style={{ position: 'relative', top: 4, marginRight: 5 }} /> : meetingData.isBackend ? <FileUploadIcon color="#fff" width={16} style={{ position: 'relative', top: 1, marginRight: 5 }} /> : <GoogleMeetLogo width={24} style={{ position: 'relative', top: 4, marginRight: 5 }} />;

  if (isLoadingBackendData)
    return <div style={{ width: '100%', height: '100%', background: '#141414', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="white"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>;

  return (
    <div id="meeting-container">
      <div>
        <style>
          {highlightSelectedCallStyle}
        </style>
        <div style={{ maxWidth: 1000, margin: 'auto', "display": "flex", "alignItems": "center", "justifyContent": "space-between", "padding": "15px 30px" }}>
          <span style={{ "color": "var(--primary-white, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "20px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
            {sourceIcon}
            {meetingData.meetingName}</span>
          <span style={{ "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "20px", "letterSpacing": "0.15px" }}>{formatDatetime(meetingData.meetingStartDate)}</span>
        </div>
        <div style={{ maxWidth: 1000, margin: 'auto', "display": "flex", "alignItems": "center", "padding": "0 30px", "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "12px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "16px", "letterSpacing": "0.15px", overflowX: 'auto' }}>
          <div style={{ whiteSpace: 'nowrap', paddingBottom: 15 }}>
            {t('meeting.heading.with-label', 'Meeting with:')}
            <span style={{ marginLeft: 5 }}>
              {isLoadingBackendData ? 'Loading...' :
                combinedMeetingData?.meeting_participants_emails?.length > 0 ?
                  combinedMeetingData?.meeting_participants_emails?.map((email) => email).join(', ') :
                  combinedMeetingData?.meeting_participants?.length > 0 ?
                    combinedMeetingData?.meeting_participants?.map((name) => name).join(', ') :
                    Object.values(combinedMeetingData?.deviceNamesById || {})?.map((name) => name).join(', ')
              }
            </span>
          </div>
        </div>
        <div id="meeting-tabs">
          {tabs.map(tab => {
            const isCurrentTab = tab.id === currentTab.id;
            return <div key={tab.id} className={"meeting-tab " + (isCurrentTab && 'active')} onClick={() => setCurrentTab(tab)}>
              <span>{tab.label}</span>
            </div>;
          })}
        </div>
      </div>
      <div id="meeting-tab-content-set">
        {tabs.map((tab, i) => {
          const Component = tab.component;
          const isGpt4 = tab.isGpt4;
          const isActive = tab == currentTab;
          return <div key={`tab-content-${tab.id}`} className={`meeting-tab-content index-${i} ${isActive ? 'active' : ''}`}>
            <Component meetingData={meetingData}
              backendMeetingData={backendMeetingData}
              version={currentTabVersion} isActive={isActive} isGpt4={isGpt4}
              setShowZoomTranscriptInfoPopup={setShowZoomTranscriptInfoPopup}
              setShowMsTeamsTranscriptInfoPopup={setShowMsTeamsTranscriptInfoPopup}
              setShowOutOfCreditsPopup={setShowOutOfCreditsPopup}
              searchWords={searchWords} aiSummaryCostsAiCredit={aiSummaryCostsAiCredit} meetingSummaryWasRequestedBefore={meetingSummaryWasRequestedBefore}
              userData={userData}
              loadBackendMeetingData={loadBackendMeetingData}
              isBackend={isBackend}
            />
          </div>;
        })
        }
      </div>

    </div>
  );
}

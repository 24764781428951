import React from "react";
import { SvgSize } from "./types";


const MinusIcon = (props: SvgSize) =>
  <svg width={props?.size ?? '28'} height={props?.size ?? '28'} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.1544 14.8456H19.1544C19.6214 14.8456 20 14.467 20 13.9999C20 13.5329 19.6214 13.1543 19.1544 13.1543H14.8456H8.84564C8.3786 13.1543 8 13.5329 8 13.9999C8 14.467 8.37861 14.8456 8.84564 14.8456H13.1544Z"
      fill="currentColor" />
  </svg>

export default MinusIcon

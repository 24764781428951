import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, colors } from '../../theme';
import formEntriesToBody from '../../utils/formEntriesToBody';
import { apiGet, apiPatch } from '../../utils/genericUtils';
import { useDataFromEndpoint } from "../hooks/useDataFromEndpoint";
import CopyToClipboard from '../CopyToClipboard';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const Input = styled.input`
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background-color: ${colors.gray900};
  color: ${colors.white};
  &:-webkit-autofill {
    outline: 1px solid pink;
    background-color: ${colors.gray900} !important;
  }
`;

const FakeInput = styled(Input)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Textarea = styled.textarea`
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background-color: ${colors.gray900};
  color: ${colors.white};
`

const FormButton = styled(Button)`
  align-self: flex-start;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & > li {
    padding: 10px;
  }
  & > li:nth-child(even) {
    background-color: rgba(0,0,0,0.2);
  }
`

interface User {
  email: string,
}

interface Organization {
  idp_endpoint?: string,
  idp_issuer?: string,
  idp_certificate?: string,
  uuid: string,
  users: User[]
}

export default function SettingsManageAdminOrganization(): JSX.Element {
  const [organization, isLoading, _, fetchOrganization] = useDataFromEndpoint(`/api/organization`)

  const handleSaveOrganization: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const body = formEntriesToBody(new FormData(e.currentTarget));
    await apiPatch({ path: '/organization', body })
    fetchOrganization()
  }

  return <div>
    <h2>SAML</h2>
    {organization != null && !isLoading && (
      <>
        <FormContainer onSubmit={handleSaveOrganization}>
          <label>
            <span>Organization UUID</span>
            <FakeInput as="div">
              <span>{organization.uuid}</span>
              <span style={{ marginLeft: "auto" }}><CopyToClipboard text={organization.uuid} /></span>
            </FakeInput>
          </label>
          <label>
            <span>SAML 2.0 Endpoint (HTTP)</span>
            <Input type="url" name="idp_endpoint" defaultValue={organization.idp_endpoint} />
          </label>
          <label>
            <span>Identity Provider Issuer</span>
            <Input type="url" name="idp_issuer" defaultValue={organization.idp_issuer} />
          </label>
          <label>
            <span>Certificate</span>
            <Textarea name="idp_certificate" defaultValue={organization.idp_certificate} />
          </label>
          <FormButton $small $inline>Save</FormButton>
        </FormContainer>
        <hr style={{ margin: '20px 0', background: '#434343' }} />
        <h2>Users</h2>
        <List>
          {organization.users.map(({ email }) => (
            <li>{email}</li>
          ))}
        </List>
      </>
    )}
  </div>
}


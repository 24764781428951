import React from 'react';
import { copyToClipboard } from "../utils/genericUtils";
import IconWithTimeoutReplaceOnClick from '../utils/IconWithTimeoutReplaceOnClick';
import CopyIcon from './Icons/Copy';
import CheckmarkCircleIcon from './Icons/CheckmarkCircle';

interface Props {
  id?: string,
  style?: React.CSSProperties,
  text: string,
}

export default function CopyToClipboard({ id, text, style }: Props): JSX.Element {
  return <IconWithTimeoutReplaceOnClick
    onClick={() => copyToClipboard(text)}
    initialIcon={<CopyIcon data-tooltip-id={id} color="#E44867" style={style} />}
    clickedIcon={<CheckmarkCircleIcon data-tooltip-id={id} color="#E44867" size="14" style={style} />}
  />
}

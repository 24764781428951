import { ReactElement, ReactNode } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled from 'styled-components';

const Overlay = styled(AlertDialog.Overlay)`
  background: rgba(0, 0, 0, .7);
  position: fixed;
  inset: 0;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 99999;
`;

const Content = styled(AlertDialog.Content)`
  background-color: #2C2C2C;
  color: #C6C6C6;
  border-radius: 4px;
  border: 1px solid #434343;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 999999;
`;

const Title = styled(AlertDialog.Title)`
  margin-top: 0;
`;


const ButtonGroup = styled.div`
  display: flex;
  gap: 26px;
  justifyContent: flex-end;
`;

const Cancel = styled(AlertDialog.Cancel)`
  border: 2px solid #E44867 !important;
  background: #2C2C2C !important;
  &:hover {
    border: 2px solid #D23655 !important;
    background: #434343 !important;
  }
  &:focus-visible {
    outline: 1px solid white;
  }
`;

const Confirm = styled(AlertDialog.AlertDialogAction)`
  &:focus-visible {
    outline: 1px solid white !important;
  }
`;

interface Props extends AlertDialog.AlertDialogProps {
  cancelContent: string | ReactElement,
  confirmContent: string | ReactElement,
  description: string,
  onConfirm: () => void,
  title: string,
  trigger?: ReactNode,
}


const Alert = ({ cancelContent, confirmContent, description, onOpenChange, onConfirm, open, trigger, title }: Props) => {
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger && <AlertDialog.Trigger asChild>
        {trigger}
      </AlertDialog.Trigger>}
      <AlertDialog.Portal>
        <Overlay />
        <Content>
          <Title>{title}</Title>
          <AlertDialog.Description>
            {description}
          </AlertDialog.Description>
          <ButtonGroup>
            <Cancel>
              {cancelContent}
            </Cancel>
            <Confirm onClick={onConfirm}>
              {confirmContent}
            </Confirm>
          </ButtonGroup>
        </Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default Alert;